import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS, ELEMENT_ID } from '@airtel-tv/constants';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { getSourceNameFromStorage } from '@airtel-tv/utils/GlobalUtil';
import useAppInAppRedirection from '@airtel-tv/lib/hooks/useAppInAppRedirection';
import { appInAppHandling } from '@airtel-feature/playback/actions/PlaybackActions';
import { showModalComponentAction, hideModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { connect } from 'react-redux';
import styles from './activateChannelPartnerRail.scss';
import { withDeviceUtil } from '@airtel-tv/utils';
const ActivateChannelPartnerRail = (props) => {
    const {
        title = '',
        focusDownElement = '',
        focusDownTargetByClass = false,
        focusUpElement = '',
        appInAppMeta,
        contentId,
        cpId,
        gtiId,
        showModalComponentActionDispatch,
        hideModalComponentActionDispatch,
        appInAppHandlingDispatch,
        appConfig,
        deviceUtil,
    } = props;
    const { PARTNER_ACTIVATION } = ELEMENT_ID;
    const { currentSource, prevSource } = getSourceNameFromStorage() || {};
    const analyticsMeta = {
        source_name: currentSource,
        source_page: prevSource,
        action: ANALYTICS_ACTIONS.BUTTON_CLICK,
        asset_name: ANALYTICS_ASSETS.ALREADY_HAVE_SUBSCRIPTION,
    };
    const appInAppRedirection = useAppInAppRedirection({
        appInAppMeta,
        contentId,
        cpId,
        gtiId,
        showModalComponentActionDispatch,
        hideModalComponentActionDispatch,
        appInAppHandlingDispatch,
        appConfig,
        deviceUtil,
    });

    return (
        <div className="activate-partner-link-container">
            <AnalyticsButtonComponent
                className={`nostyling text-16 text-ls-24 text-normal line-height-40 activate-partner-link ${PARTNER_ACTIVATION} ${FocusManagementClassNames.CAN_FOCUS}`}
                onClick={() => {
                    appInAppRedirection();
                }}
                focusUpElement={focusUpElement}
                focusDownElement={focusDownElement}
                focusDownTargetByClass={focusDownTargetByClass}
                meta={analyticsMeta}
            >
                {title}
            </AnalyticsButtonComponent>
        </div>
    );
};
ActivateChannelPartnerRail.propTypes = {
    title: PropTypes.string.isRequired,
    focusDownElement: PropTypes.string,
    focusDownTargetByClass: PropTypes.bool,
    focusUpElement: PropTypes.string,
    appInAppMeta: PropTypes.object,
    contentId: PropTypes.string,
    cpId: PropTypes.string,
    gtiId: PropTypes.string,
};

ActivateChannelPartnerRail.defaultProps = {
    focusDownElement: '',
    focusDownTargetByClass: false,
    focusUpElement: '',
    contentId: '',
    cpId: '',
    appInAppMeta: {},
    gtiId: '',
};
function mapStateToProps(state, props) {
    const {
        appConfig,
    } = state;
    return {
        appConfig,
    };
}
export default connect(
    mapStateToProps,
    {
        showModalComponentActionDispatch: showModalComponentAction,
        hideModalComponentActionDispatch: hideModalComponentAction,
        appInAppHandlingDispatch: appInAppHandling,
    },
)(withStyles(styles)((withDeviceUtil(ActivateChannelPartnerRail))));
