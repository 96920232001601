import React from 'react';
import { ROUTE_PATHS, ROUTE_PATHS_SETTINGS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

const getAllRouters = ({
    App = null,
    ErrorPageComponent = null,
    SearchContainer = null,
    SearchListContainer = null,
    ListingContainer = null,
    SettingsContainer = null,
    HelpComponent = null,
    WatchlistContainer = null,
    StaticComponent = null,

    XstreamPremium = null,
    XstreamPremium2 = null,
    ContentLayoutContainers = null,
    ContentDetailLayoutContainerWrapper,
    ContentLayoutContainer,
    SubscriptionContainer = null,
    PromotionComponent = null,
    NoSubscriptionComponent = null,
    PlansAndOffersContainer = null,
    PartnerListingComponent = null,
    NonAirtelComponent = null,
    ActivePlansContainer = null,
    MyPlansContainer = null,
    SubscriptionSuccessFailurePopUp = null,
    XmpClaimContainer = null,
    XmpClaimModal = null,
    XmpMultipleClaimContainer = null,
    SingleClaimSuccessPopUp = null,
    MultipleClaimSuccessPopUp = null,
    CpPlanDetailPage = null,
    NoPlanContainer = null,

    HappyCodeContainer = null,
    LanguageSettingsContainer = null,
    ArtistContainer = null,
    LayoutContainerWrapper = null,
    ContentDetailsContainer = null,
    // DimensionPageContainer = null,
    RedirectRouteContainer = null,
    SchedulePageContainer,
    DTHPaymentComponent = null,
    PreferredPartnerContainer = null,
    YoupageComponent = null,

    LayoutDataLoader = null,
    PlaybackDataLoader = null,
    ArtistDataLoader = null,
    ListDataLoader = null,
    BasicDataLoader = null,
    SearchListDataLoader = null,
    SearchDataLoader = null,
    // DimensionPageDataLoader = null,
    SchedulePageDataLoader = null,
    DTHLoginComponent = null,
    HappyCodeGenerateComponent = null,
    AccountDeletionComponent,
    ContentLayoutDataLoader,
    RedirectDataLoader,
    ProfileContainer,
    PlayerContainer,
    PartnerSubscriptionClaimContainer = null,
    HoichoiLandingPage = null,
    DeepLinkLandingPage = null,
    BlogComponent = null,
    BlogDescriptionComponent = null,
    SiteMapComponent = null,
    RechargePlanDetail = null,
    LoginRouteContainer = null,
    ListingContainerWrapper = null,
    SubscriptionOffersLanding = null,
    ChatbotPage = null,
    FancodeRedirect = null,
    DirectPlaybackContainer = null,
    ThanksSSORedirect = null,
    OneHubWrapperWeb = null,
    ActivationPage = null,
} = {}) => [
    {
        path: ROUTE_PATHS.ROOT,
        Component: App,
        children: [
            // no route found
            {
                path: ROUTE_PATHS.DTH_SUBSCRIBE, // @kanak - redirect to payment page directly deeplink, for d2c plan
                Component: LoginRouteContainer,
                loader: BasicDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.QR_CODE_LOGIN,
                Component: LoginRouteContainer,
                loader: BasicDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.RECHARGE_ONLY,
                // Component: RechargePlanDetail,
                // loader: BasicDataLoader,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            // {
            //     exact: true,
            //     path: ROUTE_PATHS.RECHARGE_ONLY,
            //     Component: RechargePlanDetail,
            //     loader: BasicDataLoader,
            // },
            {
                exact: true,
                path: ROUTE_PATHS.RECHARGE_STATUS_PAGE,
                Component: RechargePlanDetail,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.SITE_MAP,
                Component: SiteMapComponent,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.NOT_FOUND,
                Component: ErrorPageComponent,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.BLOG_DESCRIPTION,
                Component: BlogDescriptionComponent,
                loader: BasicDataLoader,
                isHeaderNavigation: true,
            },
            {
                path: ROUTE_PATHS.BLOGS,
                Component: BlogComponent,
                loader: BasicDataLoader,
                isHeaderNavigation: true,
            },
            {
                path: ROUTE_PATHS.OFFERS_CHECK,
                Component: RedirectRouteContainer,
                loader: RedirectDataLoader,
                exact: true,
                isHeaderNavigation: true,
            },
            // partner claim
            {
                exact: true,
                path: ROUTE_PATHS.PARTNER_CLAIM, // @kanak - is this need to be updated?
                Component: PartnerSubscriptionClaimContainer,
                loader: BasicDataLoader,
            },
            // user profile
            {
                exact: true,
                path: ROUTE_PATHS.PROFILE_PAGE,
                Component: ProfileContainer,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.DEEPLINK_LANDING_PAGE, // @kanak - does this need to be updated
                Component: DeepLinkLandingPage,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.ACCOUNT_DELETION,
                Component: AccountDeletionComponent,
                loader: BasicDataLoader,
            },
            // {
            //     exact: true,
            //     path: ROUTE_PATHS.DTH_PAYMENT_PAGE,
            //     Component: DTHPaymentComponent,
            //     loader: BasicDataLoader,
            // },
            {
                exact: true,
                path: ROUTE_PATHS.HAPPY_CODE_PAGE,
                Component: HappyCodeGenerateComponent,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.DTH_CUSTOMER_LOGIN,
                Component: DTHLoginComponent,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.PREFERRED_PARTNER_SELECTION,
                Component: PreferredPartnerContainer,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.HAPPYCODE,
                Component: HappyCodeContainer,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.SEARCH_PAGE,
                Component: SearchContainer,
                loader: SearchDataLoader,
                isSearchPage: true,
            },
            // Xstream Premium Landinng Page
            {
                path: ROUTE_PATHS.XSTREAM_PREMIUM_LANDING_PAGE,
                Component: XstreamPremium,
                loader: BasicDataLoader,
            },
            // Xstream Premium New Landinng Page
            {
                path: ROUTE_PATHS.XSTREAM_PREMIUM_NEW_LANDING_PAGE,
                Component: XstreamPremium2,
                loader: BasicDataLoader,
            },
            // cdp Landing Page
            {
                path: ROUTE_PATHS.CONTENT_DETAIL_LANDING_PAGE,
                Component: ContentLayoutContainers,
                loader: BasicDataLoader,
            },
            // Subscription Page
            {
                path: ROUTE_PATHS.SUBSCRIPTION_PAGE,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            {
                path: ROUTE_PATHS.PROMOTION_PAGE_NEW,
                Component: PromotionComponent,
                loader: BasicDataLoader,
            },
            // Subscription success/failure popup Page
            {
                path: ROUTE_PATHS.SUBSCRIPTION_SUCCESS,
                Component: SubscriptionSuccessFailurePopUp,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.SUBSCRIPTION_FAILURE,
                Component: SubscriptionSuccessFailurePopUp,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.NO_SUBSCRIPTION,
                Component: NoSubscriptionComponent,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.PLANS_AND_OFFERS,
                // Component: MyPlansContainer,
                // loader: BasicDataLoader,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            {
                path: ROUTE_PATHS.ONEHUB_PAYMENT_STATUS,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            {
                path: ROUTE_PATHS.ONEHUB,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            // {
            //     path: ROUTE_PATHS.ONEHUB_PLANS_ALL,
            //     Component: OneHubWrapperWeb,
            //     loader: BasicDataLoader,
            //     isOnehubRoute: true,
            //     isWebView: true,
            // },
            {
                path: ROUTE_PATHS.PARTNER_LISTING,
                // Component: PartnerListingComponent,
                // loader: BasicDataLoader,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            {
                path: ROUTE_PATHS.NON_AIRTEL_USER_POPUP,
                Component: NonAirtelComponent,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.ACTIVE_PLANS, // @kanak - does this need to be updated with ingress activate
                // Component: ActivePlansContainer,
                // loader: BasicDataLoader,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            {
                path: ROUTE_PATHS.My_NO_PLANS,
                Component: NoPlanContainer,
                loader: BasicDataLoader,
            },

            {
                path: ROUTE_PATHS.MY_PLANS,
                // Component: MyPlansContainer,
                // loader: SubscriptionDataLoader,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },

            // XMP Claim Page
            {
                path: ROUTE_PATHS.XMP_CLAIM_PAGE,
                Component: XmpClaimContainer,
                loader: BasicDataLoader,
            },
            // XMP Claim Modal
            {
                path: ROUTE_PATHS.XMP_CLAIM_MODAL,
                Component: XmpClaimModal,
                loader: BasicDataLoader,
            },
            // XMP Multiple Claim Page
            {
                path: ROUTE_PATHS.XMP_MULTIPLE_CLAIM_PAGE,
                Component: XmpMultipleClaimContainer,
                loader: BasicDataLoader,
            },
            // Single Claim Success Page
            {
                path: ROUTE_PATHS.SINGLE_CLAIM_SUCCESS_PAGE,
                Component: SingleClaimSuccessPopUp,
                loader: BasicDataLoader,
            },
            // Multiple Claim Success Page
            {
                path: ROUTE_PATHS.MULTIPLE_CLAIM_SUCCESS_PAGE,
                Component: MultipleClaimSuccessPopUp,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.CP_PLAN_DETAIL_PAGE,
                // Component: CpPlanDetailPage,
                // loader: BasicDataLoader,
                Component: OneHubWrapperWeb,
                loader: BasicDataLoader,
                isOnehubRoute: true,
                isWebView: true,
            },
            // SEARCH LISTING
            {
                path: ROUTE_PATHS.SEARCH_LISTING,
                Component: SearchListContainer,
                loader: SearchListDataLoader,
                isSearchPage: true,
            },

            // LISTING
            {
                path: ROUTE_PATHS.LISTING,
                Component: RedirectRouteContainer,
                loader: RedirectDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.LISTING_V2,
                Component: ListingContainer,
                loader: ListDataLoader,
                exact: true,
            },
            // GENRE PAGE
            {
                path: ROUTE_PATHS.DIMENSION_GENRE_LANG_PAGE,
                Component: RedirectRouteContainer,
                loader: RedirectDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.DIMENSION_GENRE_OR_LANG_PAGE,
                Component: RedirectRouteContainer,
                loader: RedirectDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.DIMENSION_PROGRAMTYPE_PAGE,
                Component: ListingContainerWrapper,
                loader: ListDataLoader,
                exact: true,
            },
            // schedule page
            {
                path: ROUTE_PATHS.SCHEDULE_PAGE,
                Component: SchedulePageContainer,
                loader: SchedulePageDataLoader,
            },

            // Watchlist page
            {
                path: ROUTE_PATHS.WATCHLIST_PAGE,
                Component: WatchlistContainer,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.YOU_PAGE,
                Component: YoupageComponent,
                loader: BasicDataLoader,
            },

            {
                exact: true,
                path: ROUTE_PATHS.CONTENT_LAYOUT_PAGES,
                Component: ContentLayoutContainer,
                loader: ContentLayoutDataLoader,
            },
            // SETTINGS
            {
                path: ROUTE_PATHS.SETTINGS,
                Component: SettingsContainer,
                loader: BasicDataLoader,
                children: [
                    {
                        path: ROUTE_PATHS_SETTINGS.SETTINGS_HELP_SECTION,
                        Component: HelpComponent,
                        children: [
                            {
                                path: ROUTE_PATHS_SETTINGS.SETTINGS_HELP_COMMON_SECTION,
                                Component: StaticComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: ROUTE_PATHS.SETTINGS_LANGUAGE_SECTION,
                Component: LanguageSettingsContainer,
                loader: BasicDataLoader,
            },

            //  ARTIST PAGE
            {
                path: ROUTE_PATHS.ARTIST,
                Component: ArtistContainer,
                loader: ArtistDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.LAYOUT,
                Component: LayoutContainerWrapper,
                loader: LayoutDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.SPORTS_LAYOUT,
                Component: LayoutContainerWrapper,
                loader: LayoutDataLoader,
            },


            // TV SHOW PLAYBACK
            {
                exact: true,
                path: ROUTE_PATHS.TV_EPISODE_PLAYBACK,
                Component: ContentDetailLayoutContainerWrapper,
                loader: PlaybackDataLoader,
                isCDP: true,
            },
            // PLAYBACK LIVE TV EPISODE
            {
                exact: true,
                path: ROUTE_PATHS.LIVE_TV_EPISODE_PLAYBACK,
                Component: ContentDetailLayoutContainerWrapper,
                loader: PlaybackDataLoader,
                isCDP: true,
            },
            // TV SEASON PLAYBACK
            {
                exact: true,
                path: ROUTE_PATHS.TV_SEASON_PLAYBACK,
                Component: ContentDetailLayoutContainerWrapper,
                loader: PlaybackDataLoader,
                isCDP: true,
            },
            // PLAYBACK
            {
                exact: true,
                path: ROUTE_PATHS.LIVETV_PLAYBACK,
                Component: ContentDetailsContainer,
                loader: PlaybackDataLoader,
                isCDP: true,
            },
            {
                exact: true,
                path: ROUTE_PATHS.PLAYBACK,
                Component: ContentDetailLayoutContainerWrapper,
                loader: PlaybackDataLoader,
                isCDP: true,
            },
            {
                exact: true,
                path: ROUTE_PATHS.TV_EPISODE_CONTENT_PLAYBACK,
                Component: ContentDetailLayoutContainerWrapper,
                loader: PlaybackDataLoader,
                isCDP: true,
            },
            {
                exact: true,
                path: ROUTE_PATHS.TV_SEASON_CONTENT_PLAYBACK,
                Component: ContentDetailLayoutContainerWrapper,
                loader: PlaybackDataLoader,
                isCDP: true,
            },
            {
                path: ROUTE_PATHS.CONTENT_DETAILS,
                Component: ContentLayoutContainer,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.PLAYER,
                Component: PlayerContainer,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.HOICHOI_CAMPAIGN,
                Component: HoichoiLandingPage,
                loader: BasicDataLoader,
            },
            {
                path: ROUTE_PATHS.DIMENSION_PAGE,
                Component: ListingContainerWrapper,
                loader: ListDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.LANDING,
                Component: LayoutContainerWrapper,
                loader: LayoutDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.SUBSCRIPTION_OFFER,
                Component: SubscriptionOffersLanding,
                loader: BasicDataLoader,
                exact: true,
                // Component: OneHubWrapperWeb,
                // loader: BasicDataLoader,
                // isOnehubRoute: true,
                // isWebView: true,
            },
            {
                path: ROUTE_PATHS.USER_CHAT,
                Component: ChatbotPage,
                loader: BasicDataLoader,
                exact: true,
            },
            {
                path: ROUTE_PATHS.FAN_CODE,
                Component: FancodeRedirect,
                loader: BasicDataLoader,
            },
            {
                exact: true,
                path: ROUTE_PATHS.DIRECT_PLAYBACK_CONTAINER,
                Component: DirectPlaybackContainer,
                loader: PlaybackDataLoader,
                isWebView: true,
                skipBackHandling: false,
            },
            {
                path: ROUTE_PATHS.DISTRO_CHANNELS_LIST,
                Component: ListingContainerWrapper,
                loader: BasicDataLoader,
                exact: true,
            },
            {
                exact: true,
                path: ROUTE_PATHS.THANKS_SSO,
                Component: ThanksSSORedirect,
                loader: BasicDataLoader,
                isWebView: true,
            },
            {
                exact: true,
                path: ROUTE_PATHS.PLAN_ACTIVATION,
                Component: ActivationPage,
                loader: BasicDataLoader,
            },
        ],
    },
];
export default getAllRouters;
