import React, { useRef } from 'react';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './PlanTile/PlanTile.scss';
import { formatType, getDateWithTitle, getSourceNameFromStorage } from '@airtel-tv/utils/GlobalUtil';
import { ANALYTICS_ACTIONS } from '@airtel-tv/constants/AnalyticsConst';
import { LanguageProviderUtil, checkWindowExist } from '@airtel-tv/utils';

const cpImageSettings = {
    1000: 600,
    300: 100,
};

const SubscriptionActivationTile = (props) => {
    const { isLargeScreen, tileProps = {}, removeFocusableClass = false } = props;

    const activationTileContainerRef = useRef();

    const {
        railPosition, tilePosition, planDetails, planDetails: { partner, validTill}, onKeyDown, contentDetails,
        primaryActionHandler = () => {},
        onKeyUp = () => {},
        onFocus, focusProps, pageId, tileId, linkClassName = '', containerClass = '', activationPartnerLogo,
        activationStatus,
        purchaseCtaText,
        analyticsPageId,
        railId,
        railType,
        railTitle,
        planExpiryText,
        cpDetailsById,
        deviceUtil,
        style,
    } = tileProps;

    const thisRef = useRef({
        isMobile: deviceUtil.isMobile(),
    }).current;
    const expiryDate = getDateWithTitle(validTill, formatType.LONG);

    const { ACTIVATED = 'Activated', ACTIVATION_INPROGRESS = 'Activation Inprogress' } = LanguageProviderUtil.getLanguage();

    const {
        partner: {
            partnerIcon,
            name,
            cpId,
        } = {},
    } = planDetails;

    const cpData = cpDetailsById?.[cpId] || {};
    const parnterName = cpData?.title || name;
    const { coverImage, coverImage: { SQUARE = '' } = {}} = cpData;
    const partnerLogo = SQUARE || partnerIcon;
    const { currentSource, prevSource } = getSourceNameFromStorage() || {};
    const analyticsMeta = {
        source_name: currentSource,
        source_page: prevSource,
        rail_position: railPosition,
        tile_type: railType,
        rail_id: railId,
        rail_title: railTitle,
        rail_type: railType,
        asset_position: tilePosition,
        page_id: analyticsPageId,
        tileId,
        action: ANALYTICS_ACTIONS.BUTTON_CLICK,
        asset_name: purchaseCtaText,
        cp_name: name,
    };
    const tileIdUpdated = tileId || `${pageId}-tile-${railPosition}-${tilePosition}`;

    const btnClass = activationStatus === ACTIVATION_INPROGRESS ? 'disable' : '';

    return (
        <div
            className={`activation-tile ${activationStatus === ACTIVATED ? 'activated' : ''}`}
            // append class activated with activation-tile when want to show activated flow means browse button
            // also remove side buttons using javascript in this flow (Your Subscription Benefits) rail
            ref={activationTileContainerRef}
            id={tileIdUpdated}
            style={{
                height: style.height,
            }}
        >
            <div className={`activation-tag ${activationStatus === ACTIVATED ? 'ls-activated' : ''} text-ls-14 text-ls-bolder`}>{activationStatus?.toUpperCase()}</div>
            <ThumborImage
                src={partnerLogo}
                className="logo"
                imageSettings={cpImageSettings}
            />
            <div className='date-title-holder'>
            <p className="activation-title text-ls-26 text-ls-bolder line-height-ls-44 text-18 text-bolder line-height-24 mobile-text-14 mobile-text-bolder mobile-line-height-20">{parnterName}</p>
            <p className="expire-date text-ls-24 text-ls-normal line-height-ls-36 text-14 line-height-16 mobile-text-12 mobile-text-normal mobile-line-heigt-20">{planExpiryText}</p>
            </div>
           

            {purchaseCtaText ? (
                <AnalyticsButtonComponent
                    id={tileId}
                    autoFocus={railPosition === 0 && tilePosition === 0}
                    onFocus={(e) => {
                        if (typeof onFocus === 'function') {
                            onFocus({
                                e: e.event,
                                railPosition,
                                tilePosition,
                                isCwRail: true,
                                customElmTarget: { target: activationTileContainerRef.current },
                                tileDetails: {
                                    ...contentDetails,
                                    nonQuickViewTile: true,
                                },
                                ...focusProps,
                            });
                        }
                        if (checkWindowExist()) {
                            window?.scrollTo(0, 0)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (typeof onKeyDown === 'function') {
                            onKeyDown({
                                event: e.event,
                                tilePosition,
                            });
                        }
                    }}
                    onKeyUp={e => {
                        if (typeof onKeyUp === 'function') {
                            onKeyUp(e?.event);
                        }
                    }}
                    onClick={() => primaryActionHandler(props)}
                    className={`one-hub-tile-cta activation-tile-cta nostyling text-ls-bold text-ls-20 line-height-ls-24 mobile-text-12 mobile-text-bold text-16 text-bold ${btnClass} ${FocusManagementClassNames.CAN_FOCUS}`}
                    meta={analyticsMeta}
                >
                    {activationStatus === ACTIVATED && thisRef.isMobile ? '' : purchaseCtaText}
                </AnalyticsButtonComponent>
            ) : null}
        </div>
    );
};

export default withStyles(styles)(SubscriptionActivationTile);
