import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { MODAL_POPUP } from '@airtel-tv/constants';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';

export const ERROR_ACTIONS = {
    LOGIN: 'LOGIN',
    BUY_SUBSCRIPTION: 'BUY_SUBSCRIPTION',
    RETRY_PLAYBACK: 'RETRY_PLAYBACK',
    ERROR_MESSAGE_ON_PLAYER: 'ERROR_MESSAGE_ON_PLAYER',
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
    HELP_DESK: 'HELP_DESK',
    SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE',
    COMPLETE_REGISTRATION: 'COMPLETE_REGISTRATION',
    CONCURRENT_DEVICES_LIMIT_EXCEEDED: 'CONCURRENT_DEVICES_LIMIT_EXCEEDED',
    MOBILE_APP_PUSH: 'MOBILE_APP_PUSH',
    RETRY_ERROR_PAGE: 'RETRY_ERROR_PAGE',
    GOTO_HOME_ERROR_PAGE: 'GOTO_HOME_ERROR_PAGE',
    SEARCH_AGAIN_ERROR_PAGE: 'SEARCH_AGAIN_ERROR_PAGE',
    NO_ACTION: 'NO_ACTION',
    RIGHTS_NOT_AVAILABLE: 'RIGHTS_NOT_AVAILABLE',
    SAFARI_DRM_MOBILE_APP_PUSH: 'SAFARI_DRM_MOBILE_APP_PUSH',
};


export const ACTION_TO_ERROR_CODE = {
    [ERROR_CODES.LOCAL1001]: ERROR_ACTIONS.LOGIN,
    [ERROR_CODES.ATV013]: ERROR_ACTIONS.LOGIN,
    [ERROR_CODES.ATV401]: ERROR_ACTIONS.LOGIN,
    [ERROR_CODES.ATV041]: ERROR_ACTIONS.LOGIN,

    [ERROR_CODES.ATV204]: ERROR_ACTIONS.BUY_SUBSCRIPTION,
    [ERROR_CODES.ATV209]: ERROR_ACTIONS.BUY_SUBSCRIPTION,
    [ERROR_CODES.ATV210]: ERROR_ACTIONS.BUY_SUBSCRIPTION,

    [ERROR_CODES.ATV207]: ERROR_ACTIONS.CONCURRENT_DEVICES_LIMIT_EXCEEDED,
    [ERROR_CODES.ATV208]: ERROR_ACTIONS.CONCURRENT_DEVICES_LIMIT_EXCEEDED,
    [ERROR_CODES.ATV206]: ERROR_ACTIONS.CONCURRENT_DEVICES_LIMIT_EXCEEDED,

    [ERROR_CODES.ATV201]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.ATV204]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.ATV205]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.ATV031]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_6007]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_6015]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.PLAYER_NETWORK_ERROR]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.VIDEOJS_2]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.VIDEOJS_4]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_1001]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.SHAKA_1002]: ERROR_ACTIONS.RETRY_PLAYBACK,
    [ERROR_CODES.LOCAL1008]: ERROR_ACTIONS.RETRY_PLAYBACK,

    [ERROR_CODES.ATV202]: ERROR_ACTIONS.ERROR_MESSAGE_ON_PLAYER,
    [ERROR_CODES.ATV426]: ERROR_ACTIONS.ERROR_MESSAGE_ON_PLAYER,
    [ERROR_CODES.ATATV427V426]: ERROR_ACTIONS.ERROR_MESSAGE_ON_PLAYER,

    [ERROR_CODES.ATV251]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV252]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV253]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV254]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV255]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV256]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV257]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,
    [ERROR_CODES.ATV258]: ERROR_ACTIONS.SWITCH_TO_AIRTEL,

    [ERROR_CODES.ATV033]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV998]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV001]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV002]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV003]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV004]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV015]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV020]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV021]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV030]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV90]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV999]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV409]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV259]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV260]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV261]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV404]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.UNKNOWN]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.LOCAL1003]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV022]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV006]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,
    [ERROR_CODES.ATV007]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,

    [ERROR_CODES.ATV032]: ERROR_ACTIONS.COMPLETE_REGISTRATION,
    [ERROR_CODES.ATV203]: ERROR_ACTIONS.SHOW_ERROR_MESSAGE,

    [ERROR_CODES.SHAKA_6001]: ERROR_ACTIONS.MOBILE_APP_PUSH,
    [ERROR_CODES.SHAKA_BROWSER_NOT_SUPPORTED]: ERROR_ACTIONS.MOBILE_APP_PUSH,
    [ERROR_CODES.VIDEOJS_3]: ERROR_ACTIONS.MOBILE_APP_PUSH,
    [ERROR_CODES.VIDEOJS_BROWSER_NOT_SUPPORTED]: ERROR_ACTIONS.MOBILE_APP_PUSH,

    [ERROR_CODES.ATV014]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV012]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.LOCAL1004]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV011]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.LOCAL1009]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.LOCAL1010]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV262]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,
    [ERROR_CODES.ATV410]: ERROR_ACTIONS.GOTO_HOME_ERROR_PAGE,

    [ERROR_CODES.LOCAL1002]: ERROR_ACTIONS.RETRY_ERROR_PAGE,

    [ERROR_CODES.SHAKA_3015]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.SHAKA_6003]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.SHAKA_6015]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.VIDEOJS_5]: ERROR_ACTIONS.NO_ACTION,
    [ERROR_CODES.ATV043]: ERROR_ACTIONS.NO_ACTION,

    [ERROR_CODES.LOCAL1005]: ERROR_ACTIONS.SEARCH_AGAIN_ERROR_PAGE,
    [ERROR_CODES.SAFARI_DRM_UNSUPPORTED_MOBILE_PUSH]: ERROR_ACTIONS.SAFARI_DRM_MOBILE_APP_PUSH,
};

export const NOTIFYIDS = {
    AGE_CONSENT_POPUP_ADULT: 'AGE_CONSENT_POPUP_ADULT',
    XPP_CLAIM_PENDING_POP_UP_ID: 'XPP_CLAIM_PENDING_POP_UP_ID',
    XPP_PURCHASE_POP_UP_ID: 'XPP_PURCHASE_POP_UP_ID',
    XPP_FREE_TRIAL_POP_UP_ID: 'XPP_FREE_TRIAL_POP_UP_ID',
    CONCURRENT_PLAYBACK_LIMIT_POPUP: 'CONCURRENT_PLAYBACK_LIMIT_POPUP',
    XPP_CLAIM_POP_UP_LIFECYCLE: 'XPP_CLAIM_POP_UP_LIFECYCLE',
    XPP_CLAIM_POPUP_ATB: 'XPP_CLAIM_POPUP_ATB',
};

export const ACTION_TO_NOTIFYID = {
    [ERROR_CODES.ATV213]: 'AgeClassificationPopup',
    [NOTIFYIDS.XPP_CLAIM_PENDING_POP_UP_ID]: MODAL_POPUP.XPP_CLAIM_MODAL,
    [NOTIFYIDS.XPP_PURCHASE_POP_UP_ID]: MODAL_POPUP.XPP_PURCHASE_POP_UP_ID,
    [NOTIFYIDS.XPP_FREE_TRIAL_POP_UP_ID]: MODAL_POPUP.XPP_FREE_TRIAL_POP_UP_ID,
    [NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP]: MODAL_POPUP.CONCURRENT_PLAY_LIMIT_REACHED,
    [NOTIFYIDS.XPP_CLAIM_POPUP_ATB]: MODAL_POPUP.XPP_SINGLE_CLAIM_MODAL,
    [NOTIFYIDS.XPP_CLAIM_POP_UP_LIFECYCLE]: MODAL_POPUP.XPP_SINGLE_CLAIM_MODAL,
};

export const RELOAD_LAYOUT_POPUPS = [
    NOTIFYIDS.XPP_CLAIM_PENDING_POP_UP_ID, NOTIFYIDS.XPP_PURCHASE_POP_UP_ID, NOTIFYIDS.XPP_FREE_TRIAL_POP_UP_ID, NOTIFYIDS.XPP_CLAIM_POP_UP_LIFECYCLE, NOTIFYIDS.XPP_CLAIM_POPUP_ATB,
]

export const NOTIFY_ID_MODAL_PAYLOAD = {
    [NOTIFYIDS.XPP_CLAIM_PENDING_POP_UP_ID]: {
        overrideCrossButtonValue: false,
        popUpId: NOTIFYIDS.XPP_CLAIM_PENDING_POP_UP_ID,
        popupVisibleMeta: {
            asset_name: NOTIFYIDS.XPP_CLAIM_PENDING_POP_UP_ID,
            source_name: NOTIFYIDS.XPP_CLAIM_PENDING_POP_UP_ID,
            popup_id: NOTIFYIDS.XPP_CLAIM_PENDING_POP_UP_ID,
        },
        trailPlan: true,
        closePlayer: true,
    },
    [ERROR_CODES.ATV213]: {
        overrideCrossButtonValue: true,
    },
    [NOTIFYIDS.XPP_PURCHASE_POP_UP_ID]: {
        isTrialPlanEligible: false,
        popUpId: NOTIFYIDS.XPP_PURCHASE_POP_UP_ID,
        popupVisibleMeta: {
            asset_name: NOTIFYIDS.XPP_PURCHASE_POP_UP_ID,
            source_name: NOTIFYIDS.XPP_PURCHASE_POP_UP_ID,
            popup_id: NOTIFYIDS.XPP_PURCHASE_POP_UP_ID,
        },
        closePlayer: true,
    },
    [NOTIFYIDS.XPP_FREE_TRIAL_POP_UP_ID]: {
        isTrialPlanEligible: true,
        popUpId: NOTIFYIDS.XPP_FREE_TRIAL_POP_UP_ID,
        popUpcustomClass: 'free-extention-popup',
        popupVisibleMeta: {
            asset_name: NOTIFYIDS.XPP_FREE_TRIAL_POP_UP_ID,
            source_name: NOTIFYIDS.XPP_FREE_TRIAL_POP_UP_ID,
            popup_id: NOTIFYIDS.XPP_FREE_TRIAL_POP_UP_ID,
        },
        closePlayer: true,
    },
    [NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP]: {
        showModalValue: true,
        componentNameValue: MODAL_POPUP.CONCURRENT_PLAY_LIMIT_REACHED,
        overrideCrossButtonValue: true,
        popUpcustomClass: 'second-pop-up',
        popupVisibleMeta: {
            asset_name: NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP,
            source_name: NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP,
            popup_id: NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP,
        },
        popUpId: NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP,
    },
    [NOTIFYIDS.XPP_CLAIM_POPUP_ATB]: {
        isTrialPlanEligible: true,
        popUpcustomClass: 'second-pop-up xpp-claim-modal atb-wrappper',
        popupVisibleMeta: {
            asset_name: 'xpp_claim',
            source_name: NOTIFYIDS.XPP_CLAIM_POPUP_ATB,
            popup_id: NOTIFYIDS.XPP_CLAIM_POPUP_ATB,
        },
        popUpId: NOTIFYIDS.XPP_CLAIM_POPUP_ATB,
        closePlayer: true,
    },
    [NOTIFYIDS.XPP_CLAIM_POP_UP_LIFECYCLE]: {
        isTrialPlanEligible: true,
        popUpcustomClass: 'second-pop-up xpp-claim-modal',
        popupVisibleMeta: {
            asset_name: 'xpp_claim',
            source_name: NOTIFYIDS.XPP_CLAIM_POP_UP_LIFECYCLE,
            popup_id: NOTIFYIDS.XPP_CLAIM_POP_UP_LIFECYCLE,
        },
        popUpId: NOTIFYIDS.XPP_CLAIM_POP_UP_LIFECYCLE,
        closePlayer: true,
    },
};


export const ERROR_SOURCES = {
    ARTIST_CONTAINER: 'Artist',
    LAYOUT_CONTAINER: 'Page',
    CHANNEL_PARTNER: 'Channel_Partner',
    DTH_PAYMENT_PAGE: 'Dth_Payment_Page',
    CONTENT_DETAIL_LAYOUT_CONTAINER: 'ContentDetail',
};

export const ERROR_PLACE_HOLDERS = {
    SOURCE: '{source}',
};

export default {
    ERROR_ACTIONS,
    ERROR_CODES,
    NOTIFYIDS,
    ACTION_TO_ERROR_CODE,
    ACTION_TO_NOTIFYID,
    ERROR_SOURCES,
    ERROR_PLACE_HOLDERS,
};
