import 'intersection-observer';
import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { matchPath, matchRoutes, Navigate } from 'react-router';
import lodashIsEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { LanguageProviderUtil as LanguageProvider } from '@airtel-tv/utils/LanguageProviderUtil';
import { getRouteKey, removeFalsy } from '@airtel-tv/utils/GlobalUtil';
import { IntersectionObservableHOC } from '@airtel-tv/lib';
import { INTERSECTION_OBSERVER_THRESHOLD, LOCAL_STORE_KEYS, IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { RAIL_LIMIT, RAIL_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import LayoutUtil from '@airtel-feature/layout/utils/LayoutUtil';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { screenVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { getPackIdsFromLayout } from './helpers/LayoutHelper';


class LayoutContainer extends Component {
    constructor(props) {
        super(props);

        this.deviceUtil = DeviceUtil.getDeviceUtil();
        this.layoutRef = React.createRef();

        const { layoutPackages = [], matchRoute, location } = props;

        const pageData = layoutPackages && layoutPackages.length ? layoutPackages : [];
        this.state = {
            paginatedData: [
                ...pageData,
            ],
            pageReloaded: false,
            tilesToshow: RAIL_LIMIT.USER - 1,
            isPageChanged: false,
            paramsFromUrl: {},
            fetchedData: false,
            query: '',
            railCount: 0,
            showErrorComponent: false,
        };

        this.infiniteScrollerRef = React.createRef();
        this.updateLastIndex = null;
        this.LANGUAGE = LanguageProvider.getLanguage();
        this.numberOfRails = 0;
        this.lastKeyPressTimeStamp = 0;
        this.paramsFromUrl = {};
        this.railsRef = createRef([]);
        this.isSearchLayout = matchRoute(ROUTE_PATHS.SEARCH_PAGE, location?.pathname);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { pageId } = nextProps;
        const {
            layoutPackages,
            packageContents,
            userConfig,
            layoutError,
            isAuthenticated,
            isBackButtonVisible,
            query,
            isOnehubRoute,
            layoutPageId,
        } = nextProps;


        const contentForLayoutPackages = {};// getPackageContentByLayout(layoutPackages, packageContents); uncomment in case of error
        const newContentAvailable = !lodashIsEqual(contentForLayoutPackages, prevState.contentForLayoutPackages);

        if (
            pageId !== prevState.pageId
            || layoutPackages !== prevState.layoutPackages
            || newContentAvailable
            || layoutError !== prevState.layoutError
            || isAuthenticated !== prevState.isAuthenticated
            || isBackButtonVisible !== prevState.isBackButtonVisible
            || query !== prevState.query
            || layoutPageId !== prevState.layoutPageId
        ) {
            return {
                pageId,
                layoutPackages,
                contentForLayoutPackages,
                userConfig,
                layoutError,
                isAuthenticated,
                // fetchedData,
                // fetchedData: (pageId !== prevState.pageId || (isOnehubRoute && query !== prevState.query)) || layoutPageId !== prevState.layoutPageId ? false : prevState.fetchedData,
                isBackButtonVisible,
                query,
                layoutPageId,
            };
        }

        return null;
    }

    componentDidMount() {
        const {
            fetchEpgChannelDetailsDispatch,
            hasEpgData,
            fetchEpgOnLoad,
            pageId,
            webPageId = {},
            match,
            matchRoute,
            location,
            isOnehubRoute,
        } = this.props;
        const isLayout = matchRoute(ROUTE_PATHS.LAYOUT, location?.pathname);
        const isSportsLayout = matchRoute(ROUTE_PATHS.SPORTS_LAYOUT, location?.pathname);
        const isPillsComponent = matchRoute(ROUTE_PATHS.LANDING, location?.pathname);
        if (isLayout || isSportsLayout || isPillsComponent || this.isSearchLayout || isOnehubRoute) {
            const paramsFromUrl = LocationUtil.getQueryParams(this.props);
            const pathParamsFromUrl = LocationUtil.getPathParams(this.props);
            if (Object.keys(paramsFromUrl).length > 0) {
                this.paramsFromUrl = paramsFromUrl;
            }
            if (isOnehubRoute) {
                const { ingressIntent, cpId } = pathParamsFromUrl;
                this.paramsFromUrl = {
                    ...this.paramsFromUrl,
                    ...(ingressIntent ? { ingressIntent } : {}),
                    ...(cpId ? { preferredCp: cpId } : {}),
                };
            }
        }
        if (isSportsLayout) {
            if (match?.params) {
                const paramsFromMatch = removeFalsy(match?.params);
                let paramsFromUrl = (Object.keys(paramsFromMatch).length > 0 && paramsFromMatch);
                if (Object.keys(paramsFromUrl).length > 0) {
                    paramsFromUrl = { [match.params.key]: match.params.type };
                }
                this.paramsFromUrl = {
                    ...this.paramsFromUrl,
                    ...paramsFromUrl,
                };
            }
        }
        this.init();
        if (fetchEpgOnLoad) {
            this.fetchChannels();
            if (!hasEpgData) {
                fetchEpgChannelDetailsDispatch();
            }
        }
        this.ErrorHandle();
        this.setState({
            isPageChanged: true,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !lodashIsEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            layoutPackages,
            layoutReset,
            pageId,
            isOnehubRoute,
        } = this.props;
        const {
            layoutReset: prevLayoutReset,
            pageId: previousPageId,
            query: prevQuery = '',
        } = prevProps;
        const {
            paginatedData,
            query,
            fetchedData,
            pageId: pageIdFromState,
        } = this.state;


        if ((this.isSearchLayout || isOnehubRoute) && prevQuery !== query) {
            const paramsFromUrl = LocationUtil.getQueryParams(this.props) || {};
            const pathParamsFromUrl = LocationUtil.getPathParams(this.props);
            if (Object.keys(paramsFromUrl).length > 0) {
                this.paramsFromUrl = paramsFromUrl;
            }
            if (isOnehubRoute) {
                const { ingressIntent, cpId } = pathParamsFromUrl;
                this.paramsFromUrl = {
                    ...paramsFromUrl,
                    ...(ingressIntent ? { ingressIntent } : {}),
                    ...(cpId ? { preferredCp: cpId } : {}),
                };
            }
        }

        if (
            (layoutReset && prevLayoutReset !== layoutReset)
            || previousPageId !== pageId
            || prevQuery !== query
            || prevProps.pageId !== pageIdFromState
        ) {
            // purge local layout if search query chnged or pageid changed
            this.railsRef.current = null;
            this.setState({ paginatedData: [], fetchedData: false });
            this.init(prevProps);
        }

        if (prevProps && (prevProps.pageId !== pageId || ((this.isSearchLayout || isOnehubRoute) && prevQuery !== query))) {
            const pageData = layoutPackages || [];
            this.setState({
                paginatedData: [
                    ...pageData,
                ],
                isPageChanged: true,
                fetchedData: false,
            });
        }
        else if (layoutPackages && layoutPackages.length > 0 && layoutPackages !== prevState.layoutPackages) {
            if (!lodashIsEqual(prevState.layoutPackages, layoutPackages)) {
                const previousLayoutCount = lodashGet(prevState, 'layoutPackages.length', 0);
                const pageData = layoutPackages.slice(previousLayoutCount, previousLayoutCount + RAIL_LIMIT.USER);
                this.setState({
                    paginatedData: [
                        ...paginatedData,
                        ...pageData,
                    ],
                });
            }
        }
        this.ErrorHandle();

        if (layoutPackages && ((!fetchedData && prevProps.pageId === pageIdFromState) || (prevState.paginatedData !== paginatedData))) {
            const existingRailsLength = this.railsRef?.current?.length || 0;
            const railElements = this.getRailElement({ existingRailsLength });
            this.railsRef.current = existingRailsLength ? this.railsRef.current.concat(railElements) : railElements;
            if (this.railsRef.current && Object.keys(this.railsRef.current).length) {
                this.setState(prevState => ({
                    ...prevState,
                    fetchedData: true,
                    railCount: layoutPackages.length,
                }));
            }
        }
    }

    fetchMore = () => {
        const { pageId } = this.state;
        const {
            canFetchMore, layoutFetchActionDispatch, query, isOnehubRoute, msisdnNo, useFallBackLayout,
        } = this.props;
        // if (layoutPackages.length !== paginatedData.length) {
        //     const pageData = layoutPackages.slice(paginatedData.length, paginatedData.length + RAIL_LIMIT.USER);
        //     this.setState({
        //         paginatedData: [
        //             ...paginatedData,
        //             ...pageData,
        //         ],
        //     });
        // }
        const { addToBillEnabled, ...rest } = this.paramsFromUrl;
        this.paramsFromUrl = {
            ...rest,
            ...this.isAddToBill(),
        };
        if (canFetchMore) {
            layoutFetchActionDispatch({
                pageId,
                count: RAIL_LIMIT.USER,
                paramsFromUrl: this.paramsFromUrl,
                isSearchLayout: this.isSearchLayout,
                isOnehubRoute,
                query,
                msisdnNo,
                useFallBackLayout,
            });
            return true;
        }
        return false;
    };

    isAddToBill = () => {
        let obj = {};
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            obj = sessionStore.get(LOCAL_STORE_KEYS.PAY_LATER_USER) ? { addToBillEnabled: true } : {};
        }
        return obj;
    };


    getRailElement = ({ existingRailsLength }) => {
        const {
            contentForLayoutPackages,
            paginatedData,
            tilesToshow,
        } = this.state;
        const {
            channels, themeConfig, contentId, pageId, webPageId, appConfig: { cpDetailsById },
            userConfig: { subscriptionDataById }, canFetchMore, railFactory, scrollBelowQuickView, cpIdForDirectPlayback, resetTileInfoOnFocus,
            scrollRail,
            onFocusCallBack,
            toggleShowPillsSidebarDispatch,
            layoutPageId = '',
            underMultiListRail,
            keyUpCallBack,
            keyDownCallBack,
            layoutInnerWidth,
            focusNextRailTile,
            isSearchDriven = false,
            getAllRoutes,
            location,
            msisdnNo,
            svodActivePlan,
            preferredCp,
        } = this.props;

        const isWeb = this.deviceUtil?.isWeb();
        this.numberOfRails = paginatedData && paginatedData.length;
        const activePlanAmount = svodActivePlan?.length ? svodActivePlan[0]?.price : '';

        return LayoutUtil.getRailElement({
            onFocus: (params) => {
                onFocusCallBack({
                    ...params,
                    fetchMore: this.fetchMore,
                    canFetchMore,
                });
            },
            scrollRail: params => scrollRail({
                ...params,
                paginatedData,
            }),
            focusNextRailTile,
            channels,
            themeConfig,
            cpDetailsById,
            contentForLayoutPackages,
            paginatedData,
            subscriptionDataById,
            numberOfRails: this.numberOfRails,
            contentId,
            pageId: layoutPageId,
            webPageId,
            tilesToshow,
            railLength: paginatedData.length,
            railFactory,
            scrollBelowQuickView,
            cpIdForDirectPlayback,
            isSearchDriven,
            isWeb,
            resetTileInfoOnFocus,
            toggleShowPillsSidebarDispatch,
            underMultiListRail,
            existingRailsLength,
            keyUpCallBack,
            layoutInnerWidth,
            analyticsPageId: pageId,
            msisdnNo,
            activePlanAmount,
            preferredCp,
            keyDownCallBack: (params) => {
                keyDownCallBack({
                    ...params,
                    fetchMore: this.fetchMore,
                    canFetchMore,
                });
            },
            currentRouteList: matchRoutes(getAllRoutes(), location?.pathname),
        });
    };

    fetchChannels() {
        const { channels, fetchAllChannelsDispatch } = this.props;

        if (channels.length <= 1) {
            fetchAllChannelsDispatch();
        }
    }


    componentWillUnmount() {
        const { match, setPreviousPageTitleActionDispatch, updateOnPageDescriptionActionDispatch } = this.props;
        let prevPageTitle = '';
        updateOnPageDescriptionActionDispatch && updateOnPageDescriptionActionDispatch({
            onPageDescription: null,
        });
        if (match.url) {
            prevPageTitle = getRouteKey(match.url);
        }
        if (prevPageTitle !== '') {
            setPreviousPageTitleActionDispatch && setPreviousPageTitleActionDispatch({ prevPageTitle });
        }
        else {
            setPreviousPageTitleActionDispatch && setPreviousPageTitleActionDispatch({ prevPageTitle: '' });
        }
    }

    ErrorHandle = () => {
        const {
            contentForLayoutPackages,
        } = this.state;
        const {
            packageContents,
            layoutError,
            layoutPackages,
            pageId,
            contentId,
            underMultiListRail,
            isErrorPageShownDispatch,
        } = this.props;
        let hasError = false;
        if (layoutError && Object.keys(contentForLayoutPackages).length < 1) {
            hasError = true;
        }
        // revert comment if issue in black hole commented for single marker rail
        // const packageIds = getPackIdsFromLayout(layoutPackages);
        // // no data found
        // if (layoutPackages && (Object.keys(layoutPackages).length < 1 || packageIds.length < 1) && !underMultiListRail) {
        //     // no content  found
        //     hasError = true;
        // }
        if (packageContents.error && Object.keys(contentForLayoutPackages).length < 1) {
            // no content  found
            hasError = true;
        }
        if (hasError) {
            screenVisibleEvent('black_hole', false, {
                contentId,
                page_id: pageId,
            });
            this.setState({ showErrorComponent: true });
            if (isErrorPageShownDispatch) {
                isErrorPageShownDispatch({ isErrorPageShown: true });
            }
        }
        else {
            this.setState({ showErrorComponent: false });
            if (isErrorPageShownDispatch) {
                isErrorPageShownDispatch({ isErrorPageShown: false });
            }
        }
    };

    init(prevProps = {}) {
        const {
            pageId,
            layoutPackages,
        } = this.state;

        // fetch layout
        const {
            userConfig,
            layoutFetchActionDispatch,
            contentId,
            query,
            isMobility,
            isOnehubRoute,
            msisdnNo,
            useFallBackLayout,
            location,
        } = this.props;
        const {
            query: prevQuery = '',
        } = prevProps;

        const isLandingPage = matchPath({
            path: ROUTE_PATHS.LANDING,
            exact: true,
            strict: false,
        }, location?.pathname);

        const queryParam = LocationUtil.getQueryParams(this.props);
        const isLandingLangaugePage = isLandingPage ? queryParam.context_language || '' : '';
        const { addToBillEnabled, ...rest } = this.paramsFromUrl;
        this.paramsFromUrl = {
            ...rest,
            ...queryParam,
            ...this.isAddToBill(),
        };
        if (
            userConfig.userContentProperties
            && (!layoutPackages || (Object.keys(layoutPackages).length < 1) || prevQuery !== query)
        ) {
            // purge local layout if search query chnged or pageid changed
            this.setState({ showErrorComponent: false, fetchedData: false });
            layoutFetchActionDispatch({
                pageId,
                count: RAIL_LIMIT.USER,
                contentId,
                paramsFromUrl: this.paramsFromUrl,
                isSearchLayout: this.isSearchLayout,
                isOnehubRoute,
                query,
                msisdnNo,
                useFallBackLayout,
                // paramsFromUrl: queryParam,
                isLandingLangaugePage,
            });
        }
    }

    render() {
        const {
            pageId: pageIdFromState,
            showErrorComponent,
            fetchedData,
        } = this.state;

        const {
            match,
            match: { params: { pageTitle = '' } },
            appConfig,
            canFetchMore,
            layoutError,
            webPageId,
            webPageId: { PYW_PAGE = 'pywPage' } = {},
            isAuthenticated,
            hideRenewBanner,
            showSubscriptionRibbon,
            Loadercomponent,
            SmallLoaderComponent,
            RenewBannerComponent,
            getPageIdFromProps,
            location,
            matchRoute,
            ErrorPageComponent,
            underMultiListRail,
            isSearchDriven = false,
            getAllRoutes,
        } = this.props;
        // console.log('LayoutContainer rerender');

        let pageId = pageIdFromState;
        const deviceUtil = DeviceUtil.getDeviceUtil();
        const isWeb = deviceUtil?.isWeb();

        // if ( pageId === appConfig.liveTvId) {
        //     return <LiveChannelBox />;
        // }

        if (!pageIdFromState) {
            const { pageId: pageIdFromProps } = getPageIdFromProps(match, appConfig, webPageId);

            if (!pageIdFromProps) {
                return (
                    <Navigate
                        from={RoutingUtil.getHomePage()}
                        to={RoutingUtil.get404Route()}
                    />
                );
            }
            pageId = pageIdFromProps;
        }
        const isPillsPage = matchRoute(ROUTE_PATHS.LANDING, location?.pathname) && pageTitle && !location?.pathname?.toLowerCase().includes(PYW_PAGE?.toLowerCase());

        // const isPillsPage = match.path === ROUTE_PATHS.LANDING && pageTitle;
        const loader = <Loadercomponent pageId={pageId} />;

        // in case of SSR
        if (!checkWindowExist()) {
            // console.error('I am logged hurray ===>>>>>>>>>>>>>>>>>>>>======>>>>>>>>>>>>>>>>>>>>');
            this.railsRef.current = this.getRailElement({ existingRailsLength: 0 });
        }
        const railElements = this.railsRef.current;
        // const smallLoader = <SmallLoaderComponent showRailSkeleton />;
        const smallLoader = SmallLoaderComponent ? <SmallLoaderComponent showRailSkeleton /> : null;
        const layoutApiFailed = !!layoutError;
        if (checkWindowExist()) {
            if (!layoutApiFailed && !fetchedData) {
                return loader;
            }
        }
        else if (!layoutApiFailed && !railElements) {
            return loader;
        }
        const errorLayout = layoutApiFailed ? null : loader;
        const layoutSection = isWeb
            ? (
                <IntersectionObservableHOC
                    hasMore={canFetchMore}
                    loaderElem={smallLoader}
                    fetchMore={this.fetchMore}
                    startPageNumber={1}
                    id={pageId}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                >
                    {railElements}
                </IntersectionObservableHOC>
            )

            : railElements;
        const lazyLoadRailElement = railElements ? layoutSection : errorLayout;
        const isFeatureSliderRailIncluded = railElements && railElements[0] && railElements[0]?.props?.children?.props.railType === RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL;
        const isCdpRoute = RoutingUtil.isCdpRoute(location.pathname, getAllRoutes);
        const layoutClassName = `layout-container-inner ${isPillsPage ? 'pills-page' : ''} ${!isFeatureSliderRailIncluded ? 'pt-5' : ''}`;
        return (
            <>
                {/* {isWeb && isAuthenticated && showSubscriptionRibbon && !hideRenewBanner && !isCdpRoute && !isSearchDriven && <RenewBannerComponent layoutRef={this.layoutRef} /> } */}
                {showErrorComponent ? (
                    <ErrorPageComponent
                        message="Aw snap, something went wrong!"
                        imageUrl={IMAGE_PATHS.BLACK_HOLE}
                        buttonText="Retry"
                        action={() => this.init()}
                        buttonId={`${pageId}-error-cta`}
                        focusOnMount={!underMultiListRail}
                        showOtherRail={!underMultiListRail}
                        focusUpElement=".tab-button-pill.active"
                        focusUpTargetByClass
                    />
                ) : (
                    <section
                        id="layoutSectionElement"
                        ref={this.layoutRef}
                        className={layoutClassName}
                    >
                        {lazyLoadRailElement}
                    </section>
                )}
            </>
        );
    }
}

LayoutContainer.propTypes = {
    userConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    appConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    layoutFetchActionDispatch: PropTypes.func.isRequired,
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    layoutPackages: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    channels: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    layoutError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    packageContents: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    isAuthenticated: PropTypes.bool.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    pageId: PropTypes.string.isRequired,
    fetchEpgChannelDetailsDispatch: PropTypes.func.isRequired,
    hasEpgData: PropTypes.bool.isRequired,
    themeConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    canFetchMore: PropTypes.bool.isRequired,
    pagesLargescreen: PropTypes.isRequired,
    updateLastRailTileMappingActionDispatch: PropTypes.isRequired,
    isSSRData: PropTypes.bool,
    underMultiListRail: PropTypes.bool,
    isBackButtonVisible: PropTypes.bool,
    query: PropTypes.string,
    matchRoute: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    didMountCallack: PropTypes.func,
    keyUpCallBack: PropTypes.func,
    useFallBackLayout: PropTypes.bool,
};

LayoutContainer.defaultProps = {
    layoutError: null,
    layoutPackages: null,
    packageContents: {},
    channels: [],
    underMultiListRail: false,
    isBackButtonVisible: false,
    query: '',
    didMountCallack: () => {},
    keyUpCallBack: () => {},
    lastFocusDetails: {},
    useFallBackLayout: false,
};

export { LayoutContainer };
