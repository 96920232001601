import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import LinkMarkupBuilder from '../builder/LinkMarkupBuilder';
import { BREADCRUMBS_NAME, BREADCRUMBS_LINKS } from '../../../../constants/AppConst';

export default function BreadCrumbMarkupParser({
    breadCrumbsList, baseUrl,
}) {
    const breadCrumbs = breadCrumbsList.map((content, index) => LinkMarkupBuilder({
        name: content[0],
        url: content[1],
        baseUrl,
        position: index,
    }));
    const homeBreadCrumb = [
        LinkMarkupBuilder({
            name: BREADCRUMBS_NAME.HOME,
            url: BREADCRUMBS_LINKS[BREADCRUMBS_NAME.HOME],
            baseUrl,
            position: 1,
        }),
    ];
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.BREADCRUMBLIST,
        itemListElement: breadCrumbs.length ? breadCrumbs : homeBreadCrumb,
    };
}
