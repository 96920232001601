import { ANALYTICS_ASSETS, CUSTOMER_TYPE, PAYMENT_MODES, SUBSCRIPTION_SOURCE } from '@airtel-tv/constants';
import { LocationUtil, RoutingUtil, SubscriptionUtil } from '@airtel-tv/utils';
import { getSid } from '@airtel-tv/utils/GlobalUtil';
import { getCurrentUrl, openTab } from '@airtel-tv/utils/WindowUtil';
import { continueToPayError, subscribeClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { airtelRechargeRedirection } from '@airtel-tv/utils/SubscriptionUtil';
import { activateOneHubPartnerService } from '@airtel-tv/services/PlansAndSubscriptionApiService';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

const continueToPayEvent = ({
    planId,
    circle,
    customerType,
    sid,
    redirectionUrl,
    isError,
    response,
    uid,
    isRechargePlan,
}) => {
    const subscribeClickAnalyticsMeta = {
        pageUrl: getCurrentUrl(),
        appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
        circle,
        packageName: 'XSTREAMPREMIUM',
        sid: getSid(sid, response),
        customer_type: customerType,
        planId,
        source: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
        ingressIntent: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
        pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
        service: ANALYTICS_ASSETS.AIRTELTV,
        uid,
        is_recharge_plan: isRechargePlan,
    };

    if (isError) {
        continueToPayError({
            ...subscribeClickAnalyticsMeta,
            pageUrl: redirectionUrl,
            response: {
                response,
                subscribeClickAnalyticsMeta,
            },
        });
    }
    else {
        subscribeClickEvent({
            ...subscribeClickAnalyticsMeta,
            response: {
                response,
                subscribeClickAnalyticsMeta,
            },
        });
    }
};

const subscribe = (params) => {
    const {
        plan = {},
        purchaseSubscriptionInitDispatch = () => {},
        circle = '',
        uid = '',
        customerType = '',
        showNonAirtelUserActionDispatch = null,
        successUrlOverride,
        cpIdParam,
    } = params;

    if (customerType === CUSTOMER_TYPE.NON_AIRTEL) {
        if (showNonAirtelUserActionDispatch && typeof showNonAirtelUserActionDispatch === 'function') {
            showNonAirtelUserActionDispatch();
        }
        return;
    }
    const { renewPlanId, planId, autoPayEnabled, cpId, partners, duration, sku, meta = {}, price, meta: {
        payment: {
            mode,
        } = {},
    } = {} } = plan;
    const isRechargePlan = mode === PAYMENT_MODES.AIRTEL_PARTNER;
    const planIdToExtend = renewPlanId || planId;
    const skuId = (sku || {}).itunes;
    const {
        offerIdentifier: identifier = '',
        keyIdentifier = '',
        nonce = '',
        signature = '',
        timestamp = 0,
        airtelPlan,
    } = meta;

    let { successUrl, failureUrl } = SubscriptionUtil.getSvodSubscriptionUrl(
        {
            plan: {
                ...plan,
                skuId,
                planId: planIdToExtend,
                price: isRechargePlan ? airtelPlan : price,
                autoPayEnabled: plan?.autoPayEnabled && !isRechargePlan,
                isRechargePlan,
            },
            successPage: RoutingUtil.getOneHubSuccessPage(),
            failurePage: RoutingUtil.getOneHubFailurePage(),
            cpIdParam,
        },
    );
    if (successUrlOverride) {
        successUrl = successUrlOverride;
    }

    purchaseSubscriptionInitDispatch({
        successUrl,
        failureUrl,
        pendingUrl: failureUrl,
        unknownUrl: failureUrl,
        planId: planIdToExtend,
        skuId,
        autoPayEnabled,
        identifier,
        keyIdentifier,
        nonce,
        signature,
        timestamp,
        isRechargePlan,
        disableAutoRenew: isRechargePlan,
        eventMethod: (sid, redirectionUrl, isError, response) => {
            continueToPayEvent({
                planId: planIdToExtend,
                circle,
                customer_type: customerType,
                uid,
                sid,
                redirectionUrl,
                isError,
                response,
                isRechargePlan,
            });
        },
    });
};

export const airtelRecharge = (params) => {
    const {
        tileProps: { planDetails = {} }, msisdnNo, statusUrl, mockPlan,
    } = params;
    const {
        airtelPlan,
        title = '',
        imageSubtitle = '',
        mobileBenefits = [],
        xppBenefits = [],
        dataBenefit = '',
        dataValidity = '',
        additiveValidityEnabled = false,
    } = planDetails?.meta || {};
    const airtelTitle = title.replace('<b>', '').replace('</b>', '');
    airtelRechargeRedirection({
        selectedPlan: {
            total: airtelPlan === 359 && mockPlan === 'true' ? 10 : airtelPlan,
            title: airtelTitle,
        },
        msisdnNo,
        statusUrl,
        // XSTREAM_HOME_CTA_TEXT,
    });
};

export const webPlanPurchase = (params) => {
    const { useNativeRecharge, tileProps: { planDetails } } = params;
    if (useNativeRecharge) {
        subscribe({
            ...params,
            plan: planDetails,
        });
    }
    else {
        airtelRecharge(params);
    }
};

export const redirectToPartnerForActivation = (params = {}) => { //For web and mobility
    const {
        redirectionUrl,
        isLargeScreen,
        isSubscriptions,
        referenceId,
        serviceId,
        cpId,
    } = params;

    if (isLargeScreen) {
        return;
    }

    if (!isSubscriptions) {
        openTab(redirectionUrl, '_blank', 'noopener,noreferrer');
    }
    else if (window && window.AirtelTVClient && window.AirtelTVClient.onSvodRedirection) {
        try {
            // window.AirtelTVClient.openIntent(JSON.stringify({
            //     //eventName: '',
            //     packageName: null,
            //     requestCode: '1234',
            //     action: 'android.intent.action.VIEW',
            //     deeplink: redirectionUrl,
            // }));
            // LocationUtil.handleCloseAppstate();
            window.AirtelTVClient.onSvodRedirection(JSON.stringify({
                packageName: null,
                requestCode: '1234',
                action: 'android.intent.action.VIEW',
                deeplink: redirectionUrl,
                referenceId,
                serviceId,
                cpId,
            }));
        }
        catch (e) {
            window.location.href = redirectionUrl;
        }
    }
    else if (window?.webkit?.messageHandlers?.onSvodRedirection) {
        try {
            // window.webkit.messageHandlers.onExternalDeeplink.postMessage({
            //     onExternalDeeplink: {
            //         "deeplink": redirectionUrl,
            //     },
            // });
            // LocationUtil.handleCloseAppstate();

            window.webkit.messageHandlers.onSvodRedirection.postMessage({
                onSvodRedirection: {
                    "deeplink": redirectionUrl,
                    "referenceId": referenceId,
                    "serviceId": serviceId,
                    "cpId": cpId,
                },
            });
        }
        catch (e) {
            window.location.href = redirectionUrl;
        }
    }
    else {
        window.location.href = redirectionUrl;
    }
};

export const appSvodRefresh = (params) => {
    const {
        referenceId,
        serviceId,
        cpId,
        activationStatus,
    } = params;
    if (window?.webkit?.messageHandlers?.onSvodRedirection) {
        try {
            window.webkit.messageHandlers.svodRefresh.postMessage({
                svodRefresh: {
                    "referenceId": referenceId,
                    "serviceId": serviceId,
                    "cpId": cpId,
                    "activationStatus": activationStatus,
                },
            });
        }
        catch (err) {
            console.log("err", err);
        }
    }
    else if (window && window.AirtelTVClient && window.AirtelTVClient.onSvodRedirection) {
        try {
            window.AirtelTVClient.svodRefresh(JSON.stringify({
                packageName: null,
                requestCode: '1001',
                action: 'android.intent.action.VIEW',
                referenceId,
                serviceId,
                cpId,
                activationStatus,
            }));
        }
        catch (err) {
            console.log(err, err);
        }
    }
    else {
        //Web
        console.log("err", "nothing from web");
    }
};
// export const partnerActivation = async (params) => {
//     const {
//         referenceId,
//         serviceId,
//         deviceUtil,
//         isSubscriptions,
//         isLargeScreen,
//         setPartnerActivationDataDispatch,
//         navigate,
//         updateUserConfigDispatch,
//     } = params;
//     const response = await activateOneHubPartner({
//         data: {
//             referenceId,
//             serviceId,
//         },
//         deviceUtil,
//     });

//     if (isLargeScreen) {
//         setPartnerActivationDataDispatch(response);
//         console.log("kkk", response);
//         response.status = 'SUCCESS';
//         if (response.status === 'SUCCESS') {
//             updateUserConfigDispatch();
//         }
//         const activationRoute = ROUTE_PATHS.PLAN_ACTIVATION.replace(':referenceId', referenceId).replace(':serviceId', serviceId);
//         navigate(activationRoute);
//         return;
//     }

//     const {
//         redirectionUrl,
//     } = response;

//     if (!isSubscriptions) { // For web only
//         window.location.href = redirectionUrl;
//     }
//     else if (window && window.AirtelTVClient && window.AirtelTVClient.openIntent) {
//         try {
//             window.AirtelTVClient.openIntent(JSON.stringify({
//                 //eventName: '',
//                 packageName: null,
//                 requestCode: '1234',
//                 action: 'android.intent.action.VIEW',
//                 deeplink: redirectionUrl,
//             }));
//             LocationUtil.handleCloseAppstate();
//         }
//         catch (e) {
//             window.location.href = redirectionUrl;
//         }
//     }
//     else if (window?.webkit?.messageHandlers) {
//         try {
//             window.webkit.messageHandlers.onExternalDeeplink.postMessage({
//                 onExternalDeeplink: {
//                     "deeplink": redirectionUrl,
//                 },
//             });
//             LocationUtil.handleCloseAppstate();
//         }
//         catch (e) {
//             window.location.href = redirectionUrl;
//         }
//     }
//     else {
//         window.location.href = redirectionUrl;
//     }
// };

export const getPlanTrackingData = ({
    planConfig,
    userConfig,
}) => {
    const { userDetails = {}, sid,} = planConfig || {};
    const { uid, customerType } = userConfig || {};
    return {
        circle: userDetails.circle,
        sid,
        uid,
        service: ANALYTICS_ASSETS.AIRTELTV,
        customerType,
    };
}
