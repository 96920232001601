// import variables from '../modules/shared/scss/abstracts/_variables.scss';
import variables from '@airtel-tv/common-scss/_variables.scss';
// import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import { DEFAULT_NAV } from './RouteConsts';
import { ERROR_CODES } from './ErrorCodes';

export const LANGUAGES = {
    ENGLISH: 'en',
    HINDI: 'hi',
    THAILAND: 'th',
};

export const XSTREAM_PREMIUM = {
    COMING_SOON: 'Coming Soon',
};

export const MAX_LANGUAGE_SELECTION_LIMIT = 4;

export const LANGUAGE_CONTAINER_TYPE = {
    OLD: 'OLD',
    NEW: 'NEW',
};

export const LANGUAGES_CODES = {
    th: 'Thai',
    en: 'English',
    eng: 'English',
    hi: 'Hindi',
    as: 'Assamese',
    bn: 'Bengali',
    bj: 'Bhojpuri',
    bh: 'Bhojpuri',
    bho: 'Bhojpuri',
    gu: 'Gujarati',
    ha: 'Haryanvi',
    kn: 'Kannada',
    ml: 'Malayalam',
    mr: 'Marathi',
    od: 'Odia',
    or: 'Oriya',
    ph: 'Pahadi',
    pa: 'Punjabi',
    ra: 'Rajasthani',
    ta: 'Tamil',
    te: 'Telugu',
    ur: 'Urdu',
    id: 'Indonesian',
    ru: 'Russian',
    fil: 'Filipino',
    tl: 'Tagalog',
    vi: 'Vietnamese',
};

export const ELEMENT_ID = {
    CDP_BANNER: 'cdp-banner',
    PLAY_CONTENT_BUTTON: 'play-content-button',
    PLAY_CONTENT_START_BUTTON: 'PLAY_CONTENT_START_BUTTON',
    MOBILE_PLAY_CONTENT_BUTTON: 'mobile-play-content-button',
    BANNER_VOLUME_BUTTON: 'banner-volume-button',
    ADD_TO_WATCHLIST: 'add-to-watchlist',
    MOBILE_ADD_TO_WATCHLIST: 'mobile-add-to-watchlist',
    WATCH_TRAILER_BUTTON: 'watch-trailer-button',
    MOBILE_WATCH_TRAILER_BUTTON: 'mobile-watch-trailer-button',
    CONTENT_GENRE: 'content-genre',
    BANNER_CONTENT_GENRE: 'banner-content-genre',
    MOBILE_BANNER_CONTENT_GENRE: 'mobile-banner-content-genre',
    NO_OF_SEASONS: 'number-of-seasons',
    AUDIO_LANGUAGES: 'audio-languages',
    RELEASE_YEAR: 'content-release-year',
    BANNER_CONTENT_TITLE: 'banner-content-title',
    MOBILE_BANNER_CONTENT_TITLE: 'mobile-banner-content-title',
    BANNER_DETAIL_CONTAINER: 'banner-details-container',
    BANNER_CONTENT_DESCRIPTION: 'banner-content-description',
    MOBILE_BANNER_CONTENT_DESCRIPTION: 'mobile-banner-content-description',
    BANNER_AUDIO_LANGUAGES: 'banner-audio-languages',
    MOBILE_BANNER_AUDIO_LANGUAGES: 'mobile-banner-audio-languages',
    CONTENT_SHARE_BUTTON: 'content-share-button',
    MOBILE_CONTENT_SHARE_BUTTON: 'mobile-content-share-button',
    PLAN_DESCRIPTION: 'plan-description',
    MOBILE_PLAN_DESCRIPTION: 'mobile-plan-description',
    BANNER_RELEASE_YEAR: 'banner-content-release-year',
    BANNER_CONTENT_LANGUAGE: 'banner-content-language',
    HEADER_WEB: 'header-web',
    CONTINUE_WATCHING_TEXT: 'continue-watching-text',
    WATCHLIST_TITLE_TEXT: 'Watchlist-watching-text',
    RAIL_TITLE: 'rail-title',
    RAIL_ID: 'rail-id',
    RENEW_BANNER: 'renew-banner',
    HEADER_CONTAINER: 'header_container',
    TOGGLE_FULLSCREEN: 'toggle-fullscreen',
    POTRAIT_RAIL: 'potrait-rail',
    CONT_WATCHING_RAIL: 'continue-watching-rail',
    WATCHLIST_RAIL: 'watchlist-rail',
    PYW_WATCHLIST_RAIL: 'pyw-watchlist-rail',
    TRAILERS_RAIL: 'trailers-rail',
    LANDSCAPE_RAIL: 'landscape-rail',
    EXPLORE_CHANNEL_RAIL: 'explore-channel-rail',
    LIST_RAIL: 'list-rail',
    CDP_TABS_TO_FOCUS: '.tab-button-pill.can-focus.active',
    CDP_TABS_CLASS: 'tab-button-pill',
    PARTNER_ACTIVATION: 'PARTNER_ACTIVATION',
    CDP_TABS_ACTIVE_CLASS: 'active',
    CDP_TABS_ID: 'cdp-tab-{index}',
    LAYOUT_TILE_ID: '{pageid}-tile-{railposition}-{tileposition}',
    DESCRIPION_TILE: 'descripion-tile',
    PLAN_LISTING_BUTTONS: 'plans-railposition-{railIndex}-tileposition-{tileIndex}',
    PLAN_DETAILS_BUTTONS: 'plans-details-{railIndex}-tileposition-{tileIndex}',
    RECHARGE_PLANS_BUTTON: 'recharge-plans-button',
    SUBSCRIPTION_PLANS_BUTTON: 'subscription-plans-button',
    PLAN_DETAIL_RECHARGE_BUTTON: 'plan-detail-recharge-button',
    PLAN_DETAIL_BACK_BUTTON: 'plan-detail-back-button',
    PLAYER_ID: 'video-box',
    SEEKBAR: 'seekbar',
    PLAY_FROM_BEGINNING: 'PLAY_FROM_BEGINNING',
    CAN_SCROLL_LG: 'can-scroll-lg',
    X_WARNING_RAIL_BUTTON: 'x-warning-btn',
    CHANNEL_GUIDE: 'channel-guide',
    FAVOURITES: 'favourites',
};

export const DROPDOWN_NAMES = {
    LANGUAGE: 'Language',
    TYPE: 'Type',
    GENRE: 'Genres',
    SORT: 'Sort',
    NONE: 'None',
    ALL: 'All',
};

export const LOCAL_STORE_KEYS = {
    TOKEN: 'token',
    UID: 'uid',
    MSISDN_DETECTED: 'msisdnDetected',
    LAST_WATCHED: 'lw',
    SEARCH_QUERIES: 'SEARCH_QUERIES',
    ACCESS_TOKEN: 'authToken',
    VISIT_COUNT: 'visit_count',
    LOGIN_COUNT: 'login_count',
    SHOW_APP_HOOK: 'show_app_hook',
    VISIT_COUNT_AFTER_HIDE_APP_HOOK: 'v_after_hide_app_hook',
    USER_DATA: 'user_data',
    FINGER_PRINT: 'finger_print',
    TEMP_LANG: 'temp_lang',
    USER_LANG: 'user_lang',
    PREFERRED_PARTNER_PLANS_REFIDS: 'preferredPartnerPlanRefIds',
    CHANNEL_PARTNER_ID: 'cp',
    APP_ID: 'appId',
    OS: 'os',
    APP_VERSION: 'appVersion',
    // player keys
    VOLUME_LEVEL: 'x-player-volume',
    BIT_RATE: 'x-player-quality',
    DEVICEID: 'deviceId',
    DEVICETYPE: 'deviceType',
    OSVERSION: 'osVersion',
    BUILDNO: 'buildNo',
    MOBILITYOS: 'mobilityOS',
    IS_HOICHOI_CAMPAIGN: 'is_hoichoi_campaign',
    ANALYTICS_META: 'analyticsMeta',
    TRAILER_AUTOPLAY_MUTE: 'trailerAutoplayMute',
    SHOW_RIBBON: 'show_ribbon',
    APP_SESSION_ID: 'app_session_id',
    STORED_LAYOUT_DATA: 'STORED_LAYOUT_DATA',
    FMF_DISPLAYED: 'FMF_DISPLAYED',
    UDID: 'UDID',
    IS_INSTALLED: 'isInstalled',
    SELECTED_SUBTITLE_FOR_USER: 'selectedSubtitles_${uid}',
    ANALYTICS_STITCH_META: 'stitchMeta',
    brand: 'brand',
    dname: 'dname',
    model: 'model',
    osVersion: 'osVersion',
    TOTAL_MEMORY: 'TOTAL_MEMORY',
    AVAILABLE_MEMORY: 'AVAILABLE_MEMORY',
    XPP_CLAIM_DISPLAYED: 'XPP_CLAIM_DISPLAYED',
    ACTIVATION_PENDING: 'ACTIVATION_PENDING',
    LOGIN_DEVICE_RESPONSE: 'LOGIN_DEVICE_RESPONSE',
    LOGIN_DEVICE_SUCCESS: 'LOGIN_DEVICE_SUCCESS',
    SEARCH_SESSION_CODE: 'SEARCH_SESSION_CODE',
    HAS_USER_NAVIGATED_TO_APP: 'HAS_USER_NAVIGATED_TO_APP',
    PAY_LATER_USER: 'addToBillEnabled',
    SUBSCRIPTION_DETAILS: 'SUBSCRIPTION_DETAILS',
    MEDIAKEY: 'mediaKey',
    GLOBALELAPSEDTIME: 'globalElapsedTime',
    SUN_CLIENTKEY: 'sun_clientKey',
    BOXID: 'boxId',
    USERDETAILS: 'userDetails',
    ANALYTICS_SOURCE: 'analyticsSource',
    FAVOURITE_CHANNELS: 'favouriteChannels',
    PYW_TRAILER_AUTOPLAY_MUTE: 'pywtrailerautoplay',
    TOTAL_PLAYBACK_TIME: 'total_playback_time',
    PLAYBACK_SOFT_NUDGE_INDEX: 'playback_soft_nudge_index',
    PLAYBACK_SOFT_NUDGE_TIMER_LIST: 'playback_soft_nudge_timer_list',
    VIEW_TYPE: 'viewType',
    WEBVIEW_OPEN_TIMESTAMP: 'webview_open_timestamp',
    UTM_PARAMS_STRING: 'utm_params_string',
    THANKS_APP_SESSION_ID: 'thanks_app_session_id',
    SSO_TOKEN: 'sso_token',
};

export const APP_HOOK_VISIBLE_AFTER_VIS_COUNT = 3;

export const ROLE_TYPES = {
    DIRECTOR: 'director',
    ACTOR: 'actor',
};

export const PROGRAM_TYPES = {
    HOME: 'HOME',
    CHANNEL: 'CHANNEL',
    MOVIE: 'MOVIE',
    MOVIETAB: 'MOVIETAB',
    SEASON: 'SEASON',
    SHORTMOVIE: 'SHORTMOVIE',
    VIDEO: 'VIDEO',
    EPISODE: 'EPISODE',
    SERIES: 'SERIES',
    SPORTS: 'SPORTS',
    TVSHOW: 'TVSHOW',
    TVSHOWTAB: 'TVSHOWTAB',
    CHANNELTAB: 'CHANNELTAB',
    OTHER: 'OTHER',
    PEOPLE: 'PEOPLE',
    LIVE: 'LIVE',
    LIVETVSHOW: 'LIVETVSHOW',
    LIVETVCHANNEL: 'LIVETVCHANNEL',
    LIVETVCHANNELTAB: 'LIVETVCHANNELTAB',
    LIVETV: 'LIVETV',
    LIVETVMOVIE: 'LIVETVMOVIE',
    TRAILER: 'TRAILER',
    AD: 'AD',
    NEWS: 'NEWS',
    MATCH: 'MATCH',
    PROGRAM: 'PROGRAM',
    CATCHUP_EPISODE: 'CATCHUP_EPISODE',
    PACKAGE: 'PACKAGE',
    EVENT: 'EVENT',
    PROMO: 'PROMO',
    TVSERIES: 'TVSeries',
    TVSEASON: 'TVSeason',
};

export const PROGRAM_TYPES_CATEGORY = {
    VOD: 'vod',
    CATCHUP: 'catchup',
    LIVE: 'live',
};

export const DIMENSION_PAGE_MAP = {
    MOVIE: 'movies',
    LIVETVMOVIE: 'movies',

    TVSHOW: 'tv-shows',
    EPISODE: 'tv-shows',
    LIVETV: 'livetv-channels',
    PROGRAM: 'livetv-channels',

    LIVETVSHOW: 'tv-shows',
    LIVETVCHANNEL: 'livetv-channels',

    VIDEO: 'video',
};

export const DIMENSION_URL_TO_TYPE = {
    live: 'LIVETVCHANNEL',
    tv_shows: 'TVSHOW',
    movies: 'MOVIE',
    videos: 'VIDEO',
};

export const DIMENSIONS_FOR_CALL_MAP = {
    CHANNELS: `${PROGRAM_TYPES.LIVETVCHANNEL}`,
    MOVIE: `${PROGRAM_TYPES.MOVIE},${PROGRAM_TYPES.LIVETVMOVIE}`,
    LIVETVMOVIE: `${PROGRAM_TYPES.MOVIE},${PROGRAM_TYPES.LIVETVMOVIE}`,
    VIDEO: `${PROGRAM_TYPES.VIDEO}`,
    TVSHOW: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    EPISODE: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    SEASON: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,

    LIVETVSHOW: `${PROGRAM_TYPES.TVSHOW},${PROGRAM_TYPES.LIVETVSHOW}`,
    LIVETVCHANNEL: PROGRAM_TYPES.LIVETVCHANNEL,
    LIVETV: PROGRAM_TYPES.LIVETVCHANNEL,
};

export const DIMENSIONS_SUPPORTED_MAP = {
    MOVIE: PROGRAM_TYPES.MOVIE,
    LIVETVMOVIE: PROGRAM_TYPES.MOVIE,
    VIDEO: PROGRAM_TYPES.VIDEO,

    TVSHOW: PROGRAM_TYPES.TVSHOW,
    EPISODE: PROGRAM_TYPES.TVSHOW,
    SEASON: PROGRAM_TYPES.TVSHOW,

    LIVETVSHOW: PROGRAM_TYPES.TVSHOW,
    LIVETVCHANNEL: PROGRAM_TYPES.LIVETVCHANNEL,
    PROGRAM: PROGRAM_TYPES.LIVETV,
};

export const DIMENSION_PAGE_CONTENT_LIMIT = {
    GOOGLE_BOT: 200,
    USER: 30,
};

export const DROPDOWN_CONTENT_NAME_LIMIT = 15;

export const ICON_POSITION = {
    RIGHT_150: '150px',
    RIGHT_80: '80px',
};

export const TAB_POSITION = {
    TOP_208: '208px',
    TOP_62: '62px',
};

export const DROPDOWN_NAMES_LIMIT = {
    MOBILE: 10,
    DESKTOP: 15,
};

export const SCREEN_SIZES = {
    IPAD_SIZE: 768,
    MOBILE_MEDIUM: 577,
    MOBILE_SMALL: 481,
    MOBILE_MINI: 321,
};

export const DROP_DOWN_LIMIT = 6;

export const DIMENSION_SORT_OPTIONS = {
    NONE: 'NONE',
    ALPHABETIC_ASCENDING: 'ALPHABETIC_ASCENDING',
    ALPHABETIC_DESCENDING: 'ALPHABETIC_DESCENDING',
    NEWLY_ADDED: 'NEWLY_ADDED',
};

export const DIMENSION_SORT_DISPLAY = {
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING]: 'A-Z',
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING]: 'Z-A',
    [DIMENSION_SORT_OPTIONS.NEWLY_ADDED]: 'Recent',
    [DIMENSION_SORT_OPTIONS.NONE]: 'None',
    [DROPDOWN_NAMES.ALL]: 'All',
};

export const PLAYBACK_CHECK = {
    BROWSER: 'BROWSER',
    TV: 'TV',
};

export const DIMENSION_SORT_API_OPTION = {
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_ASCENDING]: 'asc',
    [DIMENSION_SORT_OPTIONS.ALPHABETIC_DESCENDING]: 'desc',
    [DIMENSION_SORT_OPTIONS.NEWLY_ADDED]: 'desc',
};

export const DIMENSION_PROGRAMTYPE_MAP = {
    [PROGRAM_TYPES.MOVIE]: 'Movies',
    [PROGRAM_TYPES.TVSHOW]: 'TV Shows',
    [PROGRAM_TYPES.LIVETVCHANNEL]: 'Channels',
    [PROGRAM_TYPES.LIVETV]: 'Live TV',
    [PROGRAM_TYPES.VIDEO]: 'Video',
};

export const PROGRAM_TYPE_CATGEGORY_MAP = {
    [PROGRAM_TYPES.CHANNEL]: 'live',
    [PROGRAM_TYPES.MOVIE]: 'vod',
    [PROGRAM_TYPES.SEASON]: 'vod',
    [PROGRAM_TYPES.SHORTMOVIE]: 'vod',
    [PROGRAM_TYPES.VIDEO]: 'vod',
    [PROGRAM_TYPES.EPISODE]: 'vod',
    [PROGRAM_TYPES.SERIES]: 'vod',
    [PROGRAM_TYPES.SPORTS]: 'vod',
    [PROGRAM_TYPES.TVSHOW]: 'vod',
    [PROGRAM_TYPES.OTHER]: 'vod',
    [PROGRAM_TYPES.PEOPLE]: 'vod',
    [PROGRAM_TYPES.LIVE]: 'live',
    [PROGRAM_TYPES.LIVETVSHOW]: 'catchup',
    [PROGRAM_TYPES.LIVETVCHANNEL]: 'live',
    [PROGRAM_TYPES.LIVETVMOVIE]: 'catchup',
    [PROGRAM_TYPES.TRAILER]: 'vod',
    [PROGRAM_TYPES.NEWS]: 'live',
    [PROGRAM_TYPES.MATCH]: 'live',
    [PROGRAM_TYPES.PROGRAM]: 'live',
    [PROGRAM_TYPES.CATCHUP_EPISODE]: 'catchup',
};

export const PROGRAM_TYPE_TITLE_MAPPING = {
    MOVIE: 'movies',

    LIVE: 'live',
    LIVETVSHOW: 'livetv-channels',
    LIVETVCHANNEL: 'livetv-channels',
    LIVETVMOVIE: 'livetv-channels',
    CATCHUP_EPISODE: 'livetv-channels',

    SEASON: 'tv-shows',
    EPISODE: 'tv-shows',
    TVSHOW: 'tv-shows',

    CHANNEL: 'channel',
    CHANNEL_PARTNER_PAGE: 'channel',

    TRAILER: 'trailer',
    AD: 'ad',
    NEWS: 'news',
    MATCH: 'match',
    PROGRAM: 'program',
    SHORTMOVIE: 'short-movies',
    VIDEO: 'videos',
    SERIES: 'series',
    SPORTS: 'sports',
    OTHER: 'videos',
    PEOPLE: 'people',
    PROMOTION_PAGE: 'event',
};

export const COMPONENT_TYPE = {
    ACCORDIAN: 'accordian',
    HTML: 'html',
    ANCHOR: 'a',
    LINK: 'link',
    ANALYTIC: 'analytic',
    PROFILE: 'profile',
};

export const IMAGE_PATHS_WEB = {
    ARTIST_DEFAULT_ICON: '/static/image-icon-default.svg',
    BELL_REMIND: '/static/Bell.svg',
    DEFAULT_CAST: '/static/cast4.png',
    BUY_SUBSCRIPTION: '/static/error/renew-subscription.svg',
    HELP_DESK: '/static/error/notify-helpdesk.svg',
    MANAGE_DEVICES: '/static/error/manage-your-devices.svg',
    AIRTEL_LOGO: '/static/splash_logo.png',
    AIRTEL_DARK_LOGO: '/static/splash_logo.png',
    SITE_LOGO: '/static/xstream_play_logo.svg',
    SITE_LOGO_NEW: '/static/airtel-logo-new.svg',
    GO_PREMIUM_LOGO: '/static/go_premium_logo.svg',
    SITE_DARK_LOGO: '/static/xstream_play_logo.svg',
    UNLIMITED_ENTERTAINMENT: '/static/introduction-slider/slider-1.svg',
    BEST_IN_CLASS_VIEWING_EXPERIENCE: '/static/introduction-slider/slider-2.svg',
    AIRTEL_TV_ANYTIME_ANYWHERE: '/static/introduction-slider/slider-3.svg',
    PLAYER_ERROR: '/static/error/player-error.svg',
    NETWORK_ERROR: '/static/connection-problem.svg',
    ERROR_PAGE_OPSS: '/static/error-page/error-loading.svg',
    ERROR_PAGE_404: '/static/error-page/error-404.svg',
    DEFAULT_PLAYER_BACKGROUND: 'https://image.airtel.tv/grandslam/content/2024_10_3/placeholder-player.jpg',
    [ERROR_CODES.ATV262]: '/static/error/not-found.svg',
    [ERROR_CODES.ATV410]: 'static/error/not-found.svg',
    FOOTER_PLAY_STORE: '/static/button-playstore.svg',
    FOOTER_APP_STORE: '/static/button-appstore.svg',
    PLAYER_PLACEHODER: '/static/placeholder-player.jpg',
    APP_FLAYER_CLOSE_BTN: '/static/ic_actionbar_close.svg',
    UPGRADE_TO_PREMIUM: 'static/upgrade-to-premium.svg',
    [DEFAULT_NAV.HOME]: '/static/og-images/general.png',
    [PROGRAM_TYPE_TITLE_MAPPING.LIVETVCHANNEL]: '/static/og-images/livetv.png',
    [PROGRAM_TYPE_TITLE_MAPPING.MOVIE]: '/static/og-images/movie.png',
    [PROGRAM_TYPE_TITLE_MAPPING.TVSHOW]: '/static/og-images/tvshow.png',
    WHITE_CLOSE_BUTTON: '/static/close.svg',
    WATCHLIST_ICONS_ADD: '/static/add-watchlist-icon.svg',
    WATCHLIST_ICON_LOGO: '/static/webtv-watchlist-logo.svg',
    WATCHLIST_ICONS_REMOVE: '/static/remove-watchlist-icon.svg',
    HELP_SUPPORT_ICONS_ADD: '/static/more-menu/help-icon.svg',
    LOGOUT_ICONS_ADD: '/static/more-menu/logout-icon.svg',
    DOWNLOAD_ICONS_ADD: '/static/more-downloads.svg',
    FEEDBACK_ICONS_ADD: '/static/more-menu/feedback-icon.svg',
    WATCHLIST_EMPTY_STATE_ICONS: '/static/watchlist-empty.svg',
    CHEVERON_ICONS: '/static/cheveron.svg',
    CLOSE_BUTTON_OVERLAY: '/static/dimensionPages/closeButton.svg',
    TICK_MARK: '/static/dimensionPages/TickMark.svg',
    SORT_BUTTON: '/static/dimensionPages/sortButton.svg',
    CEHVRON_DROPDOWN: '/static/dimensionPages/DropDownChevron.svg',
    SELECTED_FIELD: '/static/dimensionPages/selectedField.svg',
    BANNER_LEFT: '/static/chevron-left.svg',
    BANNER_RIGHT: '/static/chevron-right.svg',
    BANNER_LEFT_NEW: '/static/left-arrow-icon.svg',
    BANNER_RIGHT_NEW: '/static/right-arrow-icon.svg',
    LIVE_TAG: '/static/schedule_rails_asset/live.svg',
    RAIL_TITLE_ARROW: '/static/schedule_rails_asset/title-link.svg',
    TILE_LINK_ARROW: '/static/schedule_rails_asset/white-arrow.svg',
    RIGHT_BUTTON: '/static/schedule_rails_asset/next-button.svg',
    LEFT_BUTTON: '/static/schedule_rails_asset/prev-button.svg',
    ALERT_BELL: '/static/schedule_page_asset/alertbell.svg',
    LANGUAGE_ICON: '/static/nav-icons/top-nav-language-icon.svg',
    PROFILE_ICON: '/static/nav-icons/profile-icon.svg',
    PROFILE_ICON_NEW: '/static/nav-icons/profile-icon-new.svg',
    APP_HOOK_INSTALL: '/static/nav-icons/top-nav-download-icon.svg',
    SEARCH_ICON: '/static/nav-icons/top-nav-search-icon.svg',
    HOME_ICON: '/static/nav-icons/home-icon.svg',
    BOTTOM_NAV_SEARCH_ICON: '/static/nav-icons/bottom-nav-search-icon.svg',
    MENU_ICON: '/static/nav-icons/menu-icon.svg',
    YOU_ICON: '/static/nav-icons/you-icon.svg',
    CANCEL_ICON: '/static/search/cancel.svg',
    RECENT_SEARCH_ICON: '/static/search/recent.svg',
    TRENDING_SEARCH_ICON: '/static/search/trending-search.svg',
    VOLUME_ZERO_ICON: '/static/volume-button/volume-zero-kc.svg',
    VOLUME_LOW_ICON: '/static/volume-button/volume-low-kc.svg',
    VOLUME_HIGH_ICON: '/static/volume-button/volume-high-kc.svg',
    BACK_ARROW: '/static/player-back-new.svg',
    BACK_ARROW_LS: '/static/back-arrow.svg',
    DONE_ICON: '/static/done-icon.svg',
    LINE_ICON: '/static/line-icon.svg',
    NEXT_EPISODE: '/static/next-episode-new.svg',
    NEXT_EPISODE_LS: '/static/play-back-controls/skip_next.svg',
    SETTINGS_LS: '/static/play-back-ui/settings-top.svg',
    SETTINGS: '/static/settings-new-player.svg',
    RIGHT_ARROW: '/static/play-back-controls/right-arrow.svg',
    LEFT_ARROW: '/static/play-back-controls/left-arrow.svg',
    FORWARD_SEEK: '/static/player-forward-new.svg',
    FORWARD_SEEK_LS: '/static/play-back-controls/forward-seek.svg',
    DISABLED_FORWARD_SEEK: '/static/play-back-controls/disabled-forward-seek.svg',
    BACKWARD_SEEK: '/static/player-backward-new.svg',
    BACKWARD_SEEK_LS: '/static/play-back-controls/backward-seek.svg',
    DISABLED_BACKWARD_SEEK: '/static/play-back-controls/disabled-backward-seek.svg',
    PAUSE: '/static/play-back-controls/pause.svg',
    PLAY: '/static/player-play-new.svg',
    PLAY_LS: '/static/play-back-controls/play.svg',
    USER_PROFILE_ICON: '/static/youpage/you_profile.svg',
    USER_PROFILE_CHEVRON: '/static/youpage/you_cheveron_small.svg',
    YOU_PAGE_WATCHLIST: '/static/youpage/playlist_add.svg',
    YOU_PAGE_VEDIO_LANGUAGE: '/static/youpage/contentlanguage.svg',
    BOLD_BACK_ARROW: '/static/UpdateProfile_assets/Back-arrow.svg',
    PIP_BUTTON: '/static/play-back-controls/PiPButton.svg',
    DTH_ASSET_VIDEO_ICON: '/static/largescreen/plan-list-assets/movies.png',
    DTH_ASSET_SUBTITLE: '/static/largescreen/plan-list-assets/multi-audio.png',
    DTH_ASSET_PLAY_ICON: '/static/largescreen/plan-list-assets/watchlist.png',
    DTH_EROS_NOW_ICON: '/static/largescreen/plan-list-assets/eros.png',
    DTH_HUNGAMA_ICON: '/static/largescreen/plan-list-assets/Hungama.png',
    DTH_SHEMAROO_ICON: '/static/largescreen/plan-list-assets/Shemaroo.png',
    DTH_HOICHOI_ICON: '/static/largescreen/plan-list-assets/hich.png',
    DTH_ULTRA_ICON: '/static/largescreen/plan-list-assets/Ultra.png',
    DTH_VOOT_ICON: '/static/largescreen/plan-list-assets/voot.png',
    PIP_ICON: '/static/pip-new-player.svg',
    PIP_ICON_LS: '/static/pip_assets/pip-icon.svg',
    SUBSCRIBED_TICK: '/static/double-ticks.svg',
    REPLAY_BUTTON: '/static/channel_partner/replay.svg',
    SUBSCRIBED_CHANNEL_TICKMARK: '/static/channel_partner/subscribedTickmark.svg',
    SHARE_ICON: '/static/share-icon.svg',
    WHATSAPP_ICON: '/static/whatsapp-icon.svg',
    SHARE_BUTTON: '/static/channel_partner/shareButton.svg',
    LOCK_ICON: '/static/overlay/lock.svg',
    GIFT_ICON: '/static/more-menu/gift-icon.svg',
    ERROR_MARKER: '/static/Happy-code-assets/error-marker.svg',
    SUCCESS: '/static/Happy-code-assets/sucess.svg',
    MODALPOPUP_CROSS: '/static/modalpopup_asset/modal-cross.svg',
    GIFT_BOX: '/static/modalpopup_asset/Gift_box.svg',
    PP_UNLOCK: '/static/modalpopup_asset/unlock.svg',
    PP_BACK: '/static/modalpopup_asset/back-arrow.svg',
    PP_BANNER: '/static/modalpopup_asset/offer-banner.svg',
    PP_GIFT_CLOSE: '/static/modalpopup_asset/gift-box-close.svg',
    SAD_EMOJI: '/static/gratification-asset/sad-emoji.svg',
    GRATIFICATION_CROSS: '/static/gratification-asset/gratification-cross.svg',
    OFFER_CHEV_LEFT: '/static/pp_assets/chevron_left.svg',
    OFFER_CHEV_RIGHT: '/static/pp_assets/chevron_right.svg',
    PREMIUM_SLIDER_CHEV: '/static/slider-chevron.svg',
    PROFILE_SECOND: '/static/profile-2.svg',
    PROFILE_BACK: '/static/profile-back.svg',
    BACKGROUND_IMAGE: '/static/background-gradient.png',
    XMP_N_TICK: '/static/xmp&Tick.svg',
    BLACK_HOLE: '/static/astronaut.svg',
    ERROR_YELLOW: '/static/error_yellow.svg',
    TICK_YELLOW: '/static/tick_yellow.svg',
    APPSTORE_BLACK: '/static/appstore_black.png',
    PLAYSTORE_BLACK: '/static/playstore_black.png',
    THREE_DOTS_VERTICAL: '/static/dots_three_vertical.svg',
    CROSS: '/static/cross.svg',
    XSTREAM_PREMIUM_CIRCULAR: '/static/xstream_circular.png',
    SETTIINGS_NEW_CHEV: '/static/settings-chev-new.svg',
    SIDE_CHEVRON: '/static/cdp-assets/chev-sside.svg',
    SUB_AIRTEL_LOGO: '/static/subscription-air-logo.svg',
    PLAY_AGAIN_TRAILER: '/static/play-again-trailer.svg',
    TRAILER_XSTREAM_ICON: '/static/trailler-xstream-icon.svg',
    ADD_TO_WATCHLIST_BLACK: '/static/black_plus.svg',
    REMOVE_TO_WATCHLIST_BLACK: '/static/black_tick.svg',

    //* ** used in login ***/
    OTP_CONFIRMED: '/static/confirmed.svg',
    DELETE_ONE: '/static/ic_delete.svg',
    DELETE_ALL: '/static/ic_remove.svg',
    LOGIN_RIGHT_ARROW: '/static/right-cheveron.svg',
    EMPTY_SEARCH_ICON: '/static/search/ic_search_empty.svg',
    SPACE_BAR_ICON: '/static/search/space_icon.svg',
    EXIT_ICON: '/static/ostv/ostv-exit.svg',
    WEBTV_PLAY_ICON: '/static/webtv-play.svg',
    BACK_TOP_BUTTON: '/static/play-back-ui/back-svg-top.svg',
    LEFT_SKIP: '/static/play-back-ui/left-skip.svg',
    RIGHT_SKIP: '/static/play-back-ui/right-skip.svg',
    SUB_TITLE_ICON: '/static/play-back-ui/sub-title-icon.svg',
    SUB_TITLE_TICK: '/static/play-back-ui/sub-title-tick.svg',
    WEBTV_PLANS_TICK: '/static/browseplan-assets/tick.svg',
    ACCOUNT_INFO_ICON: '/static/managesettings-icons/account-info.svg',
    MANAGE_PLAN_ICON: '/static/managesettings-icons/manage-plan.svg',
    CONTENT_LANGUAGE_ICON: '/static/managesettings-icons/content-language.svg',
    WEB_TV_HELP_ICON: '/static/managesettings-icons/help.svg',
    WEB_TV_LOGOUT_ICON: '/static/logout-icon-wtv.svg',
    WEB_TV_LOGIN_ICON: '/static/login-icon.svg',
    PAYMENTS_BACK_ARROW: '/static/payments-assets/payments-back.svg',
    EXPIRE_TAG: '/static/browseplan-assets/expire.svg',
    RED_TICK: '/static/browseplan-assets/red-tick.svg',
    PAYMENT_LOADER: '/static/payment-loader.svg',
    LIVE_SCHEDULE: '/static/live-schedule.svg',

    SPLASH_LOGO: '/static/splash_logo.png',
    INPROGRESS_LOGO: '/static/inprogress-subscription.svg',
    FAILURE_POPUP_LOGO: '/static/payment-unscucess.svg',
    APP_LOGO: '/static/splash_logo.svg',
    START_STREAMING: '/static/popcorn.svg',
    TICK_MARK1: '/static/red-language-tick.svg',
    INFO_ICON: '/static/info.svg',
    BACK_BUTTON: '/static/back-button-image.png',
    PORT_AIRTEL: '/static/port_airtel.svg',
    LOGIN_BACKGROUND: '/static/login-background.png',
    ARROW: '/static/arrow-left.png',
    PLANS_PAGE_BACKGROUND: './static/PlansPageBackground.svg',
    CHANNELS_FOR_PLANS_PAGE: './static/ChannelsForPlansPage.svg',
    AIRTEL_ELIPSE: 'https://image.airtel.tv/grandslam/content/2023_12_19/Ellipse_17890.png',
    FREE_TAG: 'https://image.airtel.tv/grandslam/content/2023_12_19/Free_TAG.png',
    BLOG_PAGE_LOGO: 'https://image.airtel.tv/grandslam/content/2023_9_29/BlogLogo2.png',
    MONITOR_LOGO: 'https://image.airtel.tv/grandslam/content/2023_9_29/MonitorPlay.png',
    GET_APP_ICON: 'https://assets.airtel.in/static-assets/new-home/img/get-app.svg',
    DEVICE_LOGOUT: '/static/device-logout.svg',
    DEVICE_LOGOUT_LS: './static/device-logout.svg',
    TV_LOGIN_ICON: '/static/tv-login.svg',
    CHECK_SUCCESS: '/static/check-success.svg',
    GOOGLE_PLAY: '/static/google-play.svg',
    APPLE_STORE: '/static/apple-store.svg',
    XSTREAM_PLAY_ICON: '/static/xstream-play-icon.svg',
    SUCCESS_CONFETTI: '/static/login-success-confetti.svg',
    SUCCESS_LOGIN_BG: '/static/bg-gradient-radiel.svg',
    FREE_TEXT: 'https://image.airtel.tv/grandslam/content/2024_4_22/free-sheet.png',
    SUN_RAYS: 'https://image.airtel.tv/grandslam/content/2024_4_25/rays.png',
    SUN_RAYS_LARGE: 'https://image.airtel.tv/grandslam/content/2024_6_26/sun_rays.png',
    SUN_RAYS_MOBILE: 'https://image.airtel.tv/grandslam/content/2024_6_27/sun-rays-mobile_(1).png',
    SUN_RAYS_NEW: 'https://image.airtel.tv/grandslam/content/2024_6_26/sun-rays-new.png',
    SMILEY_POPCORN_LARGE: 'https://image.airtel.tv/grandslam/content/2024_6_26/smiley_popcorn.png',
    SMILEY_POPCORN_NEW: 'https://image.airtel.tv/grandslam/content/2024_6_26/smiley_popcorn_new.png',
    SMILEY_POPCORN_MOBILE: 'https://image.airtel.tv/grandslam/content/2024_6_27/smily-popcorn-minified.png',
    SMILY_POPCORN: 'https://image.airtel.tv/grandslam/content/2024_4_25/smily-popcorn.png',
    FREE_TEXT_IMG: 'https://image.airtel.tv/grandslam/content/2024_6_27/free-text-img-minified.png',
    TILE_PLAY_ICON: '/static/play_ic.svg',
    XPP_ATB_BG_LS: 'https://image.airtel.tv/grandslam/content/2024_4_18/gradient-bg-popup-tv.png',
    XPP_ATB_BG: '/static/xpp-atb-bg.svg',
    CW_REMOVE_IMAGE: 'https://image.airtel.tv/grandslam/content/2023_9_20/ic_ok.png',
    CELEBRATION_IMG: 'https://image.airtel.tv/grandslam/content/2024_6_28/celebration.png',
    XSTREAM_PLAY: '/static/xstream_play.svg',
    REMAINING_OTTS: 'https://image.airtel.tv/grandslam/content/2024_8_23/Group_1000006078.png',
    MOBILE_ICON: '/static/mobile-icon.svg',
    ONEHUB_INFO_ICON: 'https://image.airtel.tv/grandslam/content/2024_10_2/Info.png',
    PAYMENT_SUCCESS_ICON: '/static/payment-success-icon.svg',
    PAYMENT_FAILURE_ICON: '/static/payment-failure-icon.svg',
    // FREE_TAG: './static/free-tag.svg',
    NETFLIX_ICON: 'https://image.airtel.tv/grandslam/content/2024_10_16/NETFLIX.png', //Not to be used
};

const IMAGE_PATHS_LS = {
};

for (const key in IMAGE_PATHS_WEB) {
    IMAGE_PATHS_LS[key] = `.${IMAGE_PATHS_WEB[key]}`;
}

// export const IMAGE_PATHS = DeviceTypeUtil?.isLG() ? IMAGE_PATHS_LS : IMAGE_PATHS_WEB;

export const IMAGE_PATHS = IMAGE_PATHS_WEB;

export const CACHE_CONFIGS = {
    LOW_PRIORITY_CACHE: 'LOW_PRIORITY',
    HIGH_PRIORITY_CACHE: 'HIGH_PRIORITY',
    MEDIUM_PRIORITY_CACHE: 'MEDIUM_PRIORITY',
    CRITICAL_PRIORITY_CACHE: 'CRITICAL_PRIORITY',
    LIFE_TIME_CACHE: 'LIFE_TIME',
};

export const CP_DESCRIPTION_LIMIT = {
    DESKTOP: 400,
    MOBILE: 180,
};

export const ACTION_MODALS_TYPES = {
    HELP_DESK: 'HELP_DESK',
    BUY_SUBSCRIPTION: 'BUY_SUBSCRIPTION',
    MANAGE_DEVICES: 'MANAGE_DEVICES',
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
};

export const INTRODUCTION_MODALS_TYPES = {
    SWITCH_TO_AIRTEL: 'SWITCH_TO_AIRTEL',
};

export const REF_LINKS = {
    ABOUT_US: 'https://wynk.in/corporate/index.html',
    SWITCH_TO_AIRTEL_KNOW_MORE: 'https://airtel.in/',
    PLAY_STORE_LINK: 'https://open.airtelxstream.in/XFYAvSDfcgb',
    ITUNES_LINK: 'https://open.airtelxstream.in/9pnP8MJfcgb',
    WYNK_MUSIC_LINK: 'https://wynk.in/music',
    FEEDBACK_FORM: 'https://www.airtelxstream.in/more/help/contentfeedback',
};

export const QUERY = {
    WEB_SHARE: '?appId=Web',
};

export const QUERY_PARAMS = {
    MSISDN: 'msisdn',
    CHANNEL_PARTNER: 'cp',
    APP_ID: 'appId',
    DEVICE_ID: 'deviceId',
    DEVICE_TYPE: 'deviceType',
    OS: 'os',
    OS_VERSION: 'osVersion',
    APP_VERSION: 'appVersion',
    BUILD_NUMBER: 'buildNo',
    UID: 'uid',
    TOKEN: 'token',
    DIRECT_PLAYBACK_FROM_HOME: 'directPlaybackFromHome',
    PREFERRED_CP: 'preferredCp',
    CONTENT_ID: 'content-id',
    VIEW_TYPE: 'viewType',
    PLAN_ID: 'planId',
};

export const CAPTCHA_LENGTH = 6;

export const SCOPED_WEBVIEW = 'scoped_webview';

export const KEY_CODES = {
    NUM0: 48,
    NUM1: 49,
    NUM2: 50,
    NUM3: 51,
    NUM4: 52,
    NUM5: 53,
    NUM6: 54,
    NUM7: 55,
    NUM8: 56,
    NUM9: 57,
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    PAUSE_BREAK: 19,
    CAPS_LOCK: 20,
    ESCAPE: 27,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    INSERT: 45,
    DELETE: 46,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,
    LEFT_WINDOW_KEY: 91,
    RIGHT_WINDOW_KEY: 92,
    SELECT_KEY: 93,
    NUMPAD_0: 96,
    NUMPAD_1: 97,
    NUMPAD_2: 98,
    NUMPAD_3: 99,
    NUMPAD_4: 100,
    NUMPAD_5: 101,
    NUMPAD_6: 102,
    NUMPAD_7: 103,
    NUMPAD_8: 104,
    NUMPAD_9: 105,
    MULTIPLY: 106,
    ADD: 107,
    SUBTRACT: 109,
    DECIMAL_POINT: 110,
    DIVIDE: 111,
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    NUM_LOCK: 144,
    SCROLL_LOCK: 145,
    SEMI_COLON: 186,
    EQUAL_SIGN: 187,
    COMMA: 188,
    DASH: 189,
    PERIOD: 190,
    FORWARD_SLASH: 191,
    GRAVE_ACCENT: 192,
    OPEN_BRACKET: 219,
    BACK_SLASH: 220,
    CLOSE_BRAKET: 221,
    SINGLE_QUOTE: 222,
    SPACE: 32,
};

export const TITLE_SITE_LOGO = 'site-logo';

export const INVALID_URL = 'invalid url';


export const DEFAULT_STRING = {
    SERVER_FAILED_MESSAGE: 'Oops! Something went wrong.',
    NETWORK_FAILED_MESSAGE: 'Please check your network connection',
    BUTTON_RETRY: 'RETRY',
};

export const SCREEN_ORENTATIONS = {
    LANDSCAPE: 'landscape-primary',
    LANDSCAPE_UPSIDE_DOWN: 'landscape-secondary',
    PORTRAIT: 'portrait-primary',
    PORTRAIT_UPSIDE_DOWN: 'portrait-secondary',
    NATURAL: 'natural',
};

export const APP_CONFIGS = {
    TOASTR_TIMEOUT: 2000,
};

export const TIMER = {
    FADE_IN_EFFECT_DELAY: 200,
    EXPAND_TILE_DELAY: 1000,
    TRAILER_FADE_IN_EFFECT: 200,
};
export const UI_CONFIGS = {
    MIN_WIDTH_ARTIST_DESCRIPTION: 50,
};

export const LIST_TYPE = {
    ARTIST: 'ARTIST CONTENT',
    RELATED_CONTENT: 'RELATED CONTENT',
};

export const SHARABLE_CONTENT = {
    FB_LINK: 'https://www.facebook.com/sharer/sharer.php',
    TWITTER_LINK: 'https://twitter.com/intent/tweet',
    WHATSAPP_LINK: 'whatsapp://send',
    CONTENT_RIGHTS_DEEP_LINK: 'https://open.airtelxstream.in/0AtgV6C8bgb',
    DEEP_LINK: 'https://open.airtelxstream.in/glkUS8Y7bgb',
    DOWNLOAD_APP_DEEP_LINK: 'https://open.airtelxstream.in/rw2mrrp8bgb',
};

export const DOWNLOAD_APP_ACTIONS = {
    COMMON_APP_DOWNLOAD: 'COMMON_APP_DOWNLOAD',
    DOWNLOAD_APP_STORE: 'DOWNLOAD_APP_STORE',
    DOWNLOAD_PLAY_STORE: 'DOWNLOAD_PLAY_STORE',
    APP_HOOK_INSTALL: 'Install',
    CP_UNSUPPORTED_REDIRECT: 'CP_UNSUPPORTED_REDIRECT',
};

export const LIST_TYPE_CLASS = {
    ARTIST: 'cards-portrait--grid-small',
    RELATED_CONTENT: 'cards-portrait--grid-large',
};

export const MAILING_INFORMATIONS = {
    SUPPORT_MAIL_ID: 'support@airtelxstream.in',
};

export const ERROR_PAGE_ACTIONS = {
    RELOAD: 'RELOAD',
    GOTO_HOME: 'GOTO_HOME',
};

export const TAGS = {
    PREMIUM_TAG: 'ATVPLUS',
    NON_PREMIUM_TAG: 'ATV',
};

export const SETTING_PAGES = {
    ACCOUNT: 'ACCOUNT',
};

export const SEARCH_CONFIG = {
    DEFAULT_COUNT: 30,
    DEFAULT_RAIL_ITEMS_COUNT: 8,
    MAX_RECENT_SEARCH_COUNT: 5,
    SUGGESTION_LIMIT: 10,
    SEARCH_TOTAL_RESULT_LIMIT: 30,
};

export const GENRES_CONST = {
    FAVOURITE_CHANNELS: 'Favourites',
};

export const CONTINUE_WATCHING_CONSTANTS = {
    DELAY_TIME: 180,
    MIN_SYNC_TIME: 300000.0,
};

export const DVR_DURATION = {
    MIN_DURATION_SEC: 300.0,
};

export const SORT_TYPES = {
    A2Z: 'A2Z',
    Z2A: 'Z2A',
    DATE: 'DATE',
};

export const SERVER_SORT_TYPES = {
    IMDB: 'IMDB',
    DATE_DESC: 'DATE_DESC',
    REL_YEAR_DESC: 'REL_YEAR_DESC',
};

export const MOBILE_WIDTH = Number(variables.mobileWidth?.slice(
    0,
    variables.mobileWidth.length - 2,
));
export const MOBILE_LANDSCAPE_HEIGHT = Number(variables.mediaLandscapeHeight?.slice(
    0,
    variables.mediaLandscapeHeight.length - 2,
));
export const MOBILE_LANDSCAPE_WIDTH = Number(variables.mediaLandscapeWidth?.slice(
    0,
    variables.mediaLandscapeWidth.length - 2,
));

export const TABLET_LARGE_WIDTH = Number(variables.tabletLargeWidth?.slice(
    0,
    variables.tabletLargeWidth.length - 2,
));

export const MAX_SEARCH_INPUT_CHARS = 30;
export const MAX_PHONE_LENGTH = 10;
export const MAX_EMAIL_LENGTH = 320;

export const VALUE_TYPES = {
    OBJECT: 'object',
    STRING: 'string',
};

export const COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS = 60;

export const DESCRIPTION_MAX_LENGTH = 40;
export const DESCRIPTION_MAX_LENGTH_LS = 10000;

export const CONTINUE_WATCHING_LIMIT = 50;

export const PARTIAL_EPISODE_TILE_LIMIT = 10;

export const APP_TYPE = 'website';

export const SITE_NAME = 'Airtel Xstream (Airtel TV)';

export const APP_ENVIRONMENTS = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    LOCAL: 'local',
    UAT: 'uat',
};

export const REQUEST_METHODS = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
};

export const FOOTER_CONFIG_CATEGORY = {
    LIVE_TV_CHANNELS: 'Live TV Channels',
};

export const INTERSECTION_OBSERVER_THRESHOLD = {
    NOT_LOADED: [
        0,
    ],
    FULLY_LOADED: [
        1,
    ],
    ON_ENTRY_AND_EXIT: [
        0,
        1,
    ],
};

export const PAGE_TYPE = {
    LIST: 'LIST',
};

export const CHAR_LIMIT = {
    DESKTOP_TAGLINE_LIMIT: 76,
    MOBILE_TAGLINE_LIMIT: 42,
};


export const BREADCRUMBS_NAME = {
    HOME: 'Home',
    LIVE_TV: 'Live TV',
    TV_SHOWS: 'TV Shows',
    MOVIES: 'Movies',
    VIDEO: 'Videos',
    ARTISTS: 'Artists',
    LAYOUT: 'Layout',
    DIMENSION_PAGES: 'Dimension Pages',
    SCHEDULE_PAGE: 'Schedule',
    CHANNEL: 'Channel',
};

export const BREADCRUMBS_MAP = {
    HOME: BREADCRUMBS_NAME.HOME,
    LIVE_TV: BREADCRUMBS_NAME.LIVE_TV,
    TV_SHOWS: BREADCRUMBS_NAME.TV_SHOWS,
    [PROGRAM_TYPES.TVSHOW]: BREADCRUMBS_NAME.TV_SHOWS,
    MOVIES: BREADCRUMBS_NAME.MOVIES,
    [PROGRAM_TYPES.LIVETVCHANNEL]: BREADCRUMBS_NAME.LIVE_TV,
    [PROGRAM_TYPES.MOVIE]: BREADCRUMBS_NAME.MOVIES,
    VIDEO: BREADCRUMBS_NAME.VIDEO,
    ARTISTS: BREADCRUMBS_NAME.ARTISTS,
    CHANNEL: BREADCRUMBS_NAME.CHANNEL,
};

export const BREADCRUMBS_LINKS = {
    [BREADCRUMBS_NAME.HOME]: '/',
    [BREADCRUMBS_NAME.TV_SHOWS]: '/tv-shows',
    [BREADCRUMBS_NAME.MOVIES]: '/movies',
    [BREADCRUMBS_NAME.LIVE_TV]: '/livetv-channels',
    [BREADCRUMBS_NAME.VIDEO]: '/video',
    [BREADCRUMBS_NAME.ARTISTS]: '/',
    [BREADCRUMBS_NAME.CHANNEL]: '/channel',
};

export const CHANNEL_SCHEDULE_TIME_GAP = {
    DAILY: 4,
};

export const CHANNEL_SCHEDULE_TILE_SIZE = {
    DESKTOP: 416,
    MOBILE_MEDIUM: 324,
};

export const CHANNEL_PARTNER_PLAYER_WIDGET_TYPE = {
    GIF: 'GIF',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
};

export const RESIZE_OPTION = {
    MOBILE: 'mobile',
    TAB_LARGE: 'tab-large',
};

export const DEFAULT_GENRE = {
    ALL_CHANNEL: 'all_channel',
};

export const SETTINGS_NAVIGATION_MENU_COMPONENT_NAME = {
    ACCOUNT_INFO_COMPONENT: 'AccountInfoComponent',
    MANAGE_PLANS_COMPONENT: 'ManagePlansComponent',
    LANGUAGE_SETTINGS_COMPONENT: 'LanguageSettingsContainer',
    HELP_SUPPORT_COMPONENT: 'HelpAndSupportComponent',
};

export const SETTINGS_LOGIN_LOGOUT_COMPONENT = 'SettingsLoginLogoutScreen';

export const FALLBACK_THEME = {
    brandColor: '#D8272C',
    cardColor: '#343434',
    cardColorRGB: 'rgb(52,52,52)',
    gradientEndColor: '#1F1F1F',
    gradientStartColor: '#343434',
    navIconColor: '#FFFFFF',
    statusBarStyle: 'dark',
    subtitleAlpha: '1',
    textColor: '#FFFFFF',
};

export const MODAL_POPUP = {
    PREFERRED_PARTNER_MODAL: 'PreferredPartnerModal',
    PREFERRED_PARTNER_GRATIFICATION: 'PreferredPartnerGratification',
    PREFERRED_PARTNER_SUBSCRIPTION: 'PreferredPartnerSubscription',
    AGE_CLASSIFICATION_POPUP: 'AgeClassificationPopup',
    XMP_CLAIM_POPUP: 'XmpClaimPopup',
    NON_AIRTEL_USER_POPUP: 'NonAirtelUserPopup',
    XMP_CLAIM_MODAL_POPUP: 'XmpClaimModalPopup',
    TRAILER_POPUP: 'TrailerPopUp',
    MORE_INFO_POPUP: 'MoreInfoPopUp',
    SINGLE_CLAIM_SUCCESS_POPUP: 'SingleClaimSuccessPopUp',
    MULTIPLE_CLAIM_SUCCESS_POPUP: 'MultipleClaimSuccessPopUp',
    CANCEL_AUTO_RENEW_PLAN_POPUP: 'CancelAutoRenewPopup',
    CANNOT_PLAY_VIDEO_POPUP: 'CannotPlayVideoPopup',
    BUY_PLAN_AFTER_TRAILER_POPUP: 'BuyPlanPopupAfterTrailer',
    CONTINUE_WATCHING_OPTION: 'ContinueWatchingOptionModal',
    PLAN_PURCHASE_SUBSCRIPTION_POPUP: 'PlanPurchaseSubscriptionPopup',
    PLAN_PURCHASE_INPROGRESS_POPUP: 'InProgressSubscriptionPopup',
    PLAN_PURCHASE_PAYMENT_UPDATE_POPUP: 'PlanPurchaseNotificationPopup',
    AIRTEL_CONNECTION_POPUP: 'AirtelConnectionPopup',
    OFFERS_CHECK_MODAL: 'OffersCheckModal',
    FMF_MODAL: 'FirstMonthFreeModal',
    ADD_TO_BILL_POPUP: 'AddToBillPopup',
    PILLS: 'PILLS',
    XPP_CLAIM_MODAL: 'xppClaimOnThanks',
    XPP_SINGLE_CLAIM_MODAL: 'xppSingleClickClaim',
    RECHARGE_PLAN_DETAIL: 'RechargePlanDetail',
    DEVICE_LIMIT_REACHED: 'DeviceLimitReached',
    CONCURRENT_PLAY_LIMIT_REACHED: 'ConcurrentPlayLimitReached',
    XPP_PURCHASE_POP_UP_ID: 'FMFSIPopUp',
    XPP_FREE_TRIAL_POP_UP_ID: 'FMFSIPopUp',
    SUBSCRIPTION_STATUS_POPUP: 'SUBSCRIPTION_STATUS_POPUP',
    WAITING_POPUP: 'WAITING_POPUP',
    RECHARGES_XPP_INFO_POPUP: 'RECHARGES_XPP_INFO_POPUP',
    STATUS_POPUP: 'STATUS_POPUP',
    COMMON_MODAL: 'COMMON_MODAL',
    ABOUT_CONTENT_POPUP: 'AboutContentPopup',
    PLAN_DETAIL_POPUP: 'PlanDetailPopup',
    ONEHUB_SUBSCRIPTION_STATUS_POPUP: 'ONEHUB_SUBSCRIPTION_STATUS_POPUP',
    DOWNLOAD_XSTREAM_APP_POPUP: 'DownloadXstreamAppPopup',
    ACTIVATION_PENDING_POPUP: 'ActivationPendingPopup',
};

export const GTAG_SIGN_IN_ID = 'AW-934825417/GtUQCPPatpUDEMmb4b0D';

const branchKey = 'key_live_kc5hbJYeDTvc99K81cvQrfbkzrbvQtEW';
const encodedBranchKey = () => {
    const binaryBuffer = Buffer.from(branchKey, 'utf8');
    const encryptedBranchKey = binaryBuffer.toString('base64');
    return encryptedBranchKey;
};

export const GTAG_SUBSCRIBE_NOW_BEFORE_LOGIN = 'AW-934825417/sbmjCN3op5oDEMmb4b0D';

export const GTAG_SUBSCRIBE_NOW_ID = 'AW-934825417/onSsCK7y4ZoDEMmb4b0D';

export const GTAG_GET_APP_ID = 'AW-934825417/GfuVCOvLvZUDEMmb4b0D';

export const TIZEN_KEYCODES = {
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    OK_BUTTON: 13,
    RETURN_BUTTON: 10009,
    MEDIA_STOP_BUTTON: 413,
    MEDIA_PLAY_BUTTON: 415,
    MEDIA_PAUSE_BUTTON: 19,
    MEDIA_REWIND_BUTTON: 412,
    MEDIA_FORWARD_BUTTON: 417,
    EXIT: 10182,
};
export const WEBOS_KEYCODES = {
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    OK_BUTTON: 13,
    RETURN_BUTTON: 461,
    CURSOR_SHOW: 1536,
    CURSOR_HIDE: 1537,
};

export const PUBLISH_EVENTS = {
    LEFT_ARROW: 'LEFT_ARROW',
    UP_ARROW: 'UP_ARROW',
    RIGHT_ARROW: 'RIGHT_ARROW',
    DOWN_ARROW: 'DOWN_ARROW',
    OK_BUTTON: 'OK_BUTTON',
    RETURN_BUTTON: 'RETURN_BUTTON',
    MEDIA_STOP_BUTTON: 'MEDIA_STOP_BUTTON',
    MEDIA_PLAY_BUTTON: 'MEDIA_PLAY_BUTTON',
    MEDIA_PAUSE_BUTTON: 'MEDIA_PAUSE_BUTTON',
    MEDIA_REWIND_BUTTON: 'MEDIA_REWIND_BUTTON',
    MEDIA_FORWARD_BUTTON: 'MEDIA_FORWARD_BUTTON',
    SKIP_AD: 'SKIP_AD',
    TOGGLE_PLAYBACK: 'TOGGLE_PLAYBACK',
    CURSOR_SHOW: 'CURSOR_SHOW',
    CURSOR_HIDE: 'CURSOR_HIDE',
};

export const LOCALOVERRIDEPOLLINGLISTS = {
    QrCodePolling: 30000,
    regularPolling: 600000,
    playbackPagePolling: -1,
};

export const EMAIL_REGEX = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
);

export const WEB_HEADER_HEIGHT = 102;

export const DEFAULT_TITLE = 'Airtel Xstream Play - Airtel TV Online, Live TV Shows, Channels, Watch Movies, Videos Online ';
export const DEFAULT_DESCRIPTION = 'Stream Movies, TV Shows, Sports & More Online on OTT Website. Discover Multiple Channels like Sony Liv, Lionsgate, Eros Now & More. Happy Streaming only on Airtel Xstream Play!';

export const AD_TYPE = {
    SERVER_SIDE_AD: 'SERVER_SIDE_AD',
    CLIENT_SIDE_AD: 'CLIENT_SIDE_AD',
};


export const OTP_TIMER = 30;

export const SMS_TIMER = 60;

export const THIRY_SEC_TIMER = 30000;

export const ACTIVE_TILE_AFTER_TIMEOUT = 500;

export const AUTO_VOLUME_AFTER_TIMEOUT = 1000;

export const DEBOUNCED_TIMEOUT = 500;
export const THEME_COLORS = {
    application: {
        background: '#191919',
        tint: '#FFD566',
        primaryLabel: '#eeeeee',
        secondaryLabel: '#8c8d8c',
        title: '#FFFFFF',
        error: '#F40000',
        link: '#1354AF',
    },
    login: {
        background: '#ffffff',
        headingText: '#333333',
        textfieldIcon: '#EEEEEE46',
        textfieldText: '#EEEEEE',
        textfieldPrefix: '#FFFFFF1E',
        textfieldPlaceholder: '#FFFFFF',
        textfieldBackground: '#666666',
        textfieldFooter: '#FFFFFF',
        bottomText: '#999999',
        disableButtonBackground: '#EEEEEE',
        buttonText: '#000000',
        activeButtonBackground: '#FFD566',
        linkText: '#1354AF',
        errorText: '#F40000',
    },
    languageSelection: {
        background: '#191919',
        title: '#cccccc',
        subtitle: '#666666',
        langTitle: '#eeeeee',
        primaryButtonTitle: '#202020',
        primaryButtonBackground: '#ffd566',
        itemBorder: '#FFFFFF',
        itemTitle: '#FFFFFF',
    },
    settings: {
        background: '#191919',
        title: '#FFFFFF',
        tabBackground: '#333333',
        tabActiveColor: '#eeeeee',
        tabNormal: '#999999',
        dismissButton: '#FFFFFF',
        accordionNormal: '#999999',
        accordianActive: '#eeeeee',
    },
    homepage: {
        layout: {
            headerBackground: '#212121',
            gradientBlack: '#000000',
            headerBoxShadow: 'rgba(0, 0, 0, 0.5)',
            background: '#191919',
            navItemActiveBorder: '#ffd566',
            navItemActiveColor: '#ffffff',
            navItemNormalColor: '#8c8d8c',
            dropdownNavBackground: 'rgba(33, 33, 33, 0.8)',
            dropdownNavShadow: 'rgba(0, 0, 0, 0.25)',
            carouselNormal: 'grey',
            carouselHighlighter: '#ffd566',
            footerBackground: '#222222',
            footerTitle: '#eee',
            footerLinks: '#999999',
            footerSettingsLink: '#9e9e9e',
            breadCrumb: '#999999',
            profileCardBackground: '#222222',
            profileItem: '#999999',
        },
    },
    search: {
        clearAll: '#FFD566',
        recentSearchTagBackground: '#414042',
        recentSearchTagColor: '#eeeeee',
        loader: 'FFD566',
        placeholder: '#757575',
        bottomBorder: '#939598',
        recentSearchtext: '#eeeeee',
    },
    rail: {
        title: '#eeeeee',
        overlayTag: '#cccccc',
        more: '#ffd566',
    },
    liveTV: {
        title: '#bbbbbb',
        background: '#191919',
        languageBox: '#8c8d8c',
        channeltitle: '#ffffff',
        showTitle: '#aaaaaa',
        highlighter: '#e32e2d',
        showBackground: '#262626',
        filterSection: {
            primaryLabel: '#aaaaaa',
            selectedFilterTitle: '#FFD566',
            activeTab: '#404040',
        },
        languageSelection: {
            title: '#cccccc',
            done: '#191919',
            doneBackground: '#ffd566',
        },
    },
    detailPage: {
        background: '#222222',
        title: '#FFFFFF',
        year: '#9e9e9e',
        links: '#FFd566',
        descriptionHeading: '#9e9e9e',
        description: '#ffffff',
        subtitle: '#FFFFFF',
        cardBG: '#333333',
        navigationTitle: '#EEEEEE',
        moreTitle: '#FFD566',
        staringTitle: 'FFD566',
        trailerTitle: '#FFFFFF',
        shareText: '#cccccc',
        watchListText: '#cccccc',
        castName: '#999999',
        separator: '000000',
        progressBackground: '00000000',
        videoDetailBackground: '333333',
        comingUpShowsPopupBackground: '191919',
        actorDetailPage: {
            background: '#222222',
            titleBackground: '#2a2a2a',
            title: '#ffffff',
            birthPlace: '#999999',
            moreButton: '#ffd566',
            watchBackground: '#222222',
        },
    },
    errorPage: {
        description: '#FFFFFF',
        errorTitle: '#707070',
        primaryButtonBackground: '#33333300',
        primaryButtonBorder: '#FFD566',
        primaryButtonTitle: '#FFFFFF',
    },
};

export const CDP_EVENT_CONST = {
    TRAILER_CLICK: 'trailer_click',
    WATCH_CLICK: 'watch_click',
    WATCHLIST_CLICK: 'watchlist_click',
    TRAILER_VIEW: 'trailer_view',
    MUTE_CLICK: 'mute_click',
    SHARE_CLICK: 'share_click',
    EPISODE_TAB_CLICK: 'episodes_tab_click',
    SEASON_CLICK: 'seasons_click',
    EPISODE_CLICK: 'episode_click',
    TILE_CLICK: 'tile_click',
    PLAY_CLICK: 'resume_click',
    PAUSE_CLICK: 'pause_click',
    NEXT_EP_CLICK: 'nextepisode_click',
    SUBTITLE_CLICK: 'subtitle_click',
    STARNAME_CLICK: 'starname_click',
    TRAILER_END: 'trailer_end',
    TRAILER_MUTE: 'trailer_mute',
    TRAILER_UNMUTE: 'trailer_unmute',
    TRAILER_RELOAD: 'trailer_reload',
};

export const ARIA_LABEL = {
    SCROLL_RAIL_LEFT: 'Scroll Rail Left',
    SCROLL_RAIL_RIGHT: 'Scroll Rail right',
    GO_TO_PREV_PAGE: 'Go to previous Page',
};

export const REQUEST_URL = 'REQUEST_URL';
export const urlToSourceNameMapping = {
    offers: 'offers_page',
    subscriptions: 'onehub',
};

export const TEMPLATE_TO_SOURCE_MAPPING = {
    renewalATB: 'ADD_TO_BILL_PAGE',
};

export const MATCH_TIMER = 60 * 60 * 1000;

export const APP_REDIRECT_CPS = [
    'ULTRA',
    'NAMMAFLIX',
    'RAJTV',
    'EROSNOW',
    'MANORAMAMAX',
    'SONYLIV_VOD',
    'ALTBALAJI',
    'DOLLYWOOD',
];
export const ANALYTICS_CATEGORY = { // check values once with app
    PLAYBACK: 'playback',
    PLAYBACK_TRAILER: 'playback_trailer',
    STREAMING: 'streaming',
    CERTIFICATE: 'certificate',
    LICENSE: 'license',
    MANIFEST: 'manifest',
    REFRESH_TOKEN: 'refresh_token_api', // taken from diagram, check on app
    PACKAGE_LAYOUT: 'PACKAGE_LAYOUT',
    APP_CONFIG: 'APP_CONFIG',
    CONTENT_DETAIL: 'CONTENT_DETAIL',
    CONTENT_SYNC: 'CONTENT_SYNC',
    USER_CONFIG: 'USER_CONFIG',
    USER_LOGIN: 'USER_LOGIN',
    ALL_PLANS: 'ALL_PLANS',
    MY_PLANS: 'MY_PLANS',
    SEARCH: 'SEARCH',
};

export const SIDE_BAR_CONSTS = {
    SUBSCRIPTION_PAGE: 'SUBSCRIPTION_PAGE',
    SETTINGS_PAGE: 'SETTINGS_PAGE',
    SEARCH_PAGE: 'SEARCH_PAGE',
};


export const HLS_DAI_PLATFORMS = [
    'SONYLIV_LIVE',
    'SONYLIV_VOD',
];

export const CONTINUE_WATCHING_TIMER = 800;

export const CUSTOMER_TYPE = {
    NON_AIRTEL: 'NON_AIRTEL',
    PREPAID_CUST: 'PREPAID',
    POSTPAID_CUST: 'POSTPAID',
    BROADBAND: 'BROADBAND',
};

export const LAYOUT_TABS_ARRAY = [
    [
        'tv-shows',
        'TVSHOWTAB',
    ],
    [
        'home',
        'HOME',
    ],
    [
        'movies',
        'MOVIETAB',
    ],
    [
        'channel',
        'CHANNELTAB',
    ],
    [
        'news',
        'NEWSTAB',
    ],
    [
        'sports',
        'SPORTSTAB',
    ],
    [
        'livetv-channels',
        'LIVETVCHANNELTAB',
    ],
];

export const APPLICATION_DOMAINS = {
    PREPROD: 'preprod.airtelxstream.in',
    UAT: 'uat.airtelxstream.in',
    PROD: 'www.airtelxstream.in',
    PROD_2: 'airtelxstream.in',
};
export const DEVICE_TYPES = {
    LAPTOP: 'laptop',
    PHONE: 'phone',
    TABLET: 'TABLET',
    TV: 'tv',
    STB: 'stb',
    STICK: 'stick',
    BROWSER: 'browser',
};

export const OS_TYPES = {
    LGOS: 'lgos',
    ANDROID: 'android',
    IOS: 'ios',
    WEBOS: 'webos',
    MWEBOS: 'mwebos',
    TIZENOS: 'tizenos',
    TVOS: 'tvos',
};

export const AIRTEL_USER_TYPES = {
    XSTREAMPREMIUM_TELCO_PAID: 'xstreampremium_telco_paid',
    XSTREAMPREMIUM_PAID: 'xstreampremium_paid',
};

export const REF_CONSTS = {
    TIMEOUT_REF_PLAYBACK: 'timerRefPlayback',
    TIMEOUT_REF_CDP_HIDE_DETAILS: 'timerRefHideDetails',
};


export const BILL_STATUS = {
    SUCCESS: 'SUCCESS',
    INPROGRESS: 'INPROGRESS',
    FAILURE: 'FAILURE',
    WAITING: 'WAITING',
    FAILED: 'FAILED',
    PENDING: 'PENDING',
};

export const WARNING_TYPE = {
    DESTRUCTIVE: 'destrutive',
    DESCRUTIVE: 'descrutive',
    WARNING: 'warning',
};

export const LIVE_CHANNEL_FILTER_IMAGE_PATHS = {
    MOVIES: '/static/live-channel-filter/movies.svg',
    LANGUAGE: '/static/live-channel-filter/language.svg',
    ADD_LANGUAGE: '/static/live-channel-filter/add.svg',
    LEFT_ARROW: '/static/live-channel-filter/left-arrow.svg',
    RIGTH_ARROW: '/static/live-channel-filter/rigth-arrow.svg',
    CHANNEL_NDTV: '/static/live-channel-filter/ndtv-logo.png',
    CANCEL_ARROW: '/static/live-channel-filter/cancel-arrow.svg',
    CHECK_ARROW: '/static/live-channel-filter/black-check-arrow.svg',
    POPUP_CROSS: '/static/modalpopup_asset/modal-cross.svg',
    DOWN_ARROW: '/static/live-channel-filter/down-arrow.svg',
};

export const PAYMENT_MODES = {
    AIRTEL_PARTNER: 'AIRTEL_PARTNER',
};

export const oneHubPlanTypes = {
    SUBSCRIPTION_STATUS: 'subscriptionStatus',
    ACTIVE_PLANS: 'activePlans',
    D2C_PLANS: 'd2cPlans',
    RECHARGE_PLANS: 'rechargePlans',
    RECOMMENDED_PLANS: 'recommendedPlans',
    UPGRADE_PLANS: 'upgradePlans',
};

export const ONEHUB_CTA_TYPES = {
    RENEW_TILE_MORE_PLANS: 'RENEW_TILE_MORE_PLANS',
    ACTIVE_PLAN_VIEW_DETAILS: 'ACTIVE_PLAN_VIEW_DETAILS',
    PLAN_PURCHASE: 'PLAN_PURCHASE',
    PARTNER_ACTIVATION: 'PARTNER_ACTIVATION',
    SEE_PLAN_DETAILS: 'SEE_PLAN_DETAILS',
    SHOW_MORE_PLANS: 'SHOW_MORE_PLANS',
    RECHARGE_NOTIFICATION: 'RECHARGE_NOTIFICATION',
    BROWSE_CONTENT: 'BROWSE_CONTENT',
    WAIT_TILL_CLAIM_SUCCEEDS: 'WAIT_TILL_CLAIM_SUCCEEDS',
    MORE_DETAILS: 'MORE_DETAILS',
    REDIRECT_TO_ONEHUB: 'REDIRECT_TO_ONEHUB',
};

export const ONEHUB_PLAN_STATUS = {
    PENDING_ACTIVATION: 'PENDING_ACTIVATION',
    CLAIM_IN_PROGRESS: 'CLAIM_IN_PROGRESS',
    CLAIMED: 'CLAIMED',
};

export const CP_CLAIM_STATUS = {
    claimed: 'CLAIMED',
    pending_activation: 'PENDING_ACTIVATION',
    inprogress: 'INPROGRESS',
};

export const ONEHUB_PURCHASE_STATUS = {
    RECHARGE_SUCCESSFUL: 'recharge_successful',
    RECHARGE_FAILURE: 'recharge_failure',
    RECHARGE_PENDING: 'rechharge_pending',
};

export const CDP_CTA_TYPES = {
    PLAY_CTA: 'PLAY',
    ACTIVATE_CTA: 'ACTIVATE',
    LOGIN_CTA: 'LOGIN',
    WEBVIEW_CTA_TYPE: 'WEBVIEW',
    PLAY_FROM_BEGINNING: 'PLAY_FROM_BEGINNING',
    POPUP: 'POPUP',
    PURCHASE: 'PURCHASE',
};

export default {
    WEB_HEADER_HEIGHT,
    ELEMENT_ID,
    REQUEST_METHODS,
    APP_ENVIRONMENTS,
    APP_TYPE,
    SITE_NAME,
    CONTINUE_WATCHING_LIMIT,
    PARTIAL_EPISODE_TILE_LIMIT,
    COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS,
    DOWNLOAD_APP_ACTIONS,
    LANGUAGES,
    LOCAL_STORE_KEYS,
    ROTE_TYPES: ROLE_TYPES,
    LANGUAGES_CODES,
    ACTION_MODALS_TYPES,
    INTRODUCTION_MODALS_TYPES,
    REF_LINKS,
    QUERY_PARAMS,
    KEY_CODES,
    LOCALOVERRIDEPOLLINGLISTS,
    DEFAULT_STRING,
    CACHE_CONFIGS,
    SCREEN_ORENTATIONS,
    APP_CONFIGS,
    UI_CONFIGS,
    PROGRAM_TYPES,
    LIST_TYPE,
    LIST_TYPE_CLASS,
    MAILING_INFORMATIONS,
    ERROR_PAGE_ACTIONS,
    TAGS,
    SETTING_PAGES,
    SEARCH_CONFIG,
    SORT_TYPES,
    SERVER_SORT_TYPES,
    MOBILE_WIDTH,
    MAX_SEARCH_INPUT_CHARS,
    MAX_PHONE_LENGTH,
    VALUE_TYPES,
    PROGRAM_TYPE_TITLE_MAPPING,
    PROGRAM_TYPES_CATEGORY,
    PROGRAM_TYPE_CATGEGORY_MAP,
    FOOTER_CONFIG_CATEGORY,
    DIMENSION_PAGE_MAP,
    DIMENSION_PAGE_CONTENT_LIMIT,
    DIMENSION_SORT_OPTIONS,
    DIMENSION_SORT_DISPLAY,
    DIMENSION_SORT_API_OPTION,
    DROP_DOWN_LIMIT,
    TITLE_SITE_LOGO,
    INVALID_URL,
    PAGE_TYPE,
    DROPDOWN_NAMES_LIMIT,
    SCREEN_SIZES,
    DROPDOWN_CONTENT_NAME_LIMIT,
    ICON_POSITION,
    TAB_POSITION,
    BREADCRUMBS_NAME,
    BREADCRUMBS_MAP,
    BREADCRUMBS_LINKS,
    DIMENSIONS_FOR_CALL_MAP,
    CHANNEL_SCHEDULE_TIME_GAP,
    CHANNEL_SCHEDULE_TILE_SIZE,
    CHANNEL_PARTNER_PLAYER_WIDGET_TYPE,
    RESIZE_OPTION,
    CP_DESCRIPTION_LIMIT,
    DIMENSION_URL_TO_TYPE,
    FALLBACK_THEME,
    CHAR_LIMIT,
    MAX_LANGUAGE_SELECTION_LIMIT,
    LANGUAGE_CONTAINER_TYPE,
    SETTINGS_NAVIGATION_MENU_COMPONENT_NAME,
    SETTINGS_LOGIN_LOGOUT_COMPONENT,
    encodedBranchKey,
    GTAG_SIGN_IN_ID,
    GTAG_GET_APP_ID,
    CAPTCHA_LENGTH,
    EMAIL_REGEX,
    DESCRIPTION_MAX_LENGTH,
    DESCRIPTION_MAX_LENGTH_LS,
    IMAGE_PATHS,
    AD_TYPE,
    DEVICE_TYPES,
    OS_TYPES,
    REF_CONSTS,
    IMAGE_PATHS_LS,
    WARNING_TYPE,
    GENRES_CONST,
};

export const DEEPLINK_INGRESS_KEYS = {
    BOTTOM_NUDGE: 'bottomNudge',
    SOFT_POPUP: 'softPopup',
    HARD_POPUP: 'hardPopup',
    IOS_POPUP: 'iosPopup',
    PLAYER_NUDGE: 'playerNudge',
};

export const SCOPED_WEBVIEW_INGRESS_APP = {
    THANKS: 'thanks',
    WYNK: 'wynk',
};


export const ONEHUB_INGRESS_INTENT = {
    CONTENT: 'content',
    CP: 'cp',
    RENEW: 'renew',
    ACTIVATE: 'activate',
    SUCCESS: 'success',
};
// Below are lists of event that are not required by xstream analytics
export const IGNORE_EVENTS = [
    'AD_PROGRESS',
    'VOLUME_MUTED',
    'VOLUME_CHANGE',
    'AD_PAUSE',
    'AD_RESUME',
    'CONTENT_PAUSE',
    'CONTENT_RESUME',
    'AD_SKIPPED_STATE_CHANGE',
    'AD_RESIZE',
];

export const PARTNER_AD_SLOT = "PARTNER_AD_SLOT";
