import {
    takeEvery, getContext, call, put, select, delay,
} from 'redux-saga/effects';
import { activateOneHubPartnerService, getSvodAllPlans } from '@airtel-tv/services/PlansAndSubscriptionApiService';
import { ErrorHandler } from '@airtel-feature/playback/factories/PlaybackUiComponentFactory';
import {
    getUserConfigFromReducer,
} from '@airtel-tv/redux/StoreListing';
import { AIRTEL_USER_TYPES, ONEHUB_PLAN_STATUS } from '@airtel-tv/constants';
import PlansAndSubscriptionAction, {
    SvodAllPlansSuccessAction,
    SvodAllPlansErrorAction,
    SetPartnerActivationData,
} from './PlansAndSubscriptionAction';


function* svodAllPlansFetch(action = {}) {
    const { preferredCp, dataId = 'all_plans', isMobility = false } = action?.payload || {};
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const { userContentProperties: { oSeg = '' } = {} } = yield select(getUserConfigFromReducer) || {};
        const isSubscribed = oSeg.includes(AIRTEL_USER_TYPES.XSTREAMPREMIUM_PAID);
        const svodAllPlansData = yield call(getSvodAllPlans, {
            deviceUtil,
            preferredCp,
            isSubscribed,
            isMobility,
            // requiredSubscriptions,
        });
        //const TRIMMED_PLANS_LENGTH = 2;
        //const { recommendedPlans, d2cPlans, rechargePlans } = svodAllPlansData;
        // let trimmed = [];
        // //let rechargePlansTrimmed = [];
        // let trimmedKeyName = '';
        // if (recommendedPlans && recommendedPlans.length) {
        //     trimmedKeyName = 'recommended';
        //     trimmed = recommendedPlans.slice(0, TRIMMED_PLANS_LENGTH);
        // }
        // else if (d2cPlans && d2cPlans.length) {
        //     trimmedKeyName = 'd2cPlans';
        //     trimmed = d2cPlans.slice(0, TRIMMED_PLANS_LENGTH);
        // }
        // else if (rechargePlans && rechargePlans.length) {
        //     trimmedKeyName = 'rechargePlans';
        //     trimmed = rechargePlans.slice(0, TRIMMED_PLANS_LENGTH);
        // }
        const { premiumSubscriptionStatus = [], xppSubscriptionStatus = [] } = svodAllPlansData || {};
        const planClaimStatusClient = {
            PENDING_ACTIVATION: [],
            CLAIMED: [],
            CLAIM_IN_PROGRESS: [],
        };
        if (premiumSubscriptionStatus) {
            premiumSubscriptionStatus.map(item => {
                if (item.status === ONEHUB_PLAN_STATUS.CLAIMED) {
                    planClaimStatusClient.CLAIMED.push(item);
                }
                else if(item.status === ONEHUB_PLAN_STATUS.PENDING_ACTIVATION) {
                    planClaimStatusClient.PENDING_ACTIVATION.push(item);
                }
                else if (item.status === ONEHUB_PLAN_STATUS.CLAIM_IN_PROGRESS) {
                    planClaimStatusClient.CLAIM_IN_PROGRESS.push(item);
                }
                return item;
            });
        };
        if(xppSubscriptionStatus?.length) {
            xppSubscriptionStatus.map(item => {
                if (item.status === ONEHUB_PLAN_STATUS.CLAIMED) {
                    planClaimStatusClient.CLAIMED.push(item);
                }
                else if(item.status === ONEHUB_PLAN_STATUS.PENDING_ACTIVATION) {
                    planClaimStatusClient.PENDING_ACTIVATION.push(item);
                }
                else if (item.status === ONEHUB_PLAN_STATUS.CLAIM_IN_PROGRESS) {
                    planClaimStatusClient.CLAIM_IN_PROGRESS.push(item);
                }
                return item;
            });
        }
        const svodPlans = {
            [dataId]: {
                ...svodAllPlansData,
                planClaimStatusClient,
                // recommendedPlansTrimmed,
                // d2cPlansTrimmed,
                // rechargePlansTrimmed,
            },
        };
        yield put(SvodAllPlansSuccessAction(svodPlans));
    }
    catch (error) {
        console.error('error', error);
        const ErrorData = {
            error: error && error.data ? error.data : error,
        };
        if (ErrorData.error) {
            ErrorHandler({
                error: ErrorData.error,
            });
        }
        yield put(SvodAllPlansErrorAction(ErrorData));
    }
}

function* activateOneHubPartner(action = {}) {
    const deviceUtil = yield getContext('deviceUtil');
    const { serviceId, referenceId } = action.payload;
    try {
        const activationData = yield call(activateOneHubPartnerService, {
            deviceUtil,
            data: {
                serviceId,
                referenceId,
            },
        });

        yield put(SetPartnerActivationData(activationData));
    }
    catch (err) {
        yield put(SetPartnerActivationData({
            serviceId,
            referenceId,
            status: 'FAILURE',
            errortitle: err?.data?.errortitle,
            errorCode: err?.data?.errorCode,
        }));
        console.log(err);
    }
}

export default [
    takeEvery(PlansAndSubscriptionAction.SVOD_ALL_PLANS, svodAllPlansFetch),
    takeEvery(PlansAndSubscriptionAction.ACTIVATE_ONEHUB_PARTNER, activateOneHubPartner),
];
