export const EVENT_SOURCE_NAME = {
    CONTENT_DETAIL_PAGE: 'content_detail_page',
    DETAIL_PAGE: 'detail_page',
    LISTING_PAGE: 'listing_page',
    LANGUAGE_PAGE: 'language_page',
    SEARCH_PAGE: 'search_page',
    LAYOUT_PAGE: 'layout_page',
    MORE_PAGE: 'more_page',
    ROOT: 'root',
    NOT_FOUND: '404',
    PEOPLE_PAGE: 'people_page',
    SEARCH_RESULT: 'search_result',
    UID: 'uid',
    MSISDN_DETECTED: 'msisdnDetected',
    MSISDN: 'msisdn',
    DTH_RECHARGE: 'dth',
    SCHEDULE_PAGE: 'schedule_page',
    PROMOTION_PAGE: 'PROMOTION_PAGE',
    HAPPYCODE_PAGE: 'happycode_page',
    PREFERRED_PARTNER: 'preferred_pc_page',
    CONTENT_DETAIL_PAGE_V2: 'content_detail_page_v2',
    DEVICE_LIMIT_LOGOUT_POPUP: 'device_limit_logout_popup',
    DEVICE_LIMIT_POPUP: 'device_limit_popup',
    CONCURRENY_PLAY_LIMIT_POPUP: 'concurrent_play_limit_poppup',
    SPLASH_SCREEN: 'splash_screen',
    LOGIN_PAGE: 'login_page',
    OTP_SCREEN: 'otp_screen',
    TV_CODE_SCREEN: 'tv_code_screen',
    TV_LOGIN_SUCCESSFULL: 'tv_login_successfull',
    MSISDN_INPUT_SCREEN: 'msisdn_input_screen',
    DISTRO_EPG: 'distro_cdp',
    CHANNEL_GUIDE: 'channel_guide',
    DISTRO_CHANNEL_GUIDE: 'distro_channel_guide',
};

export const LOGIN_SOURCE = {
    WATCHLIST_BUTTON: 'watchlist_button',
    WATCHLIST_PAGE: 'watchlist_page',
    PLAY_CLICK: 'play_button',
    SIGN_IN_BUTTON: 'sign_in_button',
    DTH_LOGIN: 'dth_login',
    SUBSCRIBE_BUTTON: 'subscribe_button',
    PREFERRED_PARTNER_PAGE: 'preferred_partner_page',
    SUBSCTIPTION_DEEPLINK: 'subscription_deeplink',
    TRAILER_XSTREAM_SUBSCRIPTION: 'trailer_xstream_subscription',
    MANAGE_PLANS_SETTINGS_PAGE: 'manage_plans_settings_page',
    OFFERS_VERIFY_CLICK: 'offers_verify_click',
    PLANS_AND_OFFER_PAGE: 'plans_and_offer_page',
    LOGIN_TO_WATCH: 'login to watch',
    QR_CODE_LOGIN: 'qr_code_login',
    FULLSCREEN_PLAYBACK: 'fullscreen_playback',
};

export const SUBSCRIPTION_SOURCE = {
    PLAYER: 'player',
    TILE: 'tile',
    CHANNEL_PARTNER_PAGE: 'channel_partner_page',
    CHANNEL_PARTNER_PAG_LS: 'channel_detail_page',
    EXPLORE_PLANS_PAGE: 'explore_plans_page',
    CONTENT_DETAIL_PAGE: 'content_detail_page',
    SINGLE_CLAIM_MODAL_SCREEN: 'single_claim_modal_screen',
    HOME_PAGE: 'home_page',
    CONTENT_DETAIL_PAGE_XMP_MODAL: 'content_detail_page_xmp_modal',
    PLANS_AND_OFFER_PAGE: 'plans_and_offer_page',
    PLAN_DETAIL_PAGE: 'plan_detail_page',
    PREFERRED_PARTNER_CHANNEL_SELECTION_PAGE: 'preffered_partner_channel_selection_page',
    TRAILER_PLAY: 'trailer_play',
    CONTENT_DETAIL_PAGE_V2: 'content_detail_page_v2',
    FMF_HARD_BLOCK_PAGE: 'fmf_hardblock_page',
    FMF_MY_PLANS_BANNER: 'fmf_myplans_banner',
    ADD_TO_BILL_ADDRESSAL: 'add_to_bill_addressal',
};

export const SOS_SOURCE_NAME = {
    ROOT: 'root',
    HOME: 'home',
    CHANNELS: 'channels',
    CONTENT_DETAIL_PAGE_V2: 'content_detail_page_v2',
    SEARCH_PAGE: 'search_page',
    YOU: 'you',
    MORE_PAGE: 'more',
    DTH_SUBSCRIBE: 'direct_subscription',
    DETAIL_PAGE: 'detail_page',
    LISTING_PAGE: 'grid_page',
    LANGUAGE_PAGE: 'language_page',
    LAYOUT_PAGE: 'layout_page',
    NOT_FOUND: 'not_found',
    PEOPLE_PAGE: 'people_page',
    SEARCH_RESULT: 'more_search_result',
    UID: 'uid',
    MSISDN_DETECTED: 'msisdnDetected',
    MSISDN: 'msisdn',
    DTH_RECHARGE: 'dth',
    SCHEDULE_PAGE: 'schedule_page',
    PROMOTION_PAGE: 'PROMOTION_PAGE',
    HAPPYCODE_PAGE: 'happycode_page',
    PREFERRED_PARTNER: 'preferred_pc_page',
    TV_SHOWS: 'tv-shows',
    MOVIES: 'movies',
    STRESS_TEST: 'stress_test',
    BLOGS: 'blogs',
    BLOG_DESCRIPTION: 'blog_description',
    OFFERS: 'offers',
    CONTENT_LAYOUT_PAGE: 'content_layout_page',
    WATCHLIST_PAGE: 'watchlist_page',
    LIVE_TV_EPISODE_PLAYBACK: 'live_tv_episode_playback',
    TV_EPISODE_PLAYBACK: 'tv_episode_playback',
    TV_SEASON_PLAYBACK: 'tv_season_playback',
    LIVETV_PLAYBACK: 'live_tv_playback',
    PLAYBACK: 'videos',
    LISTING_V2: 'listing_v2',
    DIMENSION_GENRE_LANG_PAGE: 'dimension_genre_lang_page',
    DIMENSION_GENRE_OR_LANG_PAGE: 'dimension_genre_or_lang_page',
    DIMENSION_PROGRAMTYPE_PAGE: 'dimension_programtype_page',
    DIMENSION_PAGE: 'dimension_page',
    GRID_PAGE: 'grid_page',
    RECHARGES_PAGE: 'recharge_toggle_page',
    QR_CODE_PAGE: 'value_prop_page',
    TV_CODE_LOGIN: 'tvlogin',
    DIRECT_PLAYBACK_CDP: 'direct_playback_cdp',
    PLAN_YOUR_WEEKEND: 'plan_your_weekend',
    ONEHUB: 'onehub',
};
export default {
    EVENT_SOURCE_NAME,
    SUBSCRIPTION_SOURCE,
};
