import { LocationUtil } from './LocationUtil';
import { IMAGE_TYPE } from '../constants/BrowserConst';
import { AppEnv } from './GetEnv';

export class ThumborUtil {
    static getThumborUrl = (width, height, url, imageFormat, quality) => {
        const {
            endpoints: {
                thumbor: {
                    base,
                    paths: {
                        unsafe,
                    },
                    filter: {
                        format,
                    },
                },
            },
            env,
        } = AppEnv.getEnv();

        if ([
            IMAGE_TYPE.SVG,
            IMAGE_TYPE.GIF,
            IMAGE_TYPE.JSON,
        ].includes(LocationUtil.getUrlExtension(url).toLowerCase())) {
            return url;
        }

        return `${base}${unsafe}${width}x${height}/${format}(${imageFormat})${quality ? `:quality(${quality})` : ''}/${url}?o=${env}`;
    };
}
