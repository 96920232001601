export const SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION = {
    CURRENT: 'CURRENT',
    PARTNER_CHANNEL: 'PARTNER_CHANNEL',
};

export const EVENT_NAME = {
    SUBSCRIPTION_COMPLETE: 'PURCHASE',
    CLAIM_SUCCESS: 'SPEND_CREDITS',
};

export const BRANCH_IO_MIN_VERSION = {
    ANDROID: '1.62.0',
};


export const CHANNEL_PARTNER = {
    XSTREAMPREMIUM: 'XSTREAMPREMIUM',
};

export const TEMPLATE_OFFERS_CONFIG = {
    SI_HARD_BLOCK: {
        TOP_LOTTIE: 'FMF_TOP_LOTTE',
        BACKGROUND_LOTTIE: 'BACKGROUND_AB_LOTTIE',
        POPUP_ID: 'FMF_HARD_BLOCK_POPUP',
        price: 0,
        language: {
            btnText: 'Claim now for ₹{price}',
            textPointers: [
                '20+ OTTs in 1 App',
                'Watch on Mobile, Web & TV',
                'Content in 10+ Languages',
            ],
            subTxt: 'We will deduct ₹1 for verification and refund it within 24hrs',
        },
        offerParams: {
            ingressIntent: "FMF_MANDATE",
        },
    },
    RENEWAL_ADD_TO_BILL: {
        TOP_LOTTIE: "FMF_TOP_LOTTE",
        BACKGROUND_LOTTIE: "BACKGROUND_AB_LOTTIE",
        POPUP_ID: "RENEWAL_ADD_TO_BILL_POPUP",
        language: {
            btnText: "Add to my Bill",
            proposition: "Just {price}/{duration}",
            text: "Add subscription to Airtel bill {si}",
            subTxt: "Auto renews on {expiryDate}, cancel anytime.",
        },
    }
};

export const FMF_RENEWAL_CONFIG = {
    planDetails: {
        planId: 6226,
        ingressIntent: 'FMF_XTRM_RENEWAL',
        autoPayEnabled: true,
    },
};

export const OFFER_POPUP_NAMES = {
    FMF_HARD_BLOCK_POPUP: 'FMF_HARD_BLOCK_POPUP',
    RENEWAL_ADD_TO_BILL_POPUP: 'RENEWAL_ADD_TO_BILL_POPUP',
};

export const DAYS_TO_SECONDS = {
    '365days': 31536000000,
    '365': 31536000000,
    '90days': 7776000000,
    '90': 7776000000,
    '30days': 2592000000,
    '30': 2592000000,
};

export default {
    SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION,
    BRANCH_IO_MIN_VERSION,
    CHANNEL_PARTNER,
};
