const CrashType = {

    // JS Crash
    JS_CRASH: {
        val: 'js_crash',
        isCritical: true,
    },

    // Render Crash
    RENDER_CRASH: {
        val: 'render_crash',
        isCritical: true,
    },
    // API CRASH
    API_CRASH: {
        val: 'api_crash',
        isCritical: false,
    },

    PLAYBACK_ERROR: {
        val: 'playback_error',
        isCritical: true,
    },

};

export default CrashType;
