/* eslint-disable no-nested-ternary */
import lodashGet from 'lodash/get';
import {
    TILE_RAIL_TYPE, TILE_TYPES, RAIL_CTA_LIST, RAIL_CTA_TEXT_LIST, LOCAL_RAIL_TYPES, RAIL_TYPES, TILE_CTA_LIST, LOCAL_TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import { DeviceTypeUtil } from '@airtel-tv/utils';
import { addParameterToURL } from '@airtel-tv/utils/GlobalUtil';
import { getRailCtaUrl } from '../helpers/ClickToActionHelper';
import { buildTile } from './TileBuilder';

export const buildRail = ({
    layoutDetails = {},
    items,
    explicitTileType,
    explicitRailType,
    explicitPackageId,
    explicitRailTitle,
    explicitRailCtaText,
    railIndex,
    totalContentCount,
    channels = {},
    totalCount,
    more,
    continueWatchingData = {},
    channelId,
    explicitRailCtaType = null,
    explicitShowGrid = false,
    themeConfig,
    numberOfRails = 1,
    cpDetailsById,
    subscriptionDataById,
    altTitle = '',
    pageId,
    contentId = '',
    webPageId = {},
    actionTitleFromSearch = '',
    sourceTab,
    cpIdForDirectPlayback,
    isWeb = DeviceTypeUtil?.isWeb(),
    preferredArtwork: preferredArtworkFromParam = null,
    cdpTrailers,
    isFreeChannelsRail: isFreeChannelsRailFromProps = false,
    analyticsPageId,
    excludeCatalogTag: excludeCatalogTagFromProps = [],
    showCatalogTag: showCatalogTagFromProps = false,
    msisdnNo,
    activePlanAmount,
    preferredCp,
    ...rest
}) => {
    const {
        format = {},
        zionDisplay = {},
        id: railId = '',
    } = layoutDetails;
    const {
        ty = TILE_TYPES.MOVIE_LOGO,
        t = '',
        st: railSubtitle = '',
        ds = '',
        infoType = '',
        title: {
            lottie: titleLottie = '',
            logo: titleLogo = '',
            tc: titleColor = '',
            stc: subTitleColor = '',
        } = {},
    } = format;
    const {
        PEOPLE_RAIL, X_CDP_TRAILER_RAIL, CONTINUE_WATCHING_RAIL_V2, CONTINUE_WATCHING_RAIL,
    } = RAIL_TYPES;

    if (railSubtitle?.includes('{PhoneNumber}')) {
        railSubtitle.replace('{PhoneNumber}', msisdnNo || '');
    }
    if (railSubtitle?.includes('{active_plan_amount}')) {
        railSubtitle.replace('{active_plan_amount}', activePlanAmount || '');
    }
    if (railSubtitle?.includes('target_cp')) {
        railSubtitle.replace('{target_cp}', preferredCp || '');
    }


    let tileType = explicitTileType || ty;
    const railTitle = explicitRailTitle || t || altTitle || zionDisplay?.titleTxt;
    let formatedRailTitle = '';
    let railSecondaryText = ds;
    const isMobile = DeviceUtil?.getDeviceUtil()?.isMobile();

    const {
        dsBoldRange = '', dsItalicRange = '', titleBoldRange = '', bgImgUrlLS = '', bgImgUrl = '',
        excludeCatalogTag = excludeCatalogTagFromProps,
        showCatalogTag = showCatalogTagFromProps,
        description: tileTitle = '',
        bgColor: tileBgColor = '',
        ctaText: tileCtaText = '',
        descriptionColor: tileDescriptionColor = '',
    } = zionDisplay;
    let dsBoldRangeStrt = 0;
    let dsBoldRangeEnd = 0;
    let dsItalicRangeStrt = 0;
    let dsItalicRangeEnd = 0;
    let showPosition = false;
    let hideImageAltTag = false;
    let hideTileBottomeDetails = false;
    const railSecondaryTextSplit = dsBoldRange || dsItalicRange ? railSecondaryText.split('') : [];
    if (titleBoldRange && titleBoldRange < railTitle.length) {
        formatedRailTitle = railTitle;
        formatedRailTitle = `<b class="font-bold-900">${formatedRailTitle.slice(0, titleBoldRange)}</b>${formatedRailTitle.slice(titleBoldRange, formatedRailTitle.tilesLength)}`;
    }
    if (dsBoldRange) {
        dsBoldRangeStrt = dsBoldRange.split(':')[0];
        dsBoldRangeEnd = dsBoldRange.split(':')[1];
        railSecondaryTextSplit.splice(dsBoldRangeStrt, 0, '<b>');
        railSecondaryTextSplit.splice(Number(dsBoldRangeEnd) + Number(dsBoldRangeEnd), 0, '</b>');
    }
    if (dsItalicRange) {
        const offset = dsBoldRange ? 1 : 0;
        dsItalicRangeStrt = dsItalicRange.split(':')[0];
        dsItalicRangeEnd = dsItalicRange.split(':')[1];
        railSecondaryTextSplit.splice(Number(dsItalicRangeStrt) + offset, 0, '<i>');
        railSecondaryTextSplit.splice(Number(dsItalicRangeEnd) + offset + Number(dsItalicRangeEnd), 0, '</i>');
    }
    if (railSecondaryTextSplit && railSecondaryTextSplit.length > 0) {
        railSecondaryText = railSecondaryTextSplit.join('');
    }

    const lottieUrl = !isMobile && bgImgUrlLS ? bgImgUrlLS : bgImgUrl;

    let railCtaType = explicitRailCtaType || lodashGet(format, 'action.st', RAIL_CTA_LIST.LISTING);
    let railCtaText = explicitRailCtaText || lodashGet(format, 'action.t', RAIL_CTA_TEXT_LIST.MORE);
    const action = lodashGet(format, 'action', null);
    const railCtaMeta = lodashGet(format, 'action.meta', {});
    let actionTitle = actionTitleFromSearch || lodashGet(format, 'action.t', '');
    let totalRailLength = lodashGet(format, 'tc', totalCount || 100);
    const titleL2 = lodashGet(format, 'action.titleL2', '');
    const showAll = lodashGet(format, 'showAll', false);
    // let railType = explicitRailType || TILE_RAIL_TYPE[tileType];
    let hideQuickViewBanner = false;
    let artwork = 'web';
    const scrollRailTillEnd = false;
    const tileLinkStyle = {};
    const isFreeChannelsRail = isFreeChannelsRailFromProps || tileType === TILE_TYPES.X_FREE_CHANNELS;
    let customClassTileLevel = '';
    if (!isWeb) {
        artwork = 'largescreen';
    }
    else if (isMobile) {
        artwork = 'mobility';
    }

    const dimensions = lodashGet(format, `artwork.${artwork}.hw`, '');
    // if (!isWeb && tileType === TILE_TYPES.TRAILERS_RAIL) {
    //     scrollRailTillEnd = true;
    //     dimensions = '475:845';
    // }
    if (isFreeChannelsRail) {
        hideImageAltTag = true;
        hideTileBottomeDetails = true;
        tileLinkStyle.background = 'radial-gradient(100.24% 100.24% at 50% -0.24%, #767F88 0%, #525966 100%)';
        customClassTileLevel = 'distro-tile';
        if (isWeb) {
            railCtaType = RAIL_CTA_LIST.DISTRO_LIVETV_LISTING;
            railCtaText = RAIL_CTA_TEXT_LIST.MORE;
            actionTitle = 'See All';
        }
    }
    let preferredArtwork = preferredArtworkFromParam;
    let tileHeight = 0;
    let tileWidth = 0;
    if (dimensions) {
        const dimensionSplit = dimensions?.split(':');
        tileHeight = dimensionSplit && dimensionSplit[0];
        tileWidth = dimensionSplit && dimensionSplit[1];
    }
    const dimensionUnit = isWeb ? 'px' : 'rem';
    const style = tileHeight > 0 && tileWidth > 0 && ![
        TILE_TYPES.X_EXPLORE_V2,
        TILE_TYPES.X_FULL_BANNER,
    ].includes(tileType) && (!isWeb || isMobile) ? {
            width: `${dimensionUnit === 'rem' ? (tileWidth / 10) : tileWidth}${dimensionUnit}`,
            height: `${dimensionUnit === 'rem' ? (tileHeight / 10) : tileHeight}${dimensionUnit}`,
        } : {};

    const highlighted_rail_details = {};
    const {
        bgImg = '',
        bg_img_ls = '',
        subTitle = '',
        titleType = '',
        maxTileTitleLines = 0,
    } = zionDisplay;
    const HightLightedRailImg = isMobile ? bgImg : bg_img_ls;
    if (HightLightedRailImg && subTitle) {
        highlighted_rail_details.bgImg = HightLightedRailImg;
        highlighted_rail_details.subTitle = subTitle;
    }
    const showCtaText = zionDisplay?.showCtaText !== false;
    let seriesId;
    const layoutContents = lodashGet(layoutDetails, [
        'contents',
    ], []);
    let packageId = explicitPackageId || null;

    if (layoutContents && layoutContents.length > 0) {
        const layoutContent = layoutContents[0];
        ({ seriesId } = lodashGet(layoutContent, 'details.content[0]', ''));
        ({ packageId } = layoutContent);
    }
    let channelName = '';
    if (channelId && channels && channels.length > 0) {
        const currentChannel = channels.find(channel => channel.id === channelId);
        if (currentChannel) {
            channelName = currentChannel.title;
        }
    }
    preferredArtwork = lodashGet(format, `artwork.${artwork}.ty`, '') || preferredArtwork || '';

    let railCtaActionUrl = getRailCtaUrl({
        cta: railCtaType,
        title: railTitle,
        packageId,
        railId,
        category: railCtaMeta.category || '',
        query: railCtaMeta.query || '',
        pageUrlName: railCtaMeta.pageUrlName,
        channelName,
        channelId,
        titleL2,
        preferredArtwork,
    });

    railCtaActionUrl = railCtaActionUrl || lodashGet(layoutDetails, 'format.action.url', null);
    const queryParams = {};
    if (showCatalogTag) {
        queryParams.showCatalogTag = true;
    }
    if (excludeCatalogTag.length) {
        queryParams.excludeCatalogTag = JSON.stringify(excludeCatalogTag);
    }
    if (Object.keys(queryParams).length) {
        const urlParams = addParameterToURL(railCtaActionUrl, queryParams);
        railCtaActionUrl = urlParams;
    }
    const showGrid = explicitShowGrid || numberOfRails <= 2;
    const lazyLoadRail = !showGrid;
    // if (tileType === TILE_TYPES.X_EXPLORE_V2) {
    // }
    // Note: below code runs for web app only
    //--------------------------
    if (tileType === TILE_TYPES.X_TOP_NUMBER_RAIL_V2) {
        tileType = TILE_TYPES.X_MASTER_RAIL;
        showPosition = true;
        style.marginLeft = '9rem';
    }

    let tileCtaType = lodashGet(layoutDetails, 'format.contentAction.st', RAIL_CTA_LIST.LISTING);
    if ([
        TILE_TYPES.X_HIGHLIGHTED_PORTRAIT,
        TILE_TYPES.X_MASTER_RAIL,
    ].includes(tileType)) {
        if (tileType === TILE_TYPES.X_HIGHLIGHTED_PORTRAIT) {
            hideQuickViewBanner = true;
        }
        const artWork = !isWeb ? lodashGet(format, 'artwork.largescreen.ty', 'PORTRAIT') : isMobile ? lodashGet(format, 'artwork.mobility.ty', 'PORTRAIT') : lodashGet(format, 'artwork.web.ty', 'PORTRAIT');
        if (!action) {
            railCtaActionUrl = null;
        }
        switch (artWork) {
            case CONTENT_IMAGE_TYPES.LANDSCAPE_169:
            case CONTENT_IMAGE_TYPES.LANDSCAPE_169_HD:
                tileType = TILE_TYPES.TVSHOW_BIG_43;
                break;
            case CONTENT_IMAGE_TYPES.PORTRAIT:
            case CONTENT_IMAGE_TYPES.PORTRAIT_HD:
                tileType = TILE_TYPES.MOVIE_NOLOGO;
                break;
            default:
                break;
        }
    }
    //-----------------------------


    // for distinguishing between explore by rail and partner channel rail
    // to be changed
    tileCtaType = (tileType === TILE_TYPES.CONTENT_PARTNER_RAIL
        || tileType === LOCAL_TILE_TYPES.CHANNEL_PARTNER_RAIL) ? TILE_CTA_LIST.PARTNER_CHANNEL : tileCtaType;

    const railType = explicitRailType || TILE_RAIL_TYPE[tileType];
    const isContinueWatchingRail = [
        CONTINUE_WATCHING_RAIL_V2,
        CONTINUE_WATCHING_RAIL,
    ].includes(railType);
    let tiles = [];
    let showMore = false;
    if (more !== undefined && !more) {
        showMore = false;
    }
    else if (actionTitle !== '') {
        showMore = true;
    }
    if (isWeb && isFreeChannelsRail) {
        showMore = true;
    }
    let tilesArray = items || [];
    if (tileType === PEOPLE_RAIL) {
        tilesArray = items?.[0]?.credits || [];
        totalRailLength = 100;
    }


    // let isPYWRail = false;
    // let showRailTitle = true;
    // let nonQuickViewRail = false;
    // let containerClass = '';
    // let customRailSlideBtnClass = '';
    // if (railType === RAIL_TYPES.X_VERTICAL_TRAILER_RAIL && isWeb) {
    //     isPYWRail = true;
    //     showRailTitle = false;
    //     nonQuickViewRail = true;
    //     showMore = false;
    //     containerClass = 'pyw_rail';
    //     customRailSlideBtnClass = 'pyw_slide_btn';
    // }

    if (tilesArray && Array.isArray(tilesArray) && tilesArray.length > 0) {
        tiles = tilesArray.slice(0, totalRailLength).map(item => buildTile({
            item,
            tileCtaType,
            railType,
            tileType,
            totalContentCount,
            channels,
            cpDetailsById,
            subscriptionDataById,
            continueWatchingData,
            themeConfig,
            showGrid,
            tilesLength: tilesArray.length,
            sourceTab,
            cpIdForDirectPlayback,
            preferredArtwork,
            style,
            isMobile,
            excludeCatalogTag,
            showCatalogTag,
            hideQuickViewBanner,
            tileLinkStyle,
            isFreeChannelsRail,
            customClassTileLevel,
            hideImageAltTag,
            hideTileBottomeDetails,
        }));
        // showMore = totalCount > items.length;
    }
    if (tileType === X_CDP_TRAILER_RAIL && cdpTrailers && cdpTrailers.length) {
        tiles = cdpTrailers.map(trailer => ({
            ...trailer,
            tileType: RAIL_TYPES.X_CDP_TRAILER_RAIL,
        }));
    }
    let imageSettings;
    const imageSettingsFiltered = {};

    switch (railType) {
        case LOCAL_RAIL_TYPES.CHANNEL_PARTNER_WIDGET:
            imageSettings = {
                1000: 500,
                500: 500,
                320: 500,
            };
            break;
        case RAIL_TYPES.WATCHLIST_RAIL:
        case RAIL_TYPES.CONTINUE_WATCHING_RAIL:
            imageSettings = {
                1000: 440,
                500: 289,
                320: 281,
            };
            break;
        case RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL:
            imageSettings = {
                2000: 2000,
                1000: 1606,
                500: 600,
                320: 500,
            };
            break;
        case RAIL_TYPES.EXPLORE_CHANNEL_PARTNER:
            imageSettings = {
                1000: 251,
                500: 82,
                320: 81,
            };
            break;
        case RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT:
            imageSettings = {
                1000: 251,
                500: 200,
                320: 200,
            };
            break;
        case RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE:
            imageSettings = {
                1000: 440,
                500: 400,
                320: 400,
            };
            break;
        case LOCAL_RAIL_TYPES.DIMENSION_LIST:
        case LOCAL_RAIL_TYPES.LIST:
            imageSettings = {
                1000: 400,
                500: 400,
                320: 400,
            };
            break;
        case RAIL_TYPES.TVSHOW_LOGO_43_RAIL:
            imageSettings = {
                1000: 508,
                500: 400,
                320: 400,
            };
            break;
        case RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL:
            imageSettings = {
                1000: 192,
                500: 192,
                320: 192,
            };
            break;
        case RAIL_TYPES.GRID_SQUARE_EXPLORE_RAIL:
            imageSettings = {
                1800: 295,
                1400: 295,
                1000: 295,
                991: 295,
                500: 217,
                320: 217,
            };
            break;
        case RAIL_TYPES.GRID_ROUND_SECTION_RAIL:
            imageSettings = {
                1000: 507,
                500: 358,
                320: 216,
            };
            break;
        case RAIL_TYPES.BORDER_SECTION_RAIL:
            imageSettings = {
                1000: 390,
                500: 275,
                320: 166,
            };
            break;
        default:
            imageSettings = {
                1000: 390,
                500: 275,
                320: 166,
            };
            break;
    }
    for (const key in imageSettings) {
        if (key >= 1000) {
            imageSettingsFiltered[key] = imageSettings[key];
        }
    }
    if (railType === RAIL_TYPES.X_PENDING_ACTIVATION_RAIL) {
        tiles = [
            {
                railType: RAIL_TYPES.X_PENDING_ACTIVATION_RAIL,
                tileType: RAIL_TYPES.X_PENDING_ACTIVATION_RAIL,
                railPosition: railIndex || 0,
                pageId,
                tileTitle,
                tileBgColor,
                tileCtaText,
                tileDescriptionColor,
            },
        ];
    }
    return {
        railId,
        railType,
        railTitle,
        tiles,
        railCtaType,
        railCtaText,
        railCtaActionUrl,
        tileType,
        railIndex: railIndex || 0,
        totalContentCount: totalContentCount || 0,
        imageSettings: isWeb ? imageSettings : imageSettingsFiltered,
        showMore,
        packageId,
        channelId,
        channelName,
        showGrid,
        lazyLoadRail,
        pageId,
        contentId,
        railSecondaryText,
        seriesId,
        webPageId,
        titleLogo,
        titleColor,
        subTitleColor,
        actionTitle,
        zionDisplay,
        tileHeight,
        tileWidth,
        showCtaText,
        preferredArtwork,
        style,
        showAll,
        highlighted_rail_details,
        showPosition,
        isContinueWatchingRail,
        titleLottie,
        formatedRailTitle,
        railSubtitle,
        lottieUrl,
        titleType,
        maxTileTitleLines,
        infoType,
        scrollRailTillEnd,
        totalRailLength,
        hideQuickViewBanner,
        // isPYWRail,
        // nonQuickViewRail,
        // showRailTitle,
        // containerClass,
        // customRailSlideBtnClass,
        tileLinkStyle,
        isFreeChannelsRail,
        hideImageAltTag,
        hideTileBottomeDetails,
        customClassTileLevel,
        ...rest,
    };
};

export default { buildRail };
