import lodashGet from 'lodash/get';
import {
    TILE_CTA_LIST, LOCAL_TILE_TYPES, DEFAULT_THEME_TYPE, TILE_TYPES, RAIL_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import { PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import { ContentImageProvider } from '@airtel-tv/utils/ContentImageProvider';
import { calculateProgressPercentage, isObjEmpty, remainingTimeToWatch } from '@airtel-tv/utils/GlobalUtil';
import { getThemeParams } from '@airtel-tv/utils/DefaultTheme';
import { getParentContentId } from '@airtel-tv/utils/SyncHelper';
import { DeviceTypeUtil } from '@airtel-tv/utils';
import ImageFactory from '../factories/ImageFactory';
import { getTileCtaUrl } from '../helpers/ClickToActionHelper';

export const buildTile = ({
    item,
    railType,
    tileType,
    tileCtaType,
    totalContentCount,
    channels,
    continueWatchingData,
    themeConfig,
    showGrid = false,
    cpDetailsById = null,
    showPlayback = false,
    tilesLength,
    sourceTab,
    preferredArtwork,
    style,
    isContinueWatchingRail = false,
    excludeCatalogTag = [],
    showCatalogTag = false,
    hideQuickViewBanner = false,
    analyticsPageId,
    isFreeChannelsRail = false,
    customClassTileLevel = '',
    railElementId,
    isWatchListTile,
}) => {
    const {
        bgImgUrlMap = {}, displayTitle, tileImages = null, tvShowImage = null,
    } = item;
    let { images = {} } = item;

    if (tileImages && Object.keys(tileImages).length) {
        images = {
            ...images,
            ...tileImages,
        };
        // if (tileImages.FEATURE_BANNER_HD) {
        //     images.FEATURE_BANNER_HD = tileImages.FEATURE_BANNER_HD;
        // }
        // if (tileImages.FEATURE_BANNER) {
        //     images.FEATURE_BANNER = tileImages.FEATURE_BANNER;
        // }
        // if (tileImages.SQUARE_HD) {
        //     images.SQUARE_HD = tileImages.SQUARE_HD;
        // }
        // if (tileImages.SQUARE) {
        //     images.SQUARE = tileImages.SQUARE;
        // }
    }
    if (tvShowImage && Object.keys(tvShowImage).length && isContinueWatchingRail) {
        images = tvShowImage;
    }
    let imageUrl = ImageFactory({
        images,
        railType,
        tileType,
        preferredArtwork,
    });
    let imageUrlMobile = imageUrl;
    if (railType === RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL) {
        imageUrlMobile = ContentImageProvider({
            imageMap: images,
            imgType: [
                CONTENT_IMAGE_TYPES.SQUARE_HD,
                CONTENT_IMAGE_TYPES.SQUARE,
            ],
        });
    }
    let deepLink = lodashGet(item, 'deepLink', '');

    const {
        id,
        cpId,
        segment,
        title,
        genre,
        programType,
        language,
        createdAt,
        name,
        episodeNumber,
        channelId,
        episodeRefs,
        airDate,
        refId,
        selected,
        tvShowId,
        seriesId,
        episodeSeasonNum,
        tvShowName,
        episodeNum,
        isPartnerChannel,
        // pageLink,
        seasonId,
        deepLinkJson,
        contentAutoplay = false,
        tileId = '',
        displayTags = [],
        layoutTagID = [],
    } = item;
    let tileTagIdExcluded = '';
    const { tileTagId } = item;
    const isWeb = DeviceTypeUtil.isWeb();
    if (deepLinkJson && Object.keys(deepLinkJson).length > 0) {
        deepLink = deepLinkJson.BROWSER;
    }

    const cpDetails = lodashGet(cpDetailsById, `[${cpId}]`, null);
    const pageLink = lodashGet(cpDetails, 'pageLink', '');
    const cpTitle = lodashGet(cpDetails, 'title', '');
    const coverImage = lodashGet(cpDetails, 'coverImage', {});
    const logoUrl = lodashGet(cpDetails, 'roundLogoUrl', '') || lodashGet(cpDetails, 'logoUrl', '');
    let useCoverImageUrl = false;

    let coverImgUrl = showGrid
        ? lodashGet(coverImage, `[${CONTENT_IMAGE_TYPES.PORTRAIT}]`, '')
        : lodashGet(coverImage, `[${CONTENT_IMAGE_TYPES.SQUARE}]`, '');

    if (tileType === TILE_TYPES.X_PARTNER_LANDSCAPE_RAIL) {
        coverImgUrl = coverImage[CONTENT_IMAGE_TYPES.RECTANGLE] || '';
        useCoverImageUrl = true;
        imageUrl = coverImgUrl;
    }

    // calculating progress bar percentage
    let showProgressBar = true;
    let progressPercentage;
    let remainingTime;
    if ([
        LOCAL_TILE_TYPES.WATCHLIST,
        LOCAL_TILE_TYPES.X_PYW_RAIL,
    ].indexOf(tileType) > -1) {
        showProgressBar = false;
    }
    else {
        progressPercentage = calculateProgressPercentage({
            continueWatchingData,
            id: getParentContentId(item),
        });
        remainingTime = remainingTimeToWatch({
            continueWatchingData,
            id: getParentContentId(item),
        });
    }

    let theme = {};
    if (!isObjEmpty(themeConfig)) {
        theme = getThemeParams(themeConfig[cpId], themeConfig[DEFAULT_THEME_TYPE]);
    }

    let channelName = channelId;
    let channelLogoUrl = '';

    // TODO: remove hard code MOVIE when you start getting it from CMS
    let category = programType;

    if (tileCtaType === TILE_CTA_LIST.SEARCH) {
        category = PROGRAM_TYPES.MOVIE;
    }
    else if (PROGRAM_TYPES.EPISODE === programType && channelId) { // ideally backend should send live tv show
        category = PROGRAM_TYPES.CATCHUP_EPISODE;
    }
    if (isPartnerChannel) {
        category = PROGRAM_TYPES.PACKAGE;
    }

    if (channelId && channels && channels.length > 0) {
        const currentChannel = channels.find(channel => channel.id === channelId);
        if (currentChannel) {
            channelName = currentChannel.title;
            channelLogoUrl = ContentImageProvider({
                imageMap: currentChannel && currentChannel.imgs,
                imgType: [
                    CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                    CONTENT_IMAGE_TYPES.LOGO,
                ],
            });
        }
    }

    let tileCtaActionUrl = '';
    let addtionalQueryParams = '';

    if (!isWeb && contentAutoplay) {
        addtionalQueryParams = '&directPlaybackFromHome=true';
    }
    const tagsToDisplay = layoutTagID?.length ? layoutTagID : displayTags;
    if (showCatalogTag && tagsToDisplay?.length) {
        const filteredtags = tagsToDisplay?.filter(tag => !excludeCatalogTag.includes(tag));
        tileTagIdExcluded = filteredtags?.length ? filteredtags[0] : '';
    }

    tileCtaActionUrl = getTileCtaUrl({
        cta: tileCtaType,
        contentId: id,
        title: programType === PROGRAM_TYPES.EPISODE && !showPlayback ? tvShowName || (title || name) : (title || name || displayTitle),
        category,
        genre,
        lang: language,
        channelName,
        episodeNumber: episodeNumber || episodeNum,
        airDate,
        tvShowId: tvShowId || seriesId,
        episodeSeasonNum,
        tvShowName,
        pageLink,
        cpTitle,
        cpId,
        showPlayback,
        sourceTab,
        deepLink,
        addtionalQueryParams,
        seriesId,
        seasonId,
        isArtist: tileType === TILE_TYPES.PEOPLE_RAIL,
        isWeb,
        tileType,
    });
    const showNextEpisode = programType === PROGRAM_TYPES.EPISODE && (!showProgressBar || !progressPercentage);
    const hideAddtoWatchListCta = [
        LOCAL_TILE_TYPES.LOCAL_TILE,
        PROGRAM_TYPES.LIVETVCHANNEL,
    ].includes(programType);

    return {
        id,
        railType,
        tileCtaActionUrl,
        imageUrl,
        tileType,
        cpId,
        segment,
        title,
        genre,
        createdAt,
        totalContentCount,
        episodeNumber: episodeNumber || episodeNum,
        channelId,
        episodeRefs,
        airDate,
        name,
        refId,
        channelName,
        channelLogoUrl,
        selected,
        progressPercentage,
        showProgressBar,
        tvShowName,
        seriesId: seriesId || tvShowId,
        contentDetails: { ...item },
        showGrid,
        theme,
        cpDetails,
        coverImgUrl,
        logoUrl,
        showNextEpisode,
        tilesLength,
        tileTagIdExcluded,
        tileTagId: tileTagId || tileTagIdExcluded,
        imageUrlMobile,
        bgImgUrlMap,
        style,
        preferredArtwork,
        remainingTime,
        zionTileId: tileId,
        tileImages: images,
        railType,
        hideQuickViewBanner,
        category,
        analyticsPageId,
        isFreeChannelsRail,
        customClassTileLevel,
        railElementId,
        isWatchListTile,
        hideAddtoWatchListCta,
        useCoverImageUrl,
    };
};

export default { buildTile };
