const PlansAndSubscriptionAction = {
    SVOD_ALL_PLANS: 'SVOD_ALL_PLANS',
    SVOD_ALL_PLANS_SUCCESS: 'SVOD_ALL_PLANS_SUCCESS',
    SVOD_ALL_PLANS_ERROR: 'SVOD_ALL_PLANS_ERROR',
    ONEHUB_SHOW_ALL_PLANS_TILES: 'ONEHUB_SHOW_ALL_PLANS_TILES',
    ONEHUB_SHOW_MORE_PLANS_PAGE: 'ONEHUB_SHOW_MORE_PLANS_PAGE',
    RESET_ONEHUB_STATE: 'RESET_ONEHUB_STATE',
    RESET_ONEHUB_PLANS_PAGE_FLAGS: 'RESET_ONEHUB_PLANS_PAGE_FLAG',
    ACTIVATE_ONEHUB_PARTNER: 'ACTIVATE_ONEHUB_PARTNER',
    SET_PARTNER_ACTIVATION_DATA: 'SET_ACTIVATION_DATA',
    ONEHUB_META_INFO: 'ONEHUB_META_INFO',
    PURGE_SVOD_SANS_ACTIVATION: 'PURGE_SVOD_SANS_ACTIVATION',
    PURGE_SVOD_ALL_PLANS: 'PURGE_SVOD_ALL_PLANS',
    SVOD_RESET: 'SVOD_RESET',
};

export default PlansAndSubscriptionAction;

export const SvodAllPlansAction = payload => ({
    type: PlansAndSubscriptionAction.SVOD_ALL_PLANS,
    payload,
});

export const SvodAllPlansSuccessAction = payload => ({
    type: PlansAndSubscriptionAction.SVOD_ALL_PLANS_SUCCESS,
    payload,
});

export const SvodAllPlansErrorAction = payload => ({
    type: PlansAndSubscriptionAction.SVOD_ALL_PLANS_ERROR,
    payload,
});

export const OnehubShowAllPlansTiles = payload => ({
    type: PlansAndSubscriptionAction.ONEHUB_SHOW_ALL_PLANS_TILES,
    payload,
});

export const OnehubShowMorePlansPage = payload => ({
    type: PlansAndSubscriptionAction.ONEHUB_SHOW_MORE_PLANS_PAGE,
    payload,
});

export const ResetOnehubPlansPageFlags = payload => ({
    type: PlansAndSubscriptionAction.RESET_ONEHUB_PLANS_PAGE_FLAGS,
    payload,
});
export const SetPartnerActivationData = payload => ({
    type: PlansAndSubscriptionAction.SET_PARTNER_ACTIVATION_DATA,
    payload,
});

export const OnehubMetaInfoAction = payload => ({
    type: PlansAndSubscriptionAction.ONEHUB_META_INFO,
    payload,
});

export const ActivateOneHubPartner = payload => ({
    type: PlansAndSubscriptionAction.ACTIVATE_ONEHUB_PARTNER,
    payload,
});

export const PurgeSvodAllPlansSansActivation = payload => ({
    type: PlansAndSubscriptionAction.PURGE_SVOD_SANS_ACTIVATION,
    payload,
});

export const PurgeSvodAllPlans = payload => ({
    type: PlansAndSubscriptionAction.PURGE_SVOD_ALL_PLANS,
});

export const resetSvodAllPlans = payload => ({
    type: PlansAndSubscriptionAction.SVOD_RESET,
});
