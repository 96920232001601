import React from 'react';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { DateTime, LanguageProviderUtil, SubscriptionUtil } from '@airtel-tv/utils';
import { IMAGE_PATHS, LOCAL_STORE_KEYS, ONEHUB_CTA_TYPES, ONEHUB_PLAN_STATUS, XPARTNERS_LIMIT_LS, PAYMENT_MODES, RAIL_TYPES, TILE_TYPES, XPARTNERS_WITH_PREMIUM_LIMIT_LS, CONTENT_PROVIDERS } from '@airtel-tv/constants';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { FORMAT_TYPE } from '@airtel-tv/constants/DateTimeConst';


const getPlanTitleElement = (planTitle, amount, FOR_TAG, autoRenewValidity) => (
    <>
        {planTitle}
        <span className="for-tag"> {FOR_TAG} </span>
        {`₹${amount}${autoRenewValidity ? ` ${autoRenewValidity}` : ''}`}
    </>
);


const getPlanSubtitleElement = tileSubHeading => (
    <>
        {tileSubHeading?.map((subtitle, index) => (
            <>
                <span>{subtitle}</span>
                {index < tileSubHeading.length - 1 ? <span className="dot">•</span> : ''}
            </>
        ))}
    </>
);

const cpImagesObject = (cpDetailsById = {}, partners = []) => {
    const cpConfigImages = {};
    partners.forEach((partner) => {
        const { cpId = '' } = partner;
        const { coverImage = {}, channelCoverImage = {}, cpIconURL } = cpDetailsById[cpId] || {};
        const { SQUARE = '', RECTANGLE = '' } = coverImage || {};
        const { mobile = '', largescreen = '' } = channelCoverImage || {};
        cpConfigImages[cpId] = {
            cpLogo: SQUARE || cpIconURL,
            cpTileImage: RECTANGLE,
            cpMobileCoverImage: mobile,
            cpLSCoverImage: largescreen,
        };
    });
    return cpConfigImages;
};

export const buildTile = ({
    planDetails = {},
    preferredCp,
    railType,
    tileType,
    totalContentCount,
    cpDetailsById = null,
    tilesLength,
    sourceTab,
    preferredArtwork,
    style = {},
    hideQuickViewBanner = false,
    analyticsPageId,
    customClassTileLevel = '',
    railElementId,
    railProps,
    planType,
    msisdnNo,
    planListingExist,
    activePlanAmount,
    activePlans,
    ...rest
}) => {
    const {
        railId,
        railTitle,
        railSubtitle,
        zionDisplay,
    } = railProps;
    const {
        offerId,
        offerTitle,
        planId,
        packGroup,
        cpId,
        partners = [],
        duration,
        autoPayEnabled,
        specialTags,
        discountedPrice,
        price,
        discount,
        sku,
        freeTrialAvailable,
        meta: {
            dataValidity,
            dataBenefit,
            payment: {
                mode,
            } = {},
        } = {},
        tileId = '',
        displayAmount = "",
        status = '',
        preReminder,
        renewPlanId,
        planStatus,
        rechargeBundlePack,
        displayTitle,
        validTill,
        title,
        upcoming,
        validityPlaceholder
    } = planDetails;
    let planTitle = displayTitle;
    const isMobile = DeviceUtil.getDeviceUtil()?.isMobile();
    const isWeb = DeviceUtil.getDeviceUtil()?.isWeb();
    const LANGUAGE = LanguageProviderUtil?.getLanguage() || {};
    const platform = browserStore.get(LOCAL_STORE_KEYS.OS) || 'WEB';
    const {
        MOBILE_DATA_BENEFIT_TEMPLATE = 'Mobile Data {dataBenefit}',
        VALIDITY_TEMPLATE = 'Valid for {validity}',
        RECHARGE_NOW_TEMPLATE = 'Recharge Now for ₹{discountedPrice}',
        SUBSCRIBE_NOW_TEMPLATE = 'Subscribe Now for ₹{discountedPrice}',
        PLAN_DETAILS = 'Plan Details',
        SEE_PLAN_DETAILS = 'See Plan Details',
        SUBSCRIBE_WITH_MOBILE = 'Subscribe with Mobile Recharge',
        SUBSCRIBE_NOW = 'Subscribe Now',
        RECHARGE_NOW = 'Recharge Now',
        AUTO_RENEWS = 'Auto Renews',
        CANCEL_ANYTIME = 'Cancel Anytime',
        FOR: FOR_TAG = 'for',
        ACTIVATE_NOW = 'Activate Now',
        ACTIVATING = 'Activating',
        ACTIVATION_PENDING = 'Activation Pending',
        ACTIVATION_INPROGRESS = 'Activation Inprogress',
        ACTIVATED = 'Activated',
        SUBSCRIPTION_RENEW_TXT = 'Renew Now',
        MORE_PLANS_LABEL = 'More Plans',
        VIEW_DETAILS_LABEL = 'View Details',
        XTREAM_PLAY_OTT_LITERAL = '+ XSTREAM PLAY ({OTTCount}+ OTTs)',
        REMAINING_OTT_LABEL = '{remainingOTTCount}+ OTTs',
        OTT_COUNT_LABEL = '{OTTCount} OTT{s}',
        BROWSE_CONTENT = 'Browse Content',
        RECHARGE = 'Recharge',
        SUBSCRIBE = 'Subscribe',
        PLAN_EXPIRY_LABEL = 'Expiring on {expiryDate}',
        VIEW_CONTENT = 'View Content',
        KNOW_MORE = 'Know More',
        VALIDITY_PLACEHOLDER = 'Valid {validityPlaceholder}',
        ACTIVATE = 'Activate',
    } = LANGUAGE;
    const subscribeWithMobileIcon = IMAGE_PATHS.MOBILE_ICON;
    let primaryActionType = '';
    let secondaryActionType = '';
    let isRenewNowCase = false;
    const tileSubHeading = [];
    let subscriberInfo = {};
    let assetNamePrefix = '';
    let purchaseCtaText = '';
    let activationStatus = '';
    let planExpiryText = '';
    let seePlanCtaText = isWeb ? PLAN_DETAILS : SEE_PLAN_DETAILS;
    const showRechargeNotification = rechargeBundlePack && activePlans.filter(plan => plan?.rechargeBundlePack)?.length;
    let planPrice = discountedPrice;
    let autoRenewValidity = ''; 
    let customStyle = style;

    // for subscription activation tile expiry
    if (validTill || validityPlaceholder) {
        const planExpiryDate = new DateTime(validTill).format(FORMAT_TYPE.DATE_WITH_ORDINAL_FORMAT);
        planExpiryText = validityPlaceholder ? VALIDITY_PLACEHOLDER.replace('{validityPlaceholder}', validityPlaceholder) : PLAN_EXPIRY_LABEL.replace('{expiryDate}', planExpiryDate);
    }

    if (!planTitle) {
        planTitle = partners?.length ? OTT_COUNT_LABEL.replace('{OTTCount}', partners?.length).replace('{s}', partners.length > 1 ? 's' : '' ) : '';
    }

    if (mode === PAYMENT_MODES.AIRTEL_PARTNER) {
        if (dataBenefit) {
            const mobileDataBenefit = MOBILE_DATA_BENEFIT_TEMPLATE?.replace('{dataBenefit}', dataBenefit);
            tileSubHeading.push(mobileDataBenefit);
        }
        if (duration) {
            const validityText = validityPlaceholder ? VALIDITY_PLACEHOLDER.replace('{validityPlaceholder}', validityPlaceholder) : VALIDITY_TEMPLATE.replace('{validity}', duration);
            tileSubHeading.push(validityText);
        }
        if (planType !== 'rechargePlans') {
            subscriberInfo = {
                heading: SUBSCRIBE_WITH_MOBILE,
                subHeading: msisdnNo ? `+91 ${msisdnNo}` : '',
                icon: subscribeWithMobileIcon,
            };
        }
        purchaseCtaText = isMobile ? RECHARGE_NOW : RECHARGE_NOW_TEMPLATE.replace('{discountedPrice}', discountedPrice);
        assetNamePrefix = 'recharge';
        if (!planTitle) {
            planTitle = RECHARGE;
        }
    }
    else {
        if (autoPayEnabled) {
            tileSubHeading.push(AUTO_RENEWS);
            tileSubHeading.push(CANCEL_ANYTIME);
            // autoRenewValidity = title;
        }
        if(planType === 'd2cPlans') {
            autoRenewValidity = title?.toLowerCase();
        }
        purchaseCtaText = isMobile ? SUBSCRIBE_NOW : SUBSCRIBE_NOW_TEMPLATE.replace('{discountedPrice}', discountedPrice);
        assetNamePrefix = 'subscribe';
        if (!planTitle) {
            planTitle = SUBSCRIBE;
        }
    }

    if (tileType === TILE_TYPES.X_PARTNER_ACTIVATION_RAIL) {
        if (status === ONEHUB_PLAN_STATUS.CLAIM_IN_PROGRESS) {
            activationStatus = ACTIVATION_INPROGRESS;
            purchaseCtaText = ACTIVATING;
        }
        else if (status === ONEHUB_PLAN_STATUS.PENDING_ACTIVATION) {
            activationStatus = ACTIVATION_PENDING;
            purchaseCtaText = isMobile ? ACTIVATE : ACTIVATE_NOW;
        }
        else {
            activationStatus = ACTIVATED;
            purchaseCtaText = planDetails?.partner?.cpId === CONTENT_PROVIDERS.NETFLIX ? KNOW_MORE : VIEW_CONTENT;
        }
    }

    // if (tileType === TILE_TYPES.X_ACTIVE_PLAN) {
    //     planPrice = price;
    // }

    const planTitleElement = getPlanTitleElement(planTitle, planPrice, FOR_TAG, autoRenewValidity);
    const planSubtitleElement = getPlanSubtitleElement(tileSubHeading);

    // ICONS AND IMAGES
    const cpConfigImages = cpImagesObject(cpDetailsById, partners) || {};
    // partnerImages logic
    const premiumPartnerImages = partners.filter(partner => partner?.meta?.premiumOTT).map(partner => cpConfigImages[partner?.cpId]?.cpLogo || '');
    let xstreamPartnerImages = partners.filter(partner => !partner?.meta?.premiumOTT).map(partner => cpConfigImages[partner?.cpId]?.cpLogo || '');
    const originalXstreamPartners = [...xstreamPartnerImages];
    const xstreamPlayOTTLabel = premiumPartnerImages?.length && originalXstreamPartners?.length ? XTREAM_PLAY_OTT_LITERAL.replace('{OTTCount}', originalXstreamPartners.length - 1) : '';

    let MAX_XSTREAM_DISPLAY_PARTNERS = originalXstreamPartners?.length;
    if (!isWeb) {
        const XSTREAM_PARTNERS_MAX_LIMIT = premiumPartnerImages?.length ? XPARTNERS_WITH_PREMIUM_LIMIT_LS : XPARTNERS_LIMIT_LS;
        MAX_XSTREAM_DISPLAY_PARTNERS = XSTREAM_PARTNERS_MAX_LIMIT;
    }
    const remainingOTTCount = originalXstreamPartners?.length - MAX_XSTREAM_DISPLAY_PARTNERS - 1;
    const remainingOTTLabel = remainingOTTCount > 0 ? REMAINING_OTT_LABEL.replace('{remainingOTTCount}', remainingOTTCount) : '';
    xstreamPartnerImages = xstreamPartnerImages.slice(0, MAX_XSTREAM_DISPLAY_PARTNERS);

    // define actionTypes
    if (tileType === TILE_TYPES.X_PARTNER_ACTIVATION_RAIL) {
        //primaryActionType = status === ONEHUB_PLAN_STATUS.PENDING_ACTIVATION ? ONEHUB_CTA_TYPES.PARTNER_ACTIVATION : ONEHUB_CTA_TYPES.BROWSE_CONTENT;

        primaryActionType = status === ONEHUB_PLAN_STATUS.PENDING_ACTIVATION ? ONEHUB_CTA_TYPES.PARTNER_ACTIVATION : status === ONEHUB_PLAN_STATUS.CLAIM_IN_PROGRESS ? ONEHUB_CTA_TYPES.WAIT_TILL_CLAIM_SUCCEEDS : ONEHUB_CTA_TYPES.BROWSE_CONTENT;;
    }
    else if (tileType === TILE_TYPES.X_PLAN_LISTING_RAIL) {
        primaryActionType = showRechargeNotification ? ONEHUB_CTA_TYPES.RECHARGE_NOTIFICATION : ONEHUB_CTA_TYPES.PLAN_PURCHASE;
        secondaryActionType = ONEHUB_CTA_TYPES.SEE_PLAN_DETAILS;
    }
    else if (tileType === TILE_TYPES.X_ACTIVE_PLAN) {
        primaryActionType = ONEHUB_CTA_TYPES.PLAN_PURCHASE;
        isRenewNowCase = (platform.toLowerCase() !== SubscriptionUtil?.PLATFORM?.IOS && planType !== SubscriptionUtil.PLAN_TYPE.FREE_TRIAL && preReminder && renewPlanId && renewPlanId !== 0 && planStatus !== SubscriptionUtil?.PLAN_STATE?.DEPRECATED) || false;
        purchaseCtaText = SUBSCRIPTION_RENEW_TXT;
        seePlanCtaText = isRenewNowCase && planListingExist ? MORE_PLANS_LABEL : VIEW_DETAILS_LABEL;
        secondaryActionType = isRenewNowCase && planListingExist ? ONEHUB_CTA_TYPES.SHOW_MORE_PLANS : ONEHUB_CTA_TYPES.SEE_PLAN_DETAILS;
    }


    let titleHeading;
    let titleSubheading;
    // modify rail props - railSubtitle
    (() => {
        let modifiedRailSubTitle = railProps.railSubtitle;
        let phoneNumber = msisdnNo?.replace('+91', '')?.trim();
        phoneNumber = `+91 ${phoneNumber}`;
        modifiedRailSubTitle = modifiedRailSubTitle?.replace('{PhoneNumber}', msisdnNo ? phoneNumber : '');
        modifiedRailSubTitle = modifiedRailSubTitle?.replace('{active_plan_amount}', activePlanAmount || 'Xstream OTTs');
        modifiedRailSubTitle = modifiedRailSubTitle?.replace('{target_cp}', preferredCp?.toUpperCase() || '');
        railProps.railSubtitle = modifiedRailSubTitle;

        titleHeading = railTitle;
        titleSubheading = railSubtitle;

        if (railType === RAIL_TYPES.X_PLAN_COMING_SOON) {
            railProps.railTitle = '';
            railProps.railSubtitle = '';
            railProps.titleLogo = '';
        }
    })();


    // upcoming tile \logic
    if(upcoming || isMobile) {
        customStyle.height = isWeb || isMobile ? '100%' : '17rem';
        customStyle.minHeight = 'auto';
    }


    return {
        planDetails,
        railType,
        tileType,
        preferredCp,
        totalContentCount,
        // selected: false,
        tilesLength,
        style,
        customStyle,
        preferredArtwork,
        hideQuickViewBanner,
        analyticsPageId,
        customClassTileLevel,
        railElementId,
        purchaseCtaText,
        seePlanCtaText,
        subscriberInfo,
        tileSubHeading,
        tileId,
        planType,
        xstreamPartnerImages,
        premiumPartnerImages,
        planTitleElement,
        planSubtitleElement,
        assetNamePrefix,
        activationStatus,
        primaryActionType,
        secondaryActionType,
        isRenewNowCase,
        xstreamPlayOTTLabel,
        remainingOTTLabel,
        planListingExist,
        zionDisplay,
        titleHeading,
        titleSubheading,
        planTitle,
        planExpiryText,
        cpDetailsById,
        cpConfigImages,
        hasPremiumPartner: !!premiumPartnerImages.length,
        ...rest,
    };
};

export default { buildTile };
