import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { matchRoutes } from 'react-router';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ROUTE_PATHS, ROUTE_STATIC_IDS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { megaMenuHoverEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import RoutingUtil from '../../../../utilities/RoutingUtil';
import DesktopHeaderNavContainerNew from '../../containers/DesktopHeaderNavContainerNew';
import {
    checkScreenHasTouch, removeMobileHover, redirectToInNewTab, addListenerToEvent, mediaForMobile, getCurrentWindowSourceName, checkWindowExist,
} from '../../../../utilities/WindowUtil';
import styles from './DesktopNavigationComponent.scss';
import LanguageProvider from '../../../../providers/LanguageProvider';
import DropDownNav from '../mega-menu/DropDownNav';
import { ELEMENT_ID } from '../../../../constants/AppConst';

const FEATURE_NAV_TYPE = {
    NAVIGATION_ITEM: {
        name: 'nav',
        listClass: 'navigate-item',
        anchorClass: 'nav-link',
    },
    SUB_NAVIGATION_ITEM: {
        name: 'sub-nav',
        listClass: 'item sub-menu-items-default common-share-dropdown-button',
        anchorClass: 'sub-nav-link',
    },
};

const SLICE_LENGTH = {
    SUB_MENU: 6,
    ONLY_MENU: 7,
};
const headerImageSettings = { 300: 100 };
class DesktopNavigationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileScreen: false,
            isSticky: false,
            stickyNavTopOffset: 0,
        };
        this.deviceUtil = props.deviceUtil;
        this.LANGUAGES = LanguageProvider();

        this.featureNavRef = React.createRef();
        this.moreDropDownRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            menuItems,
            selectedFeaturedNavigation,
            megaMenuItems,
        } = nextProps;

        let changes = {};
        if (menuItems !== prevState.menuItems
            || selectedFeaturedNavigation !== prevState.selectedFeaturedNavigation
            || megaMenuItems !== prevState.megaMenuItems
        ) {
            changes = {
                ...prevState,
                menuItems,
                selectedFeaturedNavigation,
                megaMenuItems,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidMount() {
        // disable hover state for touch devices
        if (checkScreenHasTouch()) {
            removeMobileHover();
        }
        this.updateDesktopNavHeader();
        this.initNavOffSet();
        addListenerToEvent('resize', this.updateDesktopNavHeader);
        addListenerToEvent('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const { selectedBottomNavigation } = this.props;
        if (selectedBottomNavigation !== prevProps.selectedBottomNavigation
            && selectedBottomNavigation === ROUTE_STATIC_IDS.HOME) {
            this.initNavOffSet();
        }
    }

    initNavOffSet = () => {
        if (this.featureNavRef.current) {
            this.setState({
                stickyNavTopOffset: this.featureNavRef.current.offsetTop,
            });
        }
    };

    handleScroll = () => {
        const { isSticky, stickyNavTopOffset } = this.state;

        // if not in HOME nav then no need to calculate isSticky
        if (!this.isFeatureNavVisible()) {
            this.setState({
                isSticky: false,
            });
            return;
        }
        if (this.featureNavRef.current) {
            if (stickyNavTopOffset < window.scrollY && !isSticky) {
                this.setState({
                    isSticky: true,
                });
            }
            else if (stickyNavTopOffset >= window.scrollY && isSticky) {
                this.setState({
                    isSticky: false,
                });
            }
        }
        else {
            this.setState({
                isSticky: false,
            });
        }
    };

    searchIconClicked = (e) => {
        // const LANGUAGES = LanguageProvider();
        // primaryNavClickGaEvents(LANGUAGES.MENU_TAG_SEARCH);

        const { route, location } = this.props;
        const routePresent = matchRoutes(route, location.pathname);

        if (routePresent.length > 0) {
            const currentRoute = routePresent[0].match;

            const alreadyOnSearchPage = currentRoute.path === ROUTE_PATHS.SEARCH_PAGE
                || currentRoute.path === ROUTE_PATHS.SEARCH;

            if (alreadyOnSearchPage && e) {
                e.preventDefault();
            }
        }
    };

    appHookInstallIconCLicked = (e) => {
        const { sharableContent } = this.props;
        if (e) {
            e.preventDefault();
        }
        redirectToInNewTab(sharableContent.DEEP_LINK);
    };

    updateDesktopNavHeader = () => {
        const { isMobileScreen } = this.state;
        if (mediaForMobile() && !isMobileScreen) {
            this.setState({
                isMobileScreen: true,
            });
        }
        else if (!mediaForMobile() && isMobileScreen) {
            this.setState({
                isMobileScreen: false,
            });
        }
    };

    showDesktopDesign = () => !this.deviceUtil.isMobile() || !mediaForMobile();

    isFeatureNavVisible = () => {
        const { selectedBottomNavigation } = this.props;
        return this.showDesktopDesign || selectedBottomNavigation === ROUTE_STATIC_IDS.HOME;
    };

    megaMenuItemHover = (megaMenu, itemName, hoverAnalytics) => {
        if (lodashGet(megaMenu, `[${itemName}]`, null)) {
            megaMenuHoverEvent(hoverAnalytics);
        }
    };

    getNavList = (itemList, type) => {
        if (!itemList) {
            return [];
        }
        const { selectedFeaturedNavigation, megaMenuItems } = this.props;
        return itemList.map((tab) => {
            const {
                sourceName = '', id = '', imageUrl = '',imageUrlActive = '', title = '', anchorClass = '',
                hideOnMobile = false,
            } = tab;

            const analyticsMeta = {
                asset_name: sourceName,
                action: `${sourceName}_click`,
            };

            const hoverAnalytics = {
                sourceName,
                source_name: getCurrentWindowSourceName(),
            };
            const isActive = selectedFeaturedNavigation === id;
            return (
                <li
                    key={id}
                    role="navigation item"
                    className={`${type.listClass}${isActive ? ' active' : ''} ${hideOnMobile ? 'hide-nav-mobile' : ''}`}
                >
                    <AnalyticsLinkComponent
                        meta={analyticsMeta}
                        aria-label={title}
                        role="link"
                        className={anchorClass}
                        location={itemList}
                        onClick={this.hideMoreDropDown}
                        to={RoutingUtil.getCustomLayoutUrl(tab)}
                        title={title}
                        id={`nav-${title}`}
                        onMouseEnter={() => this.megaMenuItemHover(megaMenuItems, sourceName, hoverAnalytics)}
                    >
                        {imageUrl ? (
                            <ThumborImage
                                src={isActive ? imageUrlActive : imageUrl}
                                className="navicon"
                                alt={title}
                                imageSettings={headerImageSettings}
                            />
                        ) : title}

                    </AnalyticsLinkComponent>
                    {megaMenuItems && (
                        <DropDownNav
                            menuList={megaMenuItems[sourceName]}
                            menuSrc={sourceName}
                        />
                    )}
                </li>
            );
        });
    };

    showMoreDropDown = (e) => {
        if (this.moreDropDownRef.current) {
            this.moreDropDownRef.current.classList.add('block-display');
        }
        e.stopPropagation();
        e.preventDefault();
    };

    hideMoreDropDown = (e) => {
        if (this.moreDropDownRef.current) {
            this.moreDropDownRef.current.classList.remove('block-display');
        }
        e.stopPropagation();
    };


    render() {
        const {
            selectedFeaturedNavigation, location, route, overlay, deviceUtil: { isMobile, isBot },
        } = this.props;
        let { menuItems } = this.props;
        const { isSticky, isMobileScreen } = this.state;
        menuItems = menuItems.filter(menu => !menu.notVisible);
        let sliceLength = menuItems.length;
        const showFeatureNav = this.isFeatureNavVisible();

        if (this.showDesktopDesign()) {
            if (menuItems.length <= 8) {
                sliceLength = SLICE_LENGTH.ONLY_MENU;
            }
            else {
                sliceLength = SLICE_LENGTH.SUB_MENU;
            }
        }
        const menuElements = this.getNavList(menuItems.slice(0, sliceLength), FEATURE_NAV_TYPE.NAVIGATION_ITEM);

        const subMenuElements = this.getNavList(menuItems.slice(sliceLength), FEATURE_NAV_TYPE.SUB_NAVIGATION_ITEM);
        const navProps = {
            isSticky,
            showFeatureNav,
            menuElements,
            subMenuElements,
            overlay,
            showMoreDropDown: this.showMoreDropDown,
            hideMoreDropDown: this.hideMoreDropDown,
            moreDropDownRef: this.moreDropDownRef,
            featureNavRef: this.featureNavRef,
            appHookInstallIconCLicked: this.appHookInstallIconCLicked,
        };
        if ([
            ROUTE_PATHS.BLOGS,
            ROUTE_PATHS.BLOG_DESCRIPTION,
        ].includes(location.pathname)) {
            return (
                <div />
            );
        }
        return (
            <header id={ELEMENT_ID.HEADER_CONTAINER}>

                <div className="nav-wrapper">
                    <DesktopHeaderNavContainerNew
                        selectedFeaturedNavigation={selectedFeaturedNavigation}
                        location={location}
                        route={route}
                        isMobileScreen={isMobileScreen}
                        navigationProps={navProps}
                    />
                </div>

            </header>
        );
    }
}

function mapStateToProps(state) {
    const { notify, appConfig } = state;
    return {
        overlay: notify.overlay,
        sharableContent: appConfig.sharableContent,
    };
}

DesktopNavigationComponent.defaultProps = {
    selectedFeaturedNavigation: '',
    location: '',
    selectedBottomNavigation: '',
    megaMenuItems: null,
};

DesktopNavigationComponent.propTypes = {
    route: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    menuItems: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    selectedFeaturedNavigation: PropTypes.string,
    selectedBottomNavigation: PropTypes.string,
    location: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    overlay: PropTypes.bool.isRequired,
    megaMenuItems: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    sharableContent: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default connect(mapStateToProps, {})(withDeviceUtil(withRouter(withStyles(styles)(DesktopNavigationComponent))));
