/* eslint-disable camelcase */
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import bridgeMap from '@airtel-tv/lib/bridges';
import { DEEPLINK_INGRESS_KEYS, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { AuthenticationUtil, checkWindowExist, StoreUtil } from '.';
import { localStore } from './BrowserStoreUtil';
import DeviceUtil from './DeviceUtil';
import { setIsScopedWebview } from '../../web/src/modules/app/actions/AppActions';

class ScopedWebviewUtil {
    static appIngressName;

    static setAppIngressName() {
        const searchParams = LocationUtil.parse(window.location.search);
        let platform = searchParams.utm_platform || '';
        if (!platform) {
            platform = ScopedWebviewUtil.getUtmParams()?.utm_platform || 'thanks';
        }
        this.appIngressName = platform.toLowerCase();
    }

    static setUtmParams() {
        const searchParams = LocationUtil.parse(window.location.search);

        const {
            utm_platform = '', utm_source = '', utm_medium = '', utm_cid = '',
        } = searchParams;

        if (!utm_platform) {
            return;
        }

        const utmString = JSON.stringify({
            utm_source,
            utm_platform,
            utm_medium,
            utm_cid,
        });
        localStore.set(LOCAL_STORE_KEYS.UTM_PARAMS_STRING, utmString);
    }

    static getUtmParams(appConfig) {
        const obj = {
            ...(appConfig?.utmMedium ? { utm_medium: appConfig.utmMedium } : {}),
            ...(appConfig?.utmPlatform ? { utm_platform: appConfig.utmPlatform } : {}),
            ...(appConfig?.utmSource ? { utm_source: appConfig.utmSource } : {}),
            ...(appConfig?.utmCid ? { utm_cid: appConfig.utmCid } : {}),
        };
        if (Object.keys(obj).length) {
            return obj;
        }


        const store = StoreUtil.getStore();
        const storeAppConfig = store.getState()?.appConfig;
        const appConfigObj = {
            ...(storeAppConfig?.utmMedium ? { utm_medium: storeAppConfig.utmMedium } : {}),
            ...(storeAppConfig?.utmPlatform ? { utm_platform: storeAppConfig.utmPlatform } : {}),
            ...(storeAppConfig?.utmSource ? { utm_source: storeAppConfig.utmSource } : {}),
            ...(storeAppConfig?.utmCid ? { utm_cid: storeAppConfig.utmCid } : {}),
        };
        if (Object.keys(appConfigObj).length) {
            return appConfigObj;
        }


        const string = (checkWindowExist() && localStore?.get(LOCAL_STORE_KEYS.UTM_PARAMS_STRING)) || '';
        if (string) {
            const parsedResponse = JSON.parse(string);
            store?.dispatch(setIsScopedWebview({
                isScopedWebview: true,
                utmPlatform: parsedResponse?.utm_platform,
                utmSource: parsedResponse?.utm_source,
                utmMedium: parsedResponse?.utm_medium,
                utmCid: parsedResponse?.utm_cid,
            }));
            return parsedResponse;
        }

        return null;
    }

    static navigateToDeepLinkOnCta = (deepLinkConfig, ingress) => {
        const appDeepLinkConfig = deepLinkConfig[this.appIngressName];
        let deeplink = appDeepLinkConfig[ingress];
        const fn = bridgeMap[this.appIngressName];
        const deviceUtil = DeviceUtil.getDeviceUtil();

        // put ios check here
        if (deviceUtil.isIOS()) {
            deeplink = appDeepLinkConfig[DEEPLINK_INGRESS_KEYS.IOS_POPUP];
        }
        if (!deeplink) {
            deeplink = 'https://open.airtelxstream.in/aIQnJWP8GBb';
        }
        if (fn) {
            fn(deeplink);
        }
    };

    static setWebViewTimestamp = () => {
        const loggedTimeStamp = ScopedWebviewUtil.getWebViewLogInTimeStamp();
        const currentDateObj = new Date();
        const month = currentDateObj.getMonth() < 10 ? `0${currentDateObj.getMonth() + 1}` : `${currentDateObj.getMonth() + 1}`;
        const date = currentDateObj.getDate() < 10 ? `0${currentDateObj.getDate()}` : `${currentDateObj.getDate()}`;
        const allowedTimeForDay = new Date(`${currentDateObj.getFullYear()}-${month}-${date}T23:59:59`).getTime();

        const timeDiffInhrs = Math.abs(allowedTimeForDay - loggedTimeStamp) / (1000 * 60 * 60);
        const isMoreThanOneDayDiff = timeDiffInhrs - 24;

        if (!loggedTimeStamp || (isMoreThanOneDayDiff >= 0)) {
            ScopedWebviewUtil.setWebViewLogInTimeStamp(new Date().getTime());
            ScopedWebviewUtil.resetPlaybackTimeConfig();
        }
    };

    static initScopedWebview() {
        ScopedWebviewUtil.setAppIngressName();
        ScopedWebviewUtil.setWebViewTimestamp();
        ScopedWebviewUtil.setUtmParams();
    }

    static setPlaybackTime = (watchTime) => {
        // better approach to use {UID}_TOTAL_PLAYBACK_TIME
        const playbackTimeKey = LOCAL_STORE_KEYS.TOTAL_PLAYBACK_TIME.concat(AuthenticationUtil.getUid());
        let watchedTimeInSec = localStore.get(playbackTimeKey) || 0;
        let totalWatchTime = +watchedTimeInSec;
        if (watchTime) {
            watchedTimeInSec = +watchedTimeInSec;
            totalWatchTime += watchTime;
            localStore.set(playbackTimeKey, totalWatchTime);
        }
    };

    static getPlaybackTime = () => {
        const playbackTimeKey = LOCAL_STORE_KEYS.TOTAL_PLAYBACK_TIME.concat(AuthenticationUtil.getUid());
        return +(localStore.get(playbackTimeKey) || 0);
    };

    static getWebViewLogInTimeStamp = () => {
        const webViewLogInTimeStampKey = LOCAL_STORE_KEYS.WEBVIEW_OPEN_TIMESTAMP.concat(AuthenticationUtil.getUid());
        return localStore.get(webViewLogInTimeStampKey);
    };

    static getPlaybackSoftNudgeIndex = () => {
        const playbackSoftNudgeKey = LOCAL_STORE_KEYS.PLAYBACK_SOFT_NUDGE_INDEX.concat(AuthenticationUtil.getUid());
        return +(localStore.get(playbackSoftNudgeKey) || 0);
    };

    static setPlaybackSoftNudgeIndex = (index) => {
        const playbackSoftNudgeKey = LOCAL_STORE_KEYS.PLAYBACK_SOFT_NUDGE_INDEX.concat(AuthenticationUtil.getUid());
        localStore.set(playbackSoftNudgeKey, index);
    };

    static setWebViewLogInTimeStamp = (dateLabel) => {
        const webViewLogInTimeStampKey = LOCAL_STORE_KEYS.WEBVIEW_OPEN_TIMESTAMP.concat(AuthenticationUtil.getUid());
        localStore.set(webViewLogInTimeStampKey, dateLabel);
    };

    static resetPlaybackTimeConfig = () => {
        ScopedWebviewUtil.setPlaybackSoftNudgeIndex(0);
        ScopedWebviewUtil.setPlaybackTime(0);
    };

    static canPlayPlayback = (maxAllowedPlaybackTime, userContentProperties, contentDetails) => {
        const deviceUtil = DeviceUtil.getDeviceUtil();
        const {
            oSeg, ut,
        } = userContentProperties;

        const isXppUser = !!(oSeg || '').split(',').filter(item => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;
        const isNonAirtelUser = (ut || '').toLowerCase() === 'nonairtel';
        const isBroadbandUser = (ut || '').toLowerCase() === 'broadband';
        const maxAllowedPlaybackTimeInSecs = maxAllowedPlaybackTime * 60;

        const totalPlaybackTime = ScopedWebviewUtil.getPlaybackTime();
        if (!deviceUtil.isScopedWebview() || isNonAirtelUser) {
            return true;
        }
        if (deviceUtil.isIOS()) {
            return false;
        }

        if (isXppUser || isBroadbandUser) {
            return maxAllowedPlaybackTimeInSecs > totalPlaybackTime;
        }
        return contentDetails?.free ? maxAllowedPlaybackTimeInSecs > totalPlaybackTime : false;
    };
}

export default ScopedWebviewUtil;
