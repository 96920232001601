import { useEffect } from 'react';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants';


const useSetNavigation = (params) => {
    const {
        location, getSourceName, SOSUtil, navigationObj, updateLocationInfoDispatch, source_page = null,
    } = params;
    useEffect(() => {
        const path = getSourceName(location.pathname); // Returns path
        const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS(path);
        const { currentSource = '', currentPath = '' } = navigationObj || {};
        const newSourceDict = {
            prevSource: currentSource,
            prevPath: source_page || currentPath,
            currentSource: sourceName,
            currentPath: location.pathname,
        };
        updateLocationInfoDispatch({ navigationObj: { ...newSourceDict } });
        sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_SOURCE, newSourceDict);
    }, [
        location,
    ]);

    return null;
};

export default useSetNavigation;
