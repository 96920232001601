export const getAuthConfigFromReducer = state => state.authConfig;
export const getAppConfigFromReducer = state => state.appConfig;
export const getUserConfigFromReducer = state => state.userConfig;
export const getLayoutDetailsFromReducer = state => (state ? state.layoutDetails : {});
export const getPackageContentsFromReducer = state => state.packageContents;
export const getEpgFromReducer = state => state.epg;
export const getRailsFromReducer = state => state.railsMeta;
export const getBrowserHistoryFromReducer = state => state.browserHistory;
export const getSyncContentFromReducer = state => state.syncContent;
export const getFooterDataFromReducer = state => state.footerData;
export const getDimensionPageDataFromReducer = state => state.dimensionPageData;
export const getChannelsFromReducer = state => state.channel;
export const getHappyCodeDataFromReducer = state => state.happyCode;
export const getModalConfigDataFromReducer = state => state.modalConfig;
export const getContentDetailsDataFromReducer = state => state.contentDetails;
export const getPlansOffersDataFromReducer = state => state.plansOffers;
export const getPlaybackDetailsFromReducer = state => state.playbackDetails;
export const getHomePageConfigFromReducer = state => state.homepageConfig;
export const getDistroDataConfigFromReducer = state => state.distroDetails;

export default {
    getAuthConfigFromReducer,
    getAppConfigFromReducer,
    getUserConfigFromReducer,
    getLayoutDetailsFromReducer,
    getPackageContentsFromReducer,
    getEpgFromReducer,
    getRailsFromReducer,
    getBrowserHistoryFromReducer,
    getSyncContentFromReducer,
    getFooterDataFromReducer,
    getDimensionPageDataFromReducer,
    getChannelsFromReducer,
    getHappyCodeDataFromReducer,
    getModalConfigDataFromReducer,
    getContentDetailsDataFromReducer,
    getPlansOffersDataFromReducer,
    getPlaybackDetailsFromReducer,
    getHomePageConfigFromReducer,
    getDistroDataConfigFromReducer,
};
