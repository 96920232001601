import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { hideModalComponentAction, showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { UserConfigWithAllReset } from '../../../web/src/modules/user/UserAction';
import { svodPartnerStatusService } from '@airtel-tv/services/PlansAndSubscriptionApiService';
import { MODAL_POPUP, ONEHUB_PLAN_STATUS } from '@airtel-tv/constants';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { getSourceNameFromStorage } from '../GlobalUtil';
import { DeviceTypeUtil } from '../DeviceTypeUtil';
import { LOTTIE_MAP } from '@airtel-tv/ui-lib/atoms/lottie/LottieFactory';

const SvodActivationTimer = ({
    serviceId,
    referenceId,
    cpId,
    pollingInterval = 30000,
    pollingDuration = 600000,
    timerName,
    deviceUtil,
    UserConfigWithAllResetDispatch,
    showModalComponentActionDispatch,
    hideModalComponentActionDispatch,
    cpDetailsById,
    netflixCpDetails,
    status: activationStatus,
    setFocusedElementDispatch = () => {},
    markAppTimerCompleted = () => {},
}) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const thisRef = useRef({
            isTV: DeviceTypeUtil?.isTV(),
        }).current;
    const onTimeCompletion = ({ status: activationStatus }) => {
        //Status: CLAIMED => Success UI
        //Status: CLAIM_PENDING/UNKNOWN/PENDING_ACTIVATION, Activation failed
        const cpDetails = {
            ...cpDetailsById,
            [netflixCpDetails?.cpId]: netflixCpDetails,
        }

        const imageSettings = {
            320: 250,
            500: 250,
            1000: 450,
        };

        let title, subTitle, statusClassName;
        const {
            SUBSCRIPTION_ACTIVATED_LABEL = `{cp_name} Activated`,
            SUBSCRIPTION_ACTIVATED_PARA = "Keep reactivating your subscription to continue enjoying",
            SUBSCRIPTION_ACTIVATION_FAILED_LABEL = `{cp_name} Activation Failed`,
            SUBSCRIPTION_ACTIVATION_FAILED_PARA = "Please check your account details or try again later to complete the activation.",
        } = LANGUAGE;
    
        if (activationStatus === ONEHUB_PLAN_STATUS.CLAIMED) {
            title = SUBSCRIPTION_ACTIVATED_LABEL;
            subTitle = SUBSCRIPTION_ACTIVATED_PARA;
            statusClassName = '';
        }
        else {
            title = SUBSCRIPTION_ACTIVATION_FAILED_LABEL;
            subTitle = SUBSCRIPTION_ACTIVATION_FAILED_PARA;
            statusClassName = 'failed';
        }

        const partnerDetails =  cpDetails[cpId];
        markAppTimerCompleted({timerName});
        const { currentSource, prevSource } = getSourceNameFromStorage() || {};
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.COMMON_MODAL,
            overrideCrossButtonValue: true,
            payload: {
                removeInnerPopupClass: true,
                primaryCTA: "Okay, Got it",
                overrideCrossButtonValue: true,
                onMountAction: () => {
                    UserConfigWithAllResetDispatch();
                },
                primaryAction: () => {
                    //userConfigWithLayoutResetDispatch();
                    hideModalComponentActionDispatch();
                },
                title: title.replace(`{cp_name}`, partnerDetails?.title),
                subTitle: subTitle.replace(`{title}`, partnerDetails?.title),
                imageUrl: partnerDetails?.logoUrl,
                imageSettings,
                containerClassName: `activation-success-popup ${statusClassName}`,
                backGroundCustomClass: 'popup-modal-aligner',
                popupVisibleMeta: {
                    cp_name: cpId,
                    activation_status: activationStatus,
                    source_name: currentSource,
                    source_page: prevSource,
                },
                ...(activationStatus === ONEHUB_PLAN_STATUS.CLAIMED && !thisRef.isTV ? {
                    lottieName: LOTTIE_MAP.ONEHUB_ACTIVATION_CONFETTI,
                    lottieContainerClassName: "lottie-popup-activation",
                } : {}),
                setFocusedElementDispatch,
            },
        });
    };

    useEffect(() => {
        if (!(serviceId && referenceId && cpId)) {
            return;
        }

        let time = 0;
        const intervalId = setInterval(async () => {
            let response;
            try {
                response = await svodPartnerStatusService({
                    deviceUtil,
                    data: {
                        serviceId: serviceId,
                        referenceId,
                        cpId,
                    },
                });
            }
            catch (err) {
                console.log("kkk", err);
                response = {
                    status: 'UNKNOWN',
                    serviceId,
                    referenceId,
                    cpId,
                };
            }
            
            console.log("kkk", response);

            //if (response.status === ONEHUB_PLAN_STATUS.CLAIM_IN_PROGRESS) {
            if (response.status === ONEHUB_PLAN_STATUS.CLAIMED) {
                clearInterval(intervalId);
                onTimeCompletion({
                    status: response.status,
                });
            }
            time += pollingInterval;

            if (time > pollingDuration) {
                clearInterval(intervalId);
                onTimeCompletion({
                    status: response.status,
                });
            }
        }, pollingInterval);

        // eslint-disable-next-line consistent-return
        return () => {
            clearInterval(intervalId);
        }
    }, []);

    return null;
};


const mapStateToProps = (state) => {
    const {
        appConfig,
        partnerActivationConfig = {},
    } = state;

    const { cpDetailsById = {}, netflixCpDetails = {} } = appConfig
    const { pollingInterval, pollingDuration } = partnerActivationConfig;
    return {
        cpDetailsById,
        netflixCpDetails,
        partnerActivationConfig,
        pollingInterval,
        pollingDuration
    };
};
export default withDeviceUtil(connect(mapStateToProps, {
    showModalComponentActionDispatch: showModalComponentAction,
    UserConfigWithAllResetDispatch: UserConfigWithAllReset,
    hideModalComponentActionDispatch: hideModalComponentAction,
})(SvodActivationTimer));
