import lodashGet from 'lodash/get';
import { LanguageProviderUtil } from './LanguageProviderUtil';
import { PROGRAM_TYPES, PROGRAM_TYPE_TITLE_MAPPING, ROLE_TYPES } from '@airtel-tv/constants/GlobalConst';
import { StoreUtil } from './StoreUtil';
import { DateTime } from './DateTime';
import { replaceAll } from './GlobalUtil';

export const getCDPFooterContent = ({
    programType, actorsForFooter, channelPartnerName, channelUrl, contentTitle, languageMap, genreMap, duration, downloadable, baseUrl,
}) => {
    const title = contentTitle;

    let actorLinks = [];
    let directorLinks = [];
    let languageLinks = [];
    let genreLinks = [];
    actorsForFooter.map((item, index) => {
        if ((item?.roleType || '').toLowerCase() === ROLE_TYPES.DIRECTOR) {
            directorLinks.push(`<a href=${baseUrl}${item.actorUrl}>${item.displayTitle}</a>`);
        }
        else {
            actorLinks.push(`<a href=${baseUrl}${item.actorUrl}>${item.displayTitle}</a>`);
        }
    });
    const popularActorLinks = actorLinks.slice(0, 2).join(', ');
    actorLinks = actorLinks.join(', ');
    directorLinks = directorLinks.join(', ');
    languageMap.map((item) => {
        languageLinks.push(`<a href=${baseUrl}${item.url}>${item.name}</a>`);
    });

    genreMap.map((item) => {
        genreLinks.push(`<a href=${baseUrl}${item.url}>${item.name}</a>`);
    });

    languageLinks = languageLinks.join(', ');
    genreLinks = genreLinks.join(', ');

    const channelLink = `<a href=${channelUrl}>${channelPartnerName}</a>`;
    downloadable = downloadable ? 'Yes' : 'No';

    const META_LANGUAGES = LanguageProviderUtil.MetaLanguageProvider();

    let content = META_LANGUAGES[`META_CONTENT_${programType}_DESCRIPTION`] || '';

    const contentStringMap = {
        actorLinks: actorLinks ? /{actorLinks}/g : /Cast: {actorLinks}<br>/g ,
        genreLinks: genreLinks ? /{genreLinks}/g : /Genre: {genreLinks}<br>/g,
        languageLinks: languageLinks ?  /{languageLinks}/g : /Language: {languageLinks}<br>/g,
        duration: duration ? /{duration}/g : /Duration: {duration}<br>/g,
        channelLink: channelLink ? /{channelLink}/g : /Partner Channel: {channelLink}<br>/,
        availableForDownload: downloadable ?  /{availableForDownload}/g : /Available for Download: {availableForDownload}<br>/,
        directorLinks: directorLinks ? /{directorLinks}/ : /Director: {directorLinks}<br>/,
    }

    content = content
        .replace(/{title}/g, title)
        .replace(/{popularActorLinks}/g, popularActorLinks)
        .replace(contentStringMap['actorLinks'], actorLinks)
        .replace(contentStringMap['directorLinks'], directorLinks)
        .replace(contentStringMap['channelLink'], channelLink)
        .replace(contentStringMap['availableForDownload'], downloadable)
        .replace(contentStringMap['duration'], duration)
        .replace(contentStringMap['languageLinks'], languageLinks)
        .replace(contentStringMap['genreLinks'], genreLinks);
    return content;
};

export function getSeasonTitle(seasonNum) {
    const LANGUAGES = LanguageProviderUtil.getLanguage();
    return LANGUAGES.SEASON_TITLE ? LANGUAGES.SEASON_TITLE.replace(/{seasonNum}/g, seasonNum) : '';
}

export const getMetaDescriptionGenre = ({ genreForMeta = '', actorForMeta = '' }) => {
    if (!genreForMeta || !actorForMeta) {
        return '';
    }
    const LANGUAGES = LanguageProviderUtil.getLanguage();
    return LANGUAGES.META_GENRE_MOVIE_DESCRIPTION.replace('{genres}', genreForMeta)
        .replace('{actors}', actorForMeta);
};

export const getSocialShareInfo = ({
    programType,
    showName,
    channelName,
    airDate,
    currentUrl,
    baseUrl,
    shortDesc,
    shareShortUrl = '',
}) => {
    let emailShareText = '';
    let date = '';
    const pageUrl = shareShortUrl || (baseUrl + currentUrl);
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const store = StoreUtil.getStore();
    const sharableContent = lodashGet(store.getState(), 'appConfig.sharableContent', {});
    switch (programType) {
        case PROGRAM_TYPES.LIVETVSHOW:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY_CATCHUP_SHOW
                .replace(/{showName}/g, showName)
                .replace(/{channelName}/g, channelName);
            break;
        case PROGRAM_TYPES.EPISODE:
            if (channelName) {
                date = new DateTime(airDate).format('DD MMMM YYYY');
                emailShareText = LANGUAGE.CONTENT_SHARE_BODY_CATCHUP_EPISODE
                    .replace(/{showName}/g, showName)
                    .replace(/{channelName}/g, channelName)
                    .replace(/{date}/g, date);
            }
            else {
                emailShareText = LANGUAGE.CONTENT_SHARE_BODY
                    .replace(/{title}/g, showName);
            }
            break;
        case PROGRAM_TYPES.LIVETVCHANNEL:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY
                .replace(/{title}/g, channelName);
            break;
        case PROGRAM_TYPES.PACKAGE:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY_CHANNEL_PARTNER
                .replace(/{channelName}/g, channelName)
                .replace(/{channelUrl}/g, pageUrl)
                .replace(/{shortDesc}/g, shortDesc);
            break;
        default:
            emailShareText = LANGUAGE.CONTENT_SHARE_BODY
                .replace(/{title}/g, showName);
    }
    const emailShareBody = emailShareText.replace(/{documentUrl}/g, pageUrl);
    const facebookUrl = `${sharableContent.FB_LINK}?u=${pageUrl}&text=${emailShareText.replace(/{documentUrl}/g, '')}`;
    const twitterUrl = `${
        sharableContent.TWITTER_LINK
    }?url=${pageUrl}&text=${emailShareText.replace(/{documentUrl}/g, '')}&hashtags=AirtelTv`;
    const whatsAppUrl = `${sharableContent.WHATSAPP_LINK}?text=${emailShareText.replace(/{documentUrl}/g, pageUrl)}`;
    const windowsShareText = emailShareText.replace(/{documentUrl}/g, '');
    return {
        emailShareBody,
        facebookUrl,
        twitterUrl,
        whatsAppUrl,
        windowsShareText,
    };
};
export const getLangfromCode = ({ lang = '' }) => LanguageProviderUtil.getLanguageCodes()[lang];

export const getPageSeoMeta = ({
    programType,
    contentTitle,
    contentDescription,
    tvShowDetails = {
        showName: '',
        seasonNumber: '',
        episodeNumber: '',
        episodeName: '',
        channelId: '',
        channelName: '',
        episodeDate: '',
    },
    genreForMeta = '',
    actorForMeta = '',
    releaseYear = '',
}) => {
    const LANGUAGES = LanguageProviderUtil.getLanguage();

    let title = contentTitle;
    let description = contentDescription;
    let keywords = '';

    const {
        showName,
        seasonNumber,
        episodeNumber,
        episodeName,
        channelId,
        channelName,
        episodeDate,
    } = tvShowDetails;

    switch (programType) {
        case PROGRAM_TYPES.LIVETVCHANNEL: {
            title = LANGUAGES.META_TITLE_LIVE
                .replace(/{title}/g, contentTitle);

            description = LANGUAGES.META_LIVE_DESCRIPTION.replace(/{title}/g, contentTitle);

            keywords = LANGUAGES.META_LIVE_KEYWORDS.replace(/{title}/g, contentTitle);
            break;
        }
        case PROGRAM_TYPES.EPISODE:

        // has channel id : yes then it is a live tv episode
        {
            if (!channelId) {
                title = LANGUAGES.META_EPISODE_TITLE
                    .replace(/{showName}/g, showName)
                    .replace(/{seasonNum}/g, seasonNumber)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeName}/g, episodeName);

                description = LANGUAGES.META_EPISODE_DESCRIPTION
                    .replace(/{showName}/g, showName)
                    .replace(/{seasonNum}/g, seasonNumber)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeName}/g, episodeName);

                keywords = LANGUAGES.META_EPISODE_KEYWORDS
                    .replace(/{episodeName}/g, episodeName);
            }
            else {
                title = LANGUAGES.META_CATCHUP_EPISODE_TITLE
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{channelName}/g, channelName)
                    .replace(/{episodeNum}/g, episodeNumber)
                    .replace(/{episodeDate}/g, episodeDate);


                description = LANGUAGES.META_CATCHUP_EPISODE_DESCRIPTION
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{episodeDate}/g, episodeDate);

                description = LANGUAGES.META_CATCHUP_EPISODE_KEYWORDS
                    .replace(/{showName}/g, contentTitle)
                    .replace(/{episodeDate}/g, episodeDate);
            }


            break;
        }
        case PROGRAM_TYPES.SEASON: {
            title = LANGUAGES.META_SEASON_TITLE
                .replace(/{showName}/g, showName)
                .replace(/{seasonNum}/g, seasonNumber);

            description = LANGUAGES.META_SEASON_DESCRIPTION
                .replace(/{showName}/g, showName)
                .replace(/{seasonNum}/g, seasonNumber);


            break;
        }

        case PROGRAM_TYPES.TVSHOW: {
            title = LANGUAGES.META_TV_SHOW_SEO_TITLE
                .replace(/{showName}/g, showName);

            description = LANGUAGES.META_TV_SHOW_SEO_DESCRIPTION
                .replace(/{showName}/g, showName)
                .replace(/{channelName}/g, channelName);

            keywords = LANGUAGES.META_TV_SHOW_KEYWORDS
                .replace(/{showName}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.LIVETVSHOW: {
            title = LANGUAGES.META_CATCHUP_SHOW_TITLE
                .replace(/{showName}/g, contentTitle)
                .replace(/{channelName}/g, channelName);

            description = LANGUAGES.META_CATCHUP_SHOW_DESCRIPTION
                .replace(/{showName}/g, contentTitle);

            keywords = LANGUAGES.META_CATCHUP_SHOW_KEYWORDS
                .replace(/{showName}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.LIVETVMOVIE: {
            title = LANGUAGES.META_CATCHUP_MOVIE_TITLE
                .replace(/{title}/g, contentTitle)
                .replace(/{channelName}/g, channelName);

            description = LANGUAGES.META_CATCHUP_MOVIE_DESCRPTION
                .replace(/{title}/g, contentTitle);

            break;
        }
        case PROGRAM_TYPES.MOVIE: {
            const modifiedTitle = releaseYear ? `${contentTitle} ${releaseYear}` : contentTitle;
            title = LANGUAGES.META_MOVIES_SEO_TITLE.replace('{title}', modifiedTitle);
            description = replaceAll(LANGUAGES.META_MOVIES_SEO_DESCRIPTION, '{title}', contentTitle) + getMetaDescriptionGenre({
                genreForMeta,
                actorForMeta,
            });
            keywords = replaceAll(LANGUAGES.META_MOVIE_KEYWORDS, '{title}', contentTitle);
            break;
        }


        default:
            title = LANGUAGES.META_TITLE.replace('{title}', contentTitle).replace(
                '{type}',
                PROGRAM_TYPE_TITLE_MAPPING[programType] || '',
            );
            description = replaceAll(LANGUAGES.META_MOVIES_DESCRIPTION, '{title}', contentTitle);
            keywords = replaceAll(LANGUAGES.META_MOVIE_KEYWORDS, '{title}', contentTitle);
    }

    return {
        title,
        description,
        keywords,
    };
};
