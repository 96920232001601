import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import CryptoUtil from '@airtel-tv/utils/CryptoUtil';
import EventModel from '@airtel-tv/analytics/EventModel';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';

let analytics = null;

// Initialize Firebase
const initializeFirebase = ({ config }) => {
    if (!checkWindowExist()) {
        return;
    }
    try {
        let configDecoded = config;
        if (typeof config === 'string') {
            configDecoded = CryptoUtil.AESDecrypt({
                key: 'firebase',
                value: configDecoded,
            });
            configDecoded = JSON.parse(configDecoded);
        }
        //method to encode firebase config
        // const configEncoded = CryptoUtil.AESEncrypt({
        //     key: 'firebase',
        //     value: JSON.stringify(config),
        // });
        // console.log('configEncoded', configEncoded);

        const app = initializeApp(configDecoded);
        analytics = getAnalytics(app);
    }
    catch (e) {
        console.log(e);
    }
};

const logFireBaseEvent = ({ event, params }) => {
    if (analytics) {
        const { os = '', appid = 'WEB' } = EventModel;
        const fbModifiedEventName = `${event}_${appid}`;
        logEvent(analytics, fbModifiedEventName, {
            ...params,
            osType: os,
            appid,
        });
    }
};

export {
    initializeFirebase,
    logFireBaseEvent,
};
