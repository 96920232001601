/* eslint-disable import/no-extraneous-dependencies */
import lodashGet from 'lodash/get';
import React from 'react';
import cookie from 'cookie';
import axios from 'axios';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import {
    PROGRAM_TYPE_CATGEGORY_MAP, urlToSourceNameMapping, DOWNLOAD_APP_ACTIONS, LOCAL_STORE_KEYS, PROGRAM_TYPES, TEMPLATE_TO_SOURCE_MAPPING, AIRTEL_USER_TYPES,
    CP_CLAIM_STATUS,
    ONEHUB_INGRESS_INTENT,
} from '@airtel-tv/constants/GlobalConst';
import {
    TILE_LAZY_TILE_LIMIT_TYPE_MAP, LAZY_TILE_LIMIT_PORTRAIT, TILES_LIMIT, RAIL_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import { NETWORK_QUALITY } from '@airtel-tv/constants/NetworkConst';
import { BROWSER_LIST, OS_LIST } from '@airtel-tv/constants/BrowserConst';
import DEVICES from '@airtel-tv/constants/DevicesConst';
import { _ROUTE_PATHS } from '@airtel-tv/constants/RouteConsts';
import { matchRoutes } from 'react-router';
import { SOS_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import lodashCamelCase from 'lodash/camelCase';
import lodashStartCase from 'lodash/startCase';
import { CDP_RAIL_MOCK } from '@airtel-feature/layout/sagas/LayoutMock';
import { AppEnv } from './GetEnv';
import {
    checkWindowExist, decodeBase64, getCurrentWindowSourceName, getFontRootSize, redirectToInNewTab,
} from './WindowUtil';
import { DateTime } from './DateTime';
import { DeviceTypeUtil } from './DeviceTypeUtil';
import browserStore, { sessionStore } from './BrowserStoreUtil';
import { StoreUtil } from './StoreUtil';
import { downloadAppItunesGaEvent, downloadAppMobileHeaderGaEvent, downloadAppPlayStoreGaEvent } from './GaEvents';
import { LanguageProviderUtil } from './LanguageProviderUtil';
import { RoutingUtil } from './RoutingUtil';
import LocationUtil from './LocationUtil';

let allRoutes = () => [];
const initializeAllRoutes = async () => {
    allRoutes = DeviceTypeUtil?.isWeb()
        ? await import('../../web/src/routes/routes').then(res => res.default)
        : await import('../../tv/src/routes/routes').then(res => res.default);
};

initializeAllRoutes();

export function secondToHours(seconds) {
    if (!seconds || typeof seconds !== 'number') {
        return 0;
    }
    return seconds / 3600; // eslint-disable-line no-mixed-operators
}

export function secondToMinutes(seconds) {
    if (!seconds || typeof seconds !== 'number') {
        return 0;
    }
    return seconds % 3600 / 60;// eslint-disable-line no-mixed-operators
}

export const filterPlans = (subscribedPlans) => {
    const plans = {
        false: [],
        true: [],
    };
    subscribedPlans.forEach((plan) => {
        if (plan.meta && plan.meta.offerType === 'CLAIM_MOBILEPACK') {
            plans.true.push(plan);
        }
        else {
            plans.false.push(plan);
        }
    });
    return plans;
};


const shouldDisplay = (plan) => {
    if (
        plan.meta
      && typeof plan.meta.displayUnderSubscribedPack !== 'undefined'
      && plan.meta.displayUnderSubscribedPack === false
    ) {
        return false;
    }
    return true;
};

export function filterSubscribedPlans(subscribedPlans) {
    if (!subscribedPlans) {
        return [];
    }
    const filteredPlans = subscribedPlans.filter(plan => shouldDisplay(plan) && !plan.notVisible);
    return filteredPlans || [];
}


export const findSelectedCP = (subscribedPlans, cpId, subscriptionType, planID) => {
    if (subscriptionType) {
        return subscribedPlans.find(plan => plan?.cpId?.startsWith(cpId) || cpId?.startsWith(plan?.cpId));
    }
    return subscribedPlans.find(plan => (plan?.cpId?.startsWith(cpId) || cpId?.startsWith(plan?.cpId)) && plan?.plan?.planId === parseInt(planID));
};

export const findSelectedPlan = (subscribedPlans, cpId, subscriptionType, planID) => subscribedPlans.find(plan => (plan?.planID == planID));

export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const getUniqueId = () => {
    const random = getRandomInt(0, 100000000);
    return `${random}-${new Date().getTime()}`;
};

export function getDate(date = new Date(), format = 'YYYY-MM-DD HH:mm:ss') {
    // return moment(date).format(format);
    return new DateTime(date).format(format);
}

export const isObjEmpty = (obj) => {
    let isEmpty = false;
    const type = typeof obj;

    isEmpty = isEmpty || !obj;
    isEmpty = isEmpty || (type === 'undefined'); // if it is undefined
    isEmpty = isEmpty || (obj === null); // if it is null
    isEmpty = isEmpty || (type === 'string' && (obj === '')); // if the string is empty
    isEmpty = isEmpty || (obj === false || obj === 0); // if boolean value returns false
    isEmpty = isEmpty || (Array.isArray(obj) && obj.length === 0); // if array is empty
    isEmpty = isEmpty || (type === 'object' && Object.keys(obj).length === 0); // if object is empty

    return isEmpty;
};

export const characterToUpperCase = (text) => {
    const arr = text?.split(' ') || [];
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    const str2 = arr.join(' ');
    return str2;
};

export function getSid(sid, response) {
    const redirectionUrl = lodashGet(response, 'data.redirectionUrl', '');
    const lodashSid = lodashGet(response, 'data.sid', '');
    return sid || lodashSid || (redirectionUrl && redirectionUrl.split('payment-option/')[1].split('/')[0]);
}

export const getUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0; // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-bitwise, no-mixed-operators
    return v.toString(16);
});

export const getRandomNumber = (maxLimit = 10000000000) => parseInt(Math.random() * maxLimit, 10);

export const getAppVersion = () => {
    const { maj, min, patch } = AppEnv.getEnv()?.appVersion;
    return `${maj}.${min}.${patch}`;
};

export const removeConsecutiveRepeatedChar = (target, char) => target.filter((item, pos, arr) => pos === 0 || (arr[pos - 1] !== char && char) || item !== arr[pos - 1]).join('');

export const encodeForUrl = (value) => {
    if (checkWindowExist() && window.encodeURIComponent) {
        return window.encodeURIComponent(value);
    }
    return value;
};


export const trimCaptionsForUrl = (targetString = '', separator = '-', defaultString = 'default') => {
    const specialCharacters = [
        '!',
        '"',
        '#',
        '$',
        ' % ',
        ' & ',
        "'",
        '(',
        ')',
        '*',
        '+',
        ',',
        '\\-',
        '.',
        '/',
        ':',
        ';',
        '<',
        '=',
        '>',
        '?',
        '@',
        '\\[',
        '\\]',
        '^',
        '_',
        '`',
        '{',
        '|',
        '}',
        '~',
        "'",
        '–',
    ];

    const regexEscapeCharacters = new RegExp(`[${specialCharacters.join('|')}]`);

    let encoded = encodeForUrl(
        removeConsecutiveRepeatedChar(
            targetString
                .trim()
                .toLowerCase()
                // .split((/\.|\/| |\(|\)|:|,|!|+|,|'|<|>|#|%|{|}|^|~|[|]|$|_|(|)|;|?|@|=|/))
                .split(regexEscapeCharacters) // src: https://stackoverflow.com/a/25266512/4221433
                .join(separator)
                .split(''),
            separator,
        ),
    ) || defaultString;
    if (encoded[encoded.length - 1] === separator) {
        encoded = encoded.slice(0, encoded.length - 1); // remove last '-'
    }
    if (encoded[0] === separator) {
        encoded = encoded.slice(1, encoded.length); // remove first '-'
    }
    return encoded;
};

export const getRouteKey = value => Object.keys(ROUTE_PATHS).find(key => ROUTE_PATHS[key] === value);

export const getTimeStamp = (date = new Date()) => date.getTime();

export const getAppId = () => AppEnv.getEnv()?.appId;

export const getShowTime = timestamp => new DateTime(timestamp).format('hh:mm a');

export function removeFalsy(obj) {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== '') {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
}

export const getTimeLeft = (endTime) => {
    const now = new Date().getTime();
    const timeLeft = endTime - now;

    if (timeLeft <= 0) {
        return 'The show has ended.';
    }

    const minutesLeft = Math.floor(timeLeft / 1000 / 60);

    if (minutesLeft >= 60) {
        const hoursLeft = Math.floor(minutesLeft / 60);
        const remainingMinutes = minutesLeft % 60;
        return `${hoursLeft} hours and ${remainingMinutes} minutes left`;
    }
    return `${minutesLeft} minutes left`;
};

export function objectToArray(obj) {
    return Array.from(Object.keys(obj), k => obj[k]);
}

// Find language rn from languageFilters key in appConfig
export const findLanguageFromCode = (code, languageFilters) => {
    const key = Object.keys(languageFilters).find(lan => lan === code);
    return key && languageFilters[key].rn ? languageFilters[key].rn : null;
};

export const cookieStringToObject = cookieString => cookie.parse(cookieString);

export const parseLiveTvCooke = (cookieString) => {
    if (cookieString) {
        const cookies = cookieStringToObject(cookieString);
        const playbackUrlCookies = cookies;
        let cloudFrontPolicy = cookies['CloudFront-Policy'];
        if (cloudFrontPolicy) {
            cloudFrontPolicy = replaceAll(cloudFrontPolicy, '_', '=');

            const decodedCloudFrontPolicy = decodeBase64(cloudFrontPolicy);
            const cloudFrontPolicyJSON = convertToJSON(decodedCloudFrontPolicy);
            const cookieExpire = lodashGet(cloudFrontPolicyJSON, 'Statement[0].Condition.DateLessThan.AWS:EpochTime', Infinity);

            return {
                cookieExpire,
                playbackUrlCookies,
            };
        }
    }

    return {
        cookieExpire: null,
        playbackUrlCookies: null,
    };
};

const nth = function (d) {
    if (d > 3 && d < 21) {
        return 'th';
    }
    switch (d % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

export const getDateWithTitle = (timestamp, format, removeYear = false) => {
    const fortnightAway = new Date(timestamp);
    const date = fortnightAway.getDate();
    const month = fortnightAway.toLocaleString('en', { month: format });
    return `${date}${nth(date)} ${month} ${removeYear ? '' : fortnightAway.getFullYear()}`;
};

export const getContentType = ({ contentDetails }) => {
    const { programType, channelId } = contentDetails;
    if (channelId && programType === PROGRAM_TYPES.EPISODE) {
        return PROGRAM_TYPES_CATEGORY.CATCHUP;
    }
    return PROGRAM_TYPE_CATGEGORY_MAP[programType];
};

export const isPlatformSupported = (browser, os) => {
    if (browser === BROWSER_LIST.SAFARI || os === OS_LIST.IOS) {
        return false;
    }
    return true;
};

export const parseInfinityValuesObject = (key, val) => {
    if (val === 'Infinity') {
        return Infinity;
    }
    if (val === '-Infinity') {
        return -Infinity;
    }
    return val;
};

export const stringifyInfinityValuesObject = (key, val) => {
    if (val === Infinity) {
        return 'Infinity';
    }
    if (val === -Infinity) {
        return '-Infinity';
    }
    return val;
};

export const convertToJSON = (value) => {
    try {
        return JSON.parse(value);
    }
    catch (error) {
        return value;
    }
};

export const decryptSecretKey = ({ secretKey, authToken }) => {
    if (!secretKey || !authToken) {
        return null;
    }


    const decryptionKey = authToken.slice(0, 16);

    const decryptedKey = CryptoUtil.AESDecrypt({
        key: decryptionKey,
        value: secretKey,
    });

    return decryptedKey;
};

export function getFullScreen(docElm) {
    if (!docElm) {
        return;
    }
    if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
    }
    else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
    }
    else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
    }
    else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
    }
}

export function secondsTohhmmss(secs) {
    const playerTime = new DateTime('2015-01-01');
    playerTime.addUTCSeconds(secs);
    return playerTime.format('H:mm:ss');
}

export const subtractSmallFromBig = (n, n2) => {
    if (n > n2) {
        return n - n2;
    }

    return n2 - n;
};

export const getHostFromUrl = (url = '') => {
    const arr = url.split('/');
    if (arr.length > 2) {
        return `${arr[0]}//${arr[2]}`;
    }
    return null;
};

export const getPlaySessionId = () => {
    const uniqueId = getUniqueId();
    const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META) || {};
    const { userSessionId = '' } = analyticsMeta;
    // const userSessionId = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_USER_SESSION_ID);
    return `${userSessionId}-${uniqueId}`;
};

export const calculateProgressPercentage = ({
    continueWatchingData,
    id,
}) => {
    let progressPercentage = 0;
    if (continueWatchingData && continueWatchingData[id]) {
        const { contentResponse: { durSec }, lastWatchedPosition } = continueWatchingData[id];

        if (lastWatchedPosition && durSec) {
            progressPercentage = (lastWatchedPosition / durSec) * 100;
        }
    }

    return progressPercentage;
};

export const getPrefferedPartnerList = (prefferedPartnerPlan) => {
    const list = (prefferedPartnerPlan.length > 0 && prefferedPartnerPlan[0] && prefferedPartnerPlan[0].offers) || [];
    const prefferedPartnerPlanList = list.length > 0 ? list.map(a => a.cp) : [];
    return prefferedPartnerPlanList;
};

export const firstLetterUpper = (str) => {
    if (!str || str.length < 1) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toTitleCase = str => lodashStartCase(lodashCamelCase(str));
export const getStartTime = (date = new Date(), timeGap = 0) => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(tempDate.getHours() - timeGap);
    tempDate.setMinutes(0);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);
    return tempDate.getTime();
};

export const getEndTime = (date = new Date(), timeGap = 1) => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(tempDate.getHours() + timeGap);
    tempDate.setMinutes(0);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);
    return tempDate.getTime();
};

export const createMockEPG = (epgList, epgStartTime, epgEndTime) => {
    const finalEpglist = [];

    let currentStartTime = epgStartTime;
    if (epgList.length) {
        currentStartTime = epgList[0].startTime < currentStartTime ? epgList[0].startTime : currentStartTime;
    }


    for (let index = 0; index < epgList.length;) {
        const { startTime, endTime } = epgList[index];

        const result = { ...epgList[index] };

        if (currentStartTime < startTime) {
            result.startTime = currentStartTime;
            result.endTime = startTime;
            result.mocked = true;
        }
        else {
            result.startTime = startTime;
            result.endTime = endTime;
            index += 1;
        }

        currentStartTime = result.endTime;

        finalEpglist.push(result);
    }

    if (
        finalEpglist
        && finalEpglist.length >= 1
        && finalEpglist[finalEpglist.length - 1].endTime < epgEndTime
    ) {
        finalEpglist.push({
            mocked: true,
            startTime: finalEpglist[finalEpglist.length - 1].endTime,
            endTime: epgEndTime,
        });
    }
    else if (finalEpglist.length < 1) {
        finalEpglist.push({
            mocked: true,
            startTime: epgStartTime,
            endTime: epgEndTime,
        });
    }

    return finalEpglist;
};


export const replaceAll = (targetString, search, replacement) => targetString.replace(new RegExp(search, 'g'), replacement);


export const getCurrentProgram = (programs, time = getTimeStamp()) => {
    const program = programs.find(item => item.startTime <= time && item.endTime >= time);

    return program || null;
};


export function secondsTohhhmmm(secs) {
    const durationHour = Math.floor(secondToHours(secs));
    const durationMin = Math.floor(secondToMinutes(secs));
    if (durationHour === 0 && durationMin === 0) {
        return (secs ? `${secs}s` : '');
    }
    return `${durationHour}h ${durationMin}m`;
}

export function secondToMinutesSecond(seconds) {
    // return value of seconds after remaining after minute
    if (!seconds || typeof seconds !== 'number') {
        return 0;
    }
    return seconds < 60 ? seconds : (seconds % 60);
}
export function secondsToContentDuration(seconds) {
    if (!seconds || typeof seconds !== 'number') {
        return '';
    }
    const durationSeconds = Math.floor(secondToMinutesSecond(seconds));
    const durationMin = Math.floor(secondToMinutes(seconds));
    return `${durationMin || ''} ${durationMin && durationSeconds ? ' : ' : ''}${durationSeconds || ''} ${durationMin ? `${durationMin === 1 ? 'min' : 'mins'} ` : 'secs'}`;
}

export function filterXtreamPremium(subscribedPlans) {
    if (!subscribedPlans) {
        return [];
    }
    const xStreamPremiumPlan = subscribedPlans.filter(plan => plan.meta && plan.meta.offerType === 'PREMIUM' && !plan.notVisible);
    return xStreamPremiumPlan || [];
}

export const getAnalyticsSessionMeta = contentId => ({
    contentId,
    userSessionId: getUniqueId(),
});

export const getStitchMeta = () => ({
    stitchId: getUniqueId(),
});

export const setAnalyticsMeta = (contentId) => {
    const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
    let meta;
    if (!analyticsMeta) {
        meta = getAnalyticsSessionMeta(contentId);
        sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_META, meta);
    }
    else {
        const { contentId: sessionContentId } = analyticsMeta;
        meta = analyticsMeta;
        if (sessionContentId !== contentId) {
            meta = getAnalyticsSessionMeta(contentId);
            sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_META, meta);
        }
    }
    return meta;
};

export const setStitchId = () => {
    const stitchMeta = getStitchMeta();
    sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_STITCH_META, stitchMeta);
    return stitchMeta.stitchId;
};

export const markStitchKeyConsumed = () => {
    let stitchMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_STITCH_META);
    if (!stitchMeta || stitchMeta?.consumed) {
        // Check flow here
        return;
    }
    stitchMeta = {
        ...stitchMeta,
        consumed: true,
    };
    sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_STITCH_META, stitchMeta);
};

export const readStitchId = (resetOnConsumed = true) => {
    const stitchMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_STITCH_META);
    let stitchId;
    if (!stitchMeta) {
        stitchId = setStitchId();
        return stitchId;
    }
    stitchId = stitchMeta?.stitchId;
    if (resetOnConsumed && stitchMeta?.consumed) {
        stitchId = setStitchId();
    }
    return stitchId;
};

export function getSourceName(value, locn) {
    if (value === '/subscribe/landing/offers') {
        let sn = 'fmf_si_page';
        if (locn?.search) {
            const queryParams = LocationUtil.getQueryParams({ location: locn });
            let { templateId, flowId } = queryParams;
            if (!flowId) {
                flowId = templateId;
            }
            sn = TEMPLATE_TO_SOURCE_MAPPING[flowId] || sn;
        }
        return sn;
    }
    const pathName = value.split('/')[1];
    return urlToSourceNameMapping[pathName] || (pathName === '' ? 'home' : pathName);
}


export const updateLionsgateCPTrailerURL = (trailerUrl, cpId) => {
    if (cpId === 'LIONSGATEPLAY') {
        const strToReplace = 'http://li-jit-cdn-lb.lionsgateplay.com';
        const newStr = 'https://li-jit-cdn-lb-ssl.akamaized.net';
        if (trailerUrl.indexOf(strToReplace) > -1) {
            return trailerUrl.replace(strToReplace, newStr);
        }
        return trailerUrl;
    }
    return trailerUrl;
};

export const getTrailerPlaybackDetails = (trailerRefId, trailerUrl) => ({
    [`${trailerRefId}`]: {
        playback: {
            playUrl: trailerUrl,
        },
        contentId: trailerRefId,
        contentType: 'TRAILER',
    },
});

export const getAudioLanguages = ({ languageMap, contentDetails: { languages } }, openPopUp) => {
    const langArray = [];
    languageMap.forEach((lang) => {
        if (languages && languages.includes(lang.lan.toLowerCase())) {
            langArray.push(lang.n);
        }
    });
    return (
        <>
            { langArray.slice(0, 3).map((lang, i) => (
                <>
                    <span>{lang}</span>
                    {langArray.length > 1 && i >= 0 && i < 2 && (
                        <span className="comma-saperator">
                            ,
                            {' '}
                        </span>
                    )}
                </>
            )) }
            {langArray.length > 3 && (
                <span
                    className="extra-lang"
                    onClick={openPopUp}
                >
                    {' '}
                    +
                    {langArray.length - 3}
                    {' '}
                    more
                </span>
            )}
        </>
    );
};
export const getLatestChromeBrowser = () => {
    const LANGUAGES = LanguageProviderUtil.getLanguage();
    const OFFLINELANGUAGES = LanguageProviderUtil.getOfflineLanguage();
    const chromeVersion = LANGUAGES.CHROME_LATEST_VERSION ? LANGUAGES.CHROME_LATEST_VERSION : OFFLINELANGUAGES.CHROME_LATEST_VERSION;
    return +chromeVersion;
};

export const getPlaceholderFromLocal = (key) => {
    const OFFLINELANGUAGES = LanguageProviderUtil.getOfflineLanguage();
    return OFFLINELANGUAGES[key];
};

export const onInstallClick = ({ clickItem, isPlayerError = false }) => {
    // const isMobile = DeviceUtil.isMobile();

    const store = StoreUtil.getStore();
    const refLinks = lodashGet(store.getState(), 'appConfig.refLinks', {});
    const sharableContent = lodashGet(store.getState(), 'appConfig.sharableContent', {});
    switch (clickItem) {
        case DOWNLOAD_APP_ACTIONS.COMMON_APP_DOWNLOAD:
        case DOWNLOAD_APP_ACTIONS.CP_UNSUPPORTED_REDIRECT:

            // if (isMobile) {
            //     redirectTo(SHARABLE_CONTENT.DOWNLOAD_APP_DEEP_LINK);
            // }
            //  else {
            redirectToInNewTab(sharableContent.DOWNLOAD_APP_DEEP_LINK);
            //  }

            if (!isPlayerError) {
                downloadAppMobileHeaderGaEvent();
            }

            // if (isPlayerError) {
            //     downloadAppPlayBackErrorGaEvent();
            // }
            // else {
            //     downloadAppMobileHeaderGaEvent();
            // }
            break;

        case DOWNLOAD_APP_ACTIONS.DOWNLOAD_APP_STORE:
            // if (isMobile) {
            //     redirectTo(REF_LINKS.ITUNES_LINK);
            // }
            // else {
            redirectToInNewTab(refLinks.ITUNES_LINK);
            // }
            downloadAppItunesGaEvent();
            break;

        case DOWNLOAD_APP_ACTIONS.DOWNLOAD_PLAY_STORE:
            redirectToInNewTab(refLinks.PLAY_STORE_LINK);
            downloadAppPlayStoreGaEvent();
            break;
        default:
    }
};

export const getXStreamDetail = subscribedPlans => (subscribedPlans || []).find(plans => plans.meta && plans.meta.offerType === 'PREMIUM');

export const findTileLimit = ({
    windowWidth,
    tileType,
}) => {
    const tileMap = TILE_LAZY_TILE_LIMIT_TYPE_MAP[tileType] || LAZY_TILE_LIMIT_PORTRAIT;
    const widthKey = Object.keys(tileMap);
    for (let i = 0; i < widthKey.length; i++) {
        const mapWidth = widthKey[i];
        if (mapWidth > windowWidth) {
            return tileMap[mapWidth];
        }
    }

    return TILES_LIMIT;
};

export const getPendingActivationUrl = ({ deepLink, planClaimStatusClient, template = '' }) => {
    if (deepLink.includes(template)) {
        const parsingDeepLink = deepLink.split(template);
        if (parsingDeepLink.length > 1) {
            const { PENDING_ACTIVATION = [] } = planClaimStatusClient || {};
            const oneCpHavingMultiplePendingActivation = [];
            PENDING_ACTIVATION.filter((item) => {
                const cpId = `${item?.partner?.cpId}`;
                if (`/${cpId}` === parsingDeepLink[1]) {
                    return oneCpHavingMultiplePendingActivation.push(cpId);
                }
                return false;
            });
            const redirectionUrl = oneCpHavingMultiplePendingActivation.length > 1 ? RoutingUtil.getOneHubPageCDP({
                ingressIntent: ONEHUB_INGRESS_INTENT.ACTIVATE,
                cpId: oneCpHavingMultiplePendingActivation?.[0],
            }) : RoutingUtil.getCPActivationPage({ cpId: oneCpHavingMultiplePendingActivation?.[0] });
            return redirectionUrl;
        }
    };
    return '';
};

export const deepLinkParser = (deepLink, { planClaimStatusClient = {} } = {}) => {
    // const deepLink = 'xstream://airtelxstream.com?type=PLAYBACK&contentId=LIONSGATEPLAY_MOVIE_ABOUTMYFATHERY2023M'
    // const deepLink = 'xstream://LISTING/MOVIE/axsta_ctzx31941633753728372/MOVIE_NOLOGO';
    // const deepLink = 'xstream://tv-shows/taarak-mehta-ka-ooltah-chashmah/season-10/SONYLIV_VOD_TVSHOW_1700000084_SEASON_10?type=CDP';
    if (deepLink?.toLowerCase().startsWith('wynkpremiere://')) { // for web and mweb wynkpremiere:// is used
        if (deepLink.toLowerCase().includes('wynkpremiere://Landing/page'.toLowerCase())) {
            const parsingDeepLink = deepLink.split('/');
            let url = '';
            if (parsingDeepLink[parsingDeepLink.length - 1] === 'q') {
                url = parsingDeepLink.slice(1, parsingDeepLink.length - 1).join('/');
                return url;
            }

            url = parsingDeepLink.slice(1, parsingDeepLink.length).join('/');
            return url;
        }
        if (deepLink.includes('wynkpremiere://LISTING')) {
            const parsingDeepLink = deepLink.split('/');
            if (parsingDeepLink[parsingDeepLink.length - 1] === 'q') {
                return `/${parsingDeepLink[3]}/list/${parsingDeepLink[parsingDeepLink.length - 3]}?type=p&titleL2=y&viewType=webview`;
            }
            return `/${parsingDeepLink[3]}/list/${parsingDeepLink[parsingDeepLink.length - 2]}?type=p&titleL2=y`;
        }
        if (deepLink.includes('wynkpremiere://popup')) {
            const parsingDeepLink = deepLink.split('/');
            return `/${parsingDeepLink[parsingDeepLink.length - 1]}`;
        }
        if (deepLink.includes('wynkpremiere://onehub')) {
            if (deepLink.includes('wynkpremiere://onehub/activate')) {
                if (Object.keys(planClaimStatusClient).length) {
                    // Called directly in render from FeaturedSlider
                    return getPendingActivationUrl({ deepLink, planClaimStatusClient, template: 'wynkpremiere://onehub/activate'});
                }
                // Called from tilebuilder when planClaimStatusClient is not present
                return deepLink;
            }
            if (deepLink.includes('wynkpremiere://onehub/cp')) {
                const parsingDeepLink = deepLink.split('wynkpremiere://onehub/cp');
                if (parsingDeepLink.length > 1) {
                    //wynkpremiere://onehub/cp/NETFLIX
                    return `/onehub/cp${parsingDeepLink[parsingDeepLink.length - 1]}`;
                }
            }
        }
    }
    else if (deepLink?.toLowerCase().startsWith('xstream://')) { // for TV xstream:// is used
        if (deepLink.includes('type=PLAYBACK')) {
            const parsingDeepLink = deepLink.split('/');
            const queryParams = parsingDeepLink[parsingDeepLink.length - 1].split('?');
            return `/playback/direct?${queryParams[queryParams.length - 1]}&&viewType=webview`;
        }
        if (deepLink.includes('type=OTT_PURCHASE') || deepLink.includes('type=OTT_RENEWAL')) {
            if (deepLink.toLowerCase().includes('subtype=recharges')) {
                return `${RoutingUtil.getSubscriptionPage()}?viewType=webview&&source_button=XPP_BANNER&&source_tab=HOME&&subType=RECHARGE`;
            }
            if (deepLink.toLowerCase().includes('subtype=subscription')) {
                return `${RoutingUtil.getSubscriptionPage()}?viewType=webview&&source_button=XPP_BANNER&&source_tab=HOME&&subType=SUBSCRIPTION`;
            }
            return `${RoutingUtil.getSubscriptionPage()}?viewType=webview&&source_button=XPP_BANNER&&source_tab=HOME`;
        }
        if (deepLink.includes('type=VIEW_PACKS&plan=BROWSE') || deepLink.includes('?type=VIEW_PACKS&plan=SUBSCRIBED')) {
            return `${RoutingUtil.getSettingsURL({ settingsPage: '' })}?managePlan=true`;
        }
        if (deepLink.includes('xstream://LISTING/')) {
            const parsingDeepLink = deepLink.split('/');
            if (parsingDeepLink[parsingDeepLink.length - 1] === 'q') {
                return `/${parsingDeepLink[3]}/list/${parsingDeepLink[parsingDeepLink.length - 3]}?type=p&titleL2=y&viewType=webview`;
            }
            return `/${parsingDeepLink[3]}/list/${parsingDeepLink[parsingDeepLink.length - 2]}?type=p&titleL2=y`;
        }
        if (deepLink.includes('type=CDP')) {
            return `/${deepLink.split('/').slice(2).join('/')}&viewType=webview`;
        }
    }
    else {
        let deepLinkParsed = '';

        if (deepLink.includes('/plan/activate')) {
            if (Object.keys(planClaimStatusClient).length) {
                // Called directly in render from FeaturedSlider
                return getPendingActivationUrl({ deepLink, planClaimStatusClient, template: '/plan/activate'});
            }
        }
        try {
            deepLinkParsed = deepLink ? new URL(deepLink) : '';
        }
        catch (e) {
            console.log('invalid url', e, deepLink);
        }
        return deepLinkParsed !== '' ? (deepLinkParsed.pathname + deepLinkParsed.search) : null;
    }
    return '';
};

export const getBuildNumber = () => AppEnv.getEnv()?.buildNumber;

export const getDeviceType = () => AppEnv.getEnv()?.deviceType;

export const getOsType = () => AppEnv.getEnv()?.osType;

export const getNetworkQuality = () => NETWORK_QUALITY.AWFUL;

export function removeFromArray(arr, target) {
    const targetIndex = arr.indexOf(target);
    arr.splice(targetIndex, 1);
}

export const getTimeLabel = ({
    duration, label, separator, durationType,
}) => {
    // label pattern h|m|s
    const durationInitial = duration || 0;
    let seconds = 0;
    let minutes = 0;
    let hours = 0;
    let timeLabel = '';
    switch (durationType) {
        case 'ms':
            seconds = (Math.trunc((durationInitial / 1000)) % 60);
            minutes = (Math.trunc((durationInitial / (60 * 1000))) % 60);
            hours = Math.trunc((durationInitial / (60 * 60 * 1000)));
            break;
        case 'sec':
            seconds = durationInitial % 60;
            minutes = Math.trunc(durationInitial / 60) % 60;
            hours = Math.trunc(durationInitial / 3600);
            break;
        default:
            seconds = durationInitial % 60;
            minutes = Math.trunc(durationInitial / 60);
            hours = Math.trunc(durationInitial / 3600);
            break;
    }
    const [
        hrLabel,
        minLabel,
        secLabel,
    ] = label.split('|');
    if (hours && hrLabel) {
        timeLabel = timeLabel.concat(`${hours}${hrLabel}`);
    }
    if (hours && minutes && hrLabel && minLabel) {
        timeLabel = timeLabel.concat(`${separator}`);
    }
    if (minutes && minLabel) {
        timeLabel = timeLabel.concat(`${minutes}${minLabel}`);
    }
    if (minutes && seconds && minLabel && secLabel) {
        timeLabel = timeLabel.concat(`${separator}`);
    }
    if (hours && !minutes && seconds && hrLabel && secLabel) {
        timeLabel = timeLabel.concat(`${separator}`);
    }
    if (seconds && secLabel) {
        timeLabel = timeLabel.concat(`${seconds}${secLabel}`);
    }
    return timeLabel;
};

export const packageIdForListingPage = id => `${id}LISTING`;

export const generateCaptcha = (captchaLength) => {
    const alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');
    let code = '';
    for (let i = 0; i < captchaLength; i++) {
        code += alpha[Math.floor(Math.random() * alpha.length)];
    }
    return code;
};

export const addOpacityToRGB = ({ colorInRGB, opacity = 1 }) => colorInRGB.slice(0, -1).concat(`, ${opacity}`).concat(colorInRGB.slice(-1));

export const convertRailTitle = title => title && title.toLowerCase().replace(/\s/gi, '-') || '';
export const setPlaySessionId = (playSessionId) => {
    let analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
    analyticsMeta = {
        ...analyticsMeta,
        analyticsPlaySessionId: playSessionId,
    };
    sessionStore.set(LOCAL_STORE_KEYS.ANALYTICS_META, analyticsMeta);
};

export function getDeviceResolution() {
    if (checkWindowExist()) {
        return {
            width: window.screen.width,
            height: window.screen.height,
            resolution: `${window.screen.width}x${window.screen.height}`,
        };
    }
    return {};
}

export const getPortraitorLandscape = () => {
    const mq = window.matchMedia('(orientation: portrait)');
    return mq.matches ? 'portrait' : 'landscape';
};

export const getLottieFiles = (lottie) => {
    try {
        if (typeof lottie === 'string' && lottie.trim().startsWith('http')) {
            return axios
                .get(lottie)
                .then((res) => {
                    try {
                        const jsonLottieData = JSON.parse(JSON.stringify(res.data));
                        if (jsonLottieData) {
                            return jsonLottieData;
                        }
                    }
                    catch (e) {
                        console.error(e);
                        return null;
                    }
                })
                .catch(e => console.error(e));
        }

        try {
            const jsonLottieData = JSON.parse(JSON.stringify(lottie));
            if (jsonLottieData) {
                return jsonLottieData;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    catch (e) {
        return null;
    }
};

export const getMatchedRoute = () => {
    if (checkWindowExist()) {
        // merge both routes array ?
        // const allRouters = DeviceTypeUtil.isWeb() ? getAllWebRoutes : getAllTvRoutes;
        const pathname = getCurrentWindowSourceName();
        const routes = allRoutes();
        const routePresent = matchRoutes(
            routes[0]?.children,
            pathname,
        );

        if (routePresent?.length > 1) {
            return {
                ...routePresent[1]?.route,
                params: { ...routePresent[1]?.params },
            };
        }
        return routePresent?.length ? {
            ...routePresent[0]?.route,
            pathname,
            params: { ...routePresent[0]?.params },
        } : {};
    }
    return {};
};

export const getPageInfoFromPageTitle = ({ pages = [], matchedRoute = {} }) => {
    // const pathParams = getPathParams();
    const { params = {}, pathname, path } = matchedRoute;
    const { pageTitle } = params;

    // if (isOnehubRoute && pages && pages.length) {
    //     const page = pages.filter(el => el.urlName?.toLowerCase() === SOS_SOURCE_NAME.ONEHUB);
    //     return page.length ? page[0] : {};
    // }

    if (path === _ROUTE_PATHS[DEVICES.BROWSER].LAYOUT && pages && pages.length) {
        if (pageTitle) {
            const page = pages.filter(el => el.urlName === pageTitle);
            if (page.length) {
                return page[0];
            }
        }
        if (pathname === _ROUTE_PATHS[DEVICES.BROWSER].ROOT) {
            const page = pages.filter(el => el.urlName?.toLowerCase() === SOS_SOURCE_NAME.HOME);
            return page.length ? page[0] : {};
        }
    }
    return {};
};


export const remainingTimeToWatch = ({
    continueWatchingData,
    id,
}) => {
    let remainingTime = '';
    if (continueWatchingData && continueWatchingData[id]) {
        const { contentResponse: { durSec }, lastWatchedPosition } = continueWatchingData[id];
        if (lastWatchedPosition && durSec) {
            remainingTime = Math.floor(durSec - lastWatchedPosition);
            remainingTime = secondsTohhhmmm(remainingTime);
        }
    }
    return remainingTime || '';
};

export const getEvent = () => {
    if (checkWindowExist() && window) {
        return window.event;
    }
    return false;
};

export function validateOTPInput(evt) {
    const theEvent = evt || getEvent();
    let key = '';

    // Handle paste
    if (theEvent.type === 'paste') {
        const otpevent = getEvent();
        key = otpevent.clipboardData.getData('text/plain');
    }
    else {
        // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) {
            theEvent.preventDefault();
        }
    }
}
export const matchClassSelector = ({ classSelector = '', className = '' }) => {
    if (!className || !classSelector) {
        return false;
    }
    const splitClasses = classSelector.split('.');

    const notContainetClasses = splitClasses.filter(item => !className.includes(item));
    return !(notContainetClasses.length > 0);
};


export const generateSearchSessionCode = () => {
    const sessionCode = sessionStore.get(LOCAL_STORE_KEYS.SEARCH_SESSION_CODE);
    if (!sessionStore.get(LOCAL_STORE_KEYS.SEARCH_SESSION_CODE)) {
        const searchSessionId = `search-session-${getUniqueId()}`;
        sessionStore.set(LOCAL_STORE_KEYS.SEARCH_SESSION_CODE, searchSessionId);
        return searchSessionId;
    }
    return sessionCode;
};

export const clearSearchSessionCode = () => {
    sessionStore.remove(LOCAL_STORE_KEYS.SEARCH_SESSION_CODE);
};

export const getSourceNameFromStorage = () => {
    if (!checkWindowExist()) {
        return {};
    }
    const sourceNameDict = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_SOURCE) || {};
    return sourceNameDict;
};

export const fetchSearchSessionCode = () => sessionStore.get(LOCAL_STORE_KEYS.SEARCH_SESSION_CODE);

export const pxToRem = (pxValue) => {
    const rootFontSize = getFontRootSize();
    const remValue = pxValue / rootFontSize;
    return remValue;
};

export const checkDirectPlaybackContainer = () => {
    const matchedRoute = getMatchedRoute();
    const { path } = matchedRoute;
    return [
        _ROUTE_PATHS[DEVICES.BROWSER].DIRECT_PLAYBACK_CONTAINER,
        _ROUTE_PATHS[DEVICES.BROWSER].LIVETV_PLAYBACK,
    ].includes(path);
};

export const matchPathname = (pathname) => {
    const matchedRoute = getMatchedRoute();
    const { path } = matchedRoute;
    return path === pathname;
};
export const getPrepaidExpiryTime = (validity = null, amount = null, EXPIRING_TEXT = 'Your Subscription Will Expire in {duration}.', returnHtml = false) => {
    if (validity && amount) {
        const differenceInDateInMilliSeconds = new Date(validity).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0);
        const differenceInDays = Math.floor(differenceInDateInMilliSeconds / (1000 * 60 * 60 * 24));
        let expiringText = null;
        let warningColor = null;
        switch (differenceInDays) {
            case 2:
                warningColor = '#F2CB90';
                expiringText = returnHtml ? `${EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', `<span style="color:${warningColor}">3 days</span>`)}` : EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', '3 days');
                break;
            case 1:
                warningColor = '#F2CB90';
                expiringText = returnHtml ? `${EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', `<span style="color:${warningColor}">2 days</span>`)}` : EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', '2 days');
                break;
            case 0:
                warningColor = '#F29090';
                expiringText = returnHtml ? `${EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', `<span style="color:${warningColor}">today</span>`)}` : EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', 'today');
                break;
            default:
                warningColor = '#F2CB90';
                expiringText = returnHtml ? `${EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', `<span style="color:${warningColor}">${differenceInDays + 1} days</span>`)}` : EXPIRING_TEXT.replace('{amount}', amount).replace('{duration}', `${differenceInDays + 1} days`);
                break;;
        }
        return {
            expiringText,
            warningColor,
        };
    }
    return null;
};

export const showRechargeNudges = ({
    popupDefaulConfig = {}, validity = '', amount = '', uid = '', xEy = '',
}) => {
    const popupData = { ...popupDefaulConfig };
    const keys = browserStore.get(LOCAL_STORE_KEYS.SUBSCRIPTION_DETAILS);
    const subscriptionDetails = browserStore.get(LOCAL_STORE_KEYS.SUBSCRIPTION_DETAILS)?.[uid] || false;
    const store = StoreUtil?.getStore();
    const RECHARGE_NUDGES_CONST = lodashGet(store?.getState(), 'appConfig.RECHARGE_NUDGES_CONST', {});
    const {
        XPP_EXPIRY_DAY_LIMIT = 7,
        RECHARGE_DAY_UPPER_LIMIT = 3,
    } = RECHARGE_NUDGES_CONST;

    const inputDate = new Date(validity);
    inputDate.setHours(0, 0, 0, 0);

    // Get the current date
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Calculate the time difference in milliseconds
    const timeDifference = inputDate - currentDate;

    // Convert the time difference from milliseconds to days
    const prepaidValidity = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    let xppExpiryDateDifferenceinDays = 0;

    if (xEy) {
        const xppExpiryDate = new Date(xEy);
        xppExpiryDate.setHours(0, 0, 0, 0);
        const xppExpiryDateDifference = xppExpiryDate - currentDate;
        xppExpiryDateDifferenceinDays = Math.floor(xppExpiryDateDifference / (1000 * 60 * 60 * 24));
    }
    if ((xppExpiryDateDifferenceinDays <= XPP_EXPIRY_DAY_LIMIT || !xEy) && prepaidValidity < RECHARGE_DAY_UPPER_LIMIT && prepaidValidity >= 0 && (!subscriptionDetails || (subscriptionDetails && subscriptionDetails !== currentDate.toISOString()))) {
        browserStore.set(LOCAL_STORE_KEYS.SUBSCRIPTION_DETAILS, {
            ...keys,
            [uid]: currentDate.toISOString(),
        });
        const expiringText = getPrepaidExpiryTime(validity, amount, popupData.INPROGRESS.label);
        popupData.INPROGRESS.label = expiringText?.expiringText || '';
        popupData.INPROGRESS.warningColor = expiringText?.warningColor || '#F2CB90';
        return popupData;
    }
};

export function getXstreamPartner(partners, cpDetailsById, showTitle = true) {
    const partner = {
        top: [],
        bottom: [],
    };
    let localIndex = 0;
    partners.forEach((_partner, index) => {
        if (!_partner.notVisible) {
            const cpDetail = cpDetailsById[_partner.partnerId];
            partner[localIndex % 2 === 0 ? 'top' : 'bottom'].push((
                <div className="card-holder">
                    <ThumborImage
                        src={cpDetail?.cpIconURL || ''}
                        className="card-img"
                        imageSettings={{
                            320: 200,
                        }}
                    />
                    {showTitle && <p className="card-title">{characterToUpperCase(cpDetail.title)}</p>}
                </div>
            ));
            localIndex++;
        }
    });
    return partner;
}

export const getXstreamPartnersLogo = (partners, cpDetailsById) => {
    const partner = [];
    partners.forEach((_partner, index) => {
        if (!_partner.notVisible) {
            const cpDetail = cpDetailsById[_partner.partnerId];
            partner.push((
                <div className="card-holder-tv">
                    <ThumborImage
                        src={cpDetail?.cp_in_xstreamLogo || cpDetail?.cpIconURL || ''}
                        className="card-img-tv"
                        imageSettings={{
                            1000: 65,
                            500: 65,
                            320: 65,
                        }}
                    />
                </div>
            ));
        }
    });
    return partner;
};

export const backNavigationHandler = (history, prevPath) => {
    if (prevPath) {
        history.go(-1);
    }
    else {
        history.push({ pathname: ROUTE_PATHS.ROOT });
    }
};


export const renderRechargeNudges = ({
    xpprbe = false, oSeg = '', xppPId = null, isPrepaidCustomer = false,
}) => {
    const segments = oSeg.split(',');
    const invalidOfferSegement = segments.includes(AIRTEL_USER_TYPES.XSTREAMPREMIUM_TELCO_PAID);
    const addTobillPlanId = [
        6902,
        6900,
        588,
        51885221,
    ].includes(xppPId);
    if (xpprbe && !invalidOfferSegement && !addTobillPlanId && isPrepaidCustomer) {
        return true;
    }
    return false;
};


export const trimLabelSpecialCharacter = (targetString = '', separator = ' ', caseType = 't') => {
    const specialCharacters = [
        '!',
        '"',
        '#',
        '$',
        ' % ',
        ' & ',
        "'",
        '(',
        ')',
        '*',
        '+',
        ',',
        '\\-',
        '.',
        '/',
        ':',
        ';',
        '<',
        '=',
        '>',
        '?',
        '@',
        '\\[',
        '\\]',
        '^',
        '_',
        '`',
        '{',
        '|',
        '}',
        '~',
        "'",
        '–',
    ];

    const regexEscapeCharacters = new RegExp(`[${specialCharacters.join('|')}]`);
    const casingFn = caseType === 't' ? toTitleCase : caseType === 'u' ? toUpperCase : toLowerCase;
    const transformedString = targetString.trim()
    // .split((/\.|\/| |\(|\)|:|,|!|+|,|'|<|>|#|%|{|}|^|~|[|]|$|_|(|)|;|?|@|=|/))
        .split(regexEscapeCharacters) // src: https://stackoverflow.com/a/25266512/4221433
        .map(item => casingFn(item))
        .join(separator);

    return transformedString;
};

export const addParameterToURL = (url, params = {}) => {
    let newUrl = '';
    Object.keys(params).forEach((item, index) => {
        newUrl = index === 0 && !url?.includes('?') ? `${newUrl}?${item}=${params[item]}` : `${newUrl}&${item}=${params[item]}`;
    });
    return url + newUrl;
};

export const formatType = {
    LONG: 'long',
    SHORT: 'short',
};

export const mockRailsIfNeeded = (railList) => {
    if (checkWindowExist() && localStorage.getItem('_MOCK_DATA_')) {
        const index = railList.findIndex(item => item.format.ty === RAIL_TYPES.CONTENT_PLAY_RAIL);
        if (index !== -1) {
            railList.splice(index + 1, 0, CDP_RAIL_MOCK);
        }
    }
    return railList;
};

export const getAmazonAppInAppMeta = ({
    gtiId = '', webUrl = '', androidUrl = '', iosUrl = '', iosAppId = '', lgUrl = '', lgAppId,
}) => {
    const webUrlUpdated = webUrl.replace('{GTI}', gtiId);
    const androidUrlUpdated = androidUrl.replace('{GTI}', gtiId);
    const iosUrlUpdated = iosUrl.replace('{GTI}', gtiId);
    const lgUrlUpdated = lgUrl.replace('{GTI}', gtiId);
    const appInAppMeta = {
        android_redirect_url: androidUrlUpdated,
        android_app_id: '',
        ios_redirect_url: iosUrlUpdated,
        ios_app_id: iosAppId,
        browser_redirect_url: webUrlUpdated,
        lg_redirect_url: lgUrlUpdated,
        lg_app_id: lgAppId,
    };
    return appInAppMeta;
};

export const decryptPartnerStatusString = ({ ps = '' }) => {
    const partnerStatus = {};
    const pendingActivationCps = [];
    const claimedCps = [];
    const psSplit = ps.split(',');
    try {
        psSplit.forEach((item) => {
            const split = item.split(':');
            const cp = split[0]?.toUpperCase();
            const status = split[1]?.toUpperCase();
            if (status === CP_CLAIM_STATUS.pending_activation && !pendingActivationCps.includes(cp)) {
                pendingActivationCps.push(cp);
            }
            if (status === CP_CLAIM_STATUS.claimed && !claimedCps.includes(cp)) {
                claimedCps.push(cp);
            }
            if (partnerStatus[cp]) {
                partnerStatus[cp].push(status);
            }
            else {
                partnerStatus[cp] = [
                    status,
                ];
            }
        });
    }
    catch (e) {
        console.log(e);
    }
    partnerStatus.pendingActivationCps = pendingActivationCps;
    partnerStatus.claimedCps = claimedCps;
    return partnerStatus;
};


export function scrollIfNeeded(element) {
    const container = element?.parentElement;
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
  
    // Check if the element is out of view on the left
    if (elementRect.left < containerRect.left) {
      container.scrollLeft -= (containerRect.left - elementRect.left);
    }
  
    // Check if the element is out of view on the right
    else if (elementRect.right > containerRect.right) {
      container.scrollLeft += (elementRect.right - containerRect.right);
    }
  }