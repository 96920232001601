import { secondToHours, secondToMinutes } from '../../../../utilities/CommonUtil';

import {RoutingUtil} from '@airtel-tv/utils';
import { MARKUP_CONTEXT, MARKUP_TYPES } from '../../../../constants/MarkupConsts';
import { PROGRAM_TYPES } from '../../../../constants/AppConst';
import PotentialActionParser from '../markup/PotentialActionParser';

export default function VideoMarkupBuilder({
    contentDetails, programType: program, baseUrl,
}) {
    const {
        title, description, images, durSec, programType, id, languages,
    } = contentDetails;
    let canonicalUrl;

    switch (program) {
        case PROGRAM_TYPES.MOVIE:
            canonicalUrl = RoutingUtil.getContentPlaybackUrlWeb({
                category: programType,
                contentTitle: title,
                contentId: id,
            });
            break;
        case PROGRAM_TYPES.TVSHOW:
        case PROGRAM_TYPES.SEASON:
        case PROGRAM_TYPES.EPISODE: {
            const {
                episodeTvShowName,
                episodeNum,
                episodeSeasonNum,
                playableId,
                seasonId,
            } = contentDetails;
            canonicalUrl = RoutingUtil.getTvEpisodeUrlWeb({
                programType,
                title: episodeTvShowName,
                episodeNumber: episodeNum,
                episodeName: title,
                seasonName: `season-${episodeSeasonNum}`,
                contentId: id,
                seasonId,
                episodeId: playableId,
            });
            break;
        }
        case PROGRAM_TYPES.LIVETVCHANNEL: {
            canonicalUrl = RoutingUtil.getContentPlaybackUrlWeb({
                category: programType,
                contentTitle: title,
                contentId: id,
            });
            break;
        }
        default: break;
    }
    const potentialAction = PotentialActionParser({
        urlTemplate: baseUrl+canonicalUrl,
        inLanguage: languages && languages[0],
    });

    const durationHour = Math.floor(secondToHours(durSec));
    const durationMin = Math.floor(secondToMinutes(durSec));
    return {
        '@context': MARKUP_CONTEXT.URL,
        '@type': MARKUP_TYPES.VIDEO_OBJECT,
        name: title,
        description: description || title,
        thumbnailUrl: images && Object.values(images),
        uploadDate: '1970-01-01T00:00:00.000Z',
        duration: `PT${durationHour}H${durationMin}M00S`,
        embedUrl: baseUrl + canonicalUrl,
        regionsAllowed: [
            {
                '@type': 'Country',
                name: 'IN',
            },
        ],
        requiresSubscription: true,
        publication: {
            '@type': 'BroadcastEvent',
            isLiveBroadcast: (program === PROGRAM_TYPES.LIVETVCHANNEL),
            startDate: '2016-03-31T18:30:00.000Z',
        },
        ...potentialAction
    };
}
