import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { connect } from 'react-redux';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import {
    formatType, getDateWithTitle, getPrepaidExpiryTime,
    getSourceNameFromStorage,
} from '@airtel-tv/utils/GlobalUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import SubscriptionUtil from '@airtel-tv/utils/SubscriptionUtil';
import { TIZEN_KEYCODES } from '@airtel-tv/constants/GlobalConst';
import { ANALYTICS_ACTIONS } from '@airtel-tv/constants/AnalyticsConst';
import styles from './PlanTile/PlanTile.scss';


const ActivePlanTile = (props) => {
    const planTileContainerRef = useRef();
    const LANGUAGE = LanguageProviderUtil?.getLanguage() || {};
    const {
        SUBSCRIPTION_RENEW_TXT = 'Renew Now',
        EXPIRING_TEXT = 'Your Subscription Will Expire in {duration}',
        MORE_PLANS_LABEL = 'More Plans',
        VIEW_DETAILS_LABEL = 'View Details',
        VALID_TILL_LABEL = 'Valid till ${date}',
        ACTIVE_PLAN = 'ACTIVE',
        ACTIVE_PLAN_FOOTER_TEXT = 'To manage your plan, go to your mobile app',
        UPCOMING_PLAN = 'UPCOMING',
        VALIDITY_PLACEHOLDER = 'Valid {validityPlaceholder}',
    } = LANGUAGE;

    const [
        focusSecondaryCta,
        setFocusSecondaryCta,
    ] = useState(false);

    const {
        isLargeScreen, tileProps, removeFocusableClass = false, selector = '',
    } = props;
    const {
        railPosition, tilePosition, contentDetails, onKeyDown,
        onKeyUp,
        onFocus, focusProps, pageId, tileId, linkClassName = '', containerClass = '',
        style,
        customStyle,
        planDetails: {
            validTill = 0,
            amount = '',
            planStatus = '',
            renewPlanId = 0,
            planType = '',
            preReminder = false,
            upcoming,
            meta: {
                tags = [],
            } = {},
            validityPlaceholder,
            discountedPrice
        } = {},
        planDetails = {},
        secondaryActionHandler = () => {},
        primaryActionHandler = () => {},
        planTitleElement,
        purchaseCtaText = SUBSCRIPTION_RENEW_TXT,
        seePlanCtaText = VIEW_DETAILS_LABEL,
        isRenewNowCase,
        secondaryActionType,
        analyticsPageId,
        railId,
        railType,
        railTitle,
        deviceUtil,
    } = tileProps;

    const thisRef = useRef({
        isMobile: deviceUtil.isMobile(),
    }).current;

    const tileIdUpdated = tileId || `${pageId}-tile-${railPosition}-${tilePosition}`;
    const isFocussedWithIn = selector === tileIdUpdated;
    let date;

    if (validTill) {
        date = getDateWithTitle(validTill, formatType.SHORT);
    }
    // const platform = browserStore.get(LOCAL_STORE_KEYS.OS) || 'WEB';
    // const isRenewNowCase = (platform.toLowerCase() !== SubscriptionUtil?.PLATFORM?.IOS && planType !== SubscriptionUtil.PLAN_TYPE.FREE_TRIAL && preReminder && renewPlanId && renewPlanId !== 0 && planStatus !== SubscriptionUtil?.PLAN_STATE?.DEPRECATED) || false;
    const expiringData = getPrepaidExpiryTime(validTill, discountedPrice, EXPIRING_TEXT, true);

    const { currentSource, prevSource } = getSourceNameFromStorage() || {};
    const analyticsMeta = {
        source_name: currentSource,
        source_page: prevSource,
        rail_position: railPosition,
        tile_type: railType,
        rail_id: railId,
        rail_title: railTitle,
        rail_type: railType,
        asset_position: tilePosition,
        page_id: analyticsPageId,
        tileId,
        action: ANALYTICS_ACTIONS.BUTTON_CLICK,
    };


    let planTitle = ACTIVE_PLAN;
    let validityText = validityPlaceholder ? VALIDITY_PLACEHOLDER.replace('{validityPlaceholder}', validityPlaceholder) : VALID_TILL_LABEL.replace('${date}', date);
    let footerText = ACTIVE_PLAN_FOOTER_TEXT;
    
    if(upcoming) {
        planTitle = UPCOMING_PLAN;
        validityText = '';
        footerText = '';
    }

    const packNames = tags.filter(pack => pack?.toLowerCase()?.includes('pack'));
    return (
        <div
            ref={planTileContainerRef}
            id={tileIdUpdated}
            className={`nostyling plan-tile plan-tile-for-ls plan-tile-for-web ${FocusManagementClassNames.CAN_FOCUS} ${tileId}`}
            style={{
                ...customStyle
            }}
        >
            <div className={`subs-plans-action-wrapper ${isRenewNowCase ? 'coloumn' : ''}`}>
                <div className="subs-plans-wrapper">
                    <div className="plans-title-subs">
                        {packNames?.length ? <div className="text-ls-20 text-bold line-height-28 text-12 text-bolder line-height-16 text-ls-16 text-ls-super-bolder line-height-ls-24 mobile-text-10 mobile-text-bolder mobile-line-height-18">{packNames[0]}</div> : null}
                        {packNames?.length ? <div className='dot'>•</div> : null}
                        <div className="text-ls-20 text-bold line-height-28 text-12 text-bolder line-height-16 text-ls-16 text-ls-super-bolder line-height-ls-24 mobile-text-10 mobile-text-bolder mobile-line-height-18">{planTitle}</div>
                    </div>
                    <div className="subs-offers text-ls-bold text-ls-28 line-height-ls-40 mobile-text-bolder mobile-text-16 mobile-line-height-24">{planTitleElement}</div>
                    {isRenewNowCase && expiringData?.expiringText
                        ? (
                            <div
                                dangerouslySetInnerHTML={{ __html: expiringData?.expiringText }}
                                className="subs-validity mobile-text-14 text-14 text-normal text-ls-bold line-height-20 text-ls-22 line-height-ls-36"
                            />
                        )
                        : (validityText ? <div className="subs-validity text-14 text-normal line-height-20 text-ls-bold text-ls-24 line-height-ls-36">{validityText}</div> : null) }
                </div>
                {isRenewNowCase ? (
                    <div className="renew-xpp-container active-actions renew-cta-wrapper">
                        <AnalyticsButtonComponent
                            id={tileId}
                            // onClick={e => this.subscribe(plans)}
                            className="one-hub-tile-cta renew-now-btn text-ls-normal text-ls-22 line-height-ls-28 primary"
                            aria-label={SUBSCRIPTION_RENEW_TXT}
                            autoFocus={isLargeScreen && isFocussedWithIn && !focusSecondaryCta}
                            onKeyDown={(e) => {
                                const { keyCode = '' } = e.event;
                                if (keyCode === TIZEN_KEYCODES.RIGHT_ARROW) {
                                    setFocusSecondaryCta(true);
                                }
                                else {
                                    // onKeyDownCallback(e.event);
                                    if (typeof onKeyDown === 'function') {
                                        onKeyDown({
                                            event: e.event,
                                            tilePosition,
                                        });
                                    }
                                }
                            }}
                            onFocus={(e) => {
                                if (typeof onFocus === 'function') {
                                    onFocus({
                                        e: e.event,
                                        railPosition,
                                        tilePosition,
                                        isCwRail: true,
                                        customElmTarget: { target: planTileContainerRef.current },
                                        tileDetails: {
                                            ...contentDetails,
                                            nonQuickViewTile: true,
                                        },
                                        ...focusProps,
                                    });
                                }
                            }}
                            onKeyUp={(e) => {
                                if (typeof onKeyUp === 'function') {
                                    onKeyUp(e?.event);
                                }
                            }}
                            onClick={() => primaryActionHandler(props)}
                            meta={{
                                ...analyticsMeta,
                                asset_name: purchaseCtaText,
                            }}
                        >
                            {purchaseCtaText}
                        </AnalyticsButtonComponent>
                        <AnalyticsButtonComponent
                            // id={tileId}
                            autoFocus={isLargeScreen && isFocussedWithIn && focusSecondaryCta}
                            // onClick={() => {}}
                            // autoFocus={railPosition === 0 && tilePosition === 0}
                            onKeyDown={(e) => {
                                const { keyCode = '' } = e.event;
                                if (keyCode === TIZEN_KEYCODES.LEFT_ARROW) {
                                    setFocusSecondaryCta(false);
                                }
                                else {
                                    // onKeyDownCallback(e.event);
                                    if (typeof onKeyDown === 'function') {
                                        onKeyDown({
                                            event: e.event,
                                            tilePosition,
                                        });
                                    }
                                }
                            }}
                            onKeyUp={(e) => {
                                if (typeof onKeyUp === 'function') {
                                    onKeyUp(e?.event);
                                }
                            }}
                            onFocus={(e) => {
                                if (typeof onFocus === 'function') {
                                    onFocus({
                                        e: e.event,
                                        railPosition,
                                        tilePosition,
                                        isCwRail: true,
                                        customElmTarget: { target: planTileContainerRef.current },
                                        tileDetails: {
                                            ...contentDetails,
                                            nonQuickViewTile: true,
                                        },
                                        ...focusProps,
                                    });
                                }
                            }}
                            onClick={() => {
                                secondaryActionHandler({
                                    ...props,
                                    tileProps: {
                                        ...tileProps,
                                        secondaryActionType,
                                    },
                                });
                            }}
                            className="one-hub-tile-cta text-ls-normal text-ls-22 line-height-ls-28 "
                            meta={{
                                ...analyticsMeta,
                                asset_name: seePlanCtaText,
                            }}
                        >
                            {seePlanCtaText}
                            {/* {thisRef.isMobile ? '' : seePlanCtaText} */}
                        </AnalyticsButtonComponent>
                    </div> 
                )
                    : (
                        <div className="active-actions">
                            <AnalyticsButtonComponent
                                id={tileId}
                                autoFocus={isLargeScreen && railPosition === 0 && tilePosition === 0}
                                onFocus={(e) => {
                                    if (typeof onFocus === 'function') {
                                        onFocus({
                                            e: e.event,
                                            railPosition,
                                            tilePosition,
                                            isCwRail: true,
                                            customElmTarget: { target: planTileContainerRef.current },
                                            tileDetails: {
                                                ...contentDetails,
                                                nonQuickViewTile: true,
                                            },
                                            ...focusProps,
                                        });
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (typeof onKeyDown === 'function') {
                                        onKeyDown({
                                            event: e.event,
                                            tilePosition,
                                        });
                                    }
                                }}
                                onKeyUp={e => {
                                    if (typeof onKeyUp === 'function') {
                                        onKeyUp(e?.event);
                                    }
                                }}
                                onClick={() => {
                                    secondaryActionHandler({
                                        ...props,
                                        tileProps: {
                                            ...tileProps,
                                            secondaryActionType,
                                        },
                                    });
                                }}
                                className="one-hub-tile-cta text-ls-normal text-ls-22 line-height-ls-28 text-16 text-bold"
                                meta={{
                                    ...analyticsMeta,
                                    asset_name: seePlanCtaText,
                                }}
                            >
                                {thisRef.isMobile ? '' : seePlanCtaText}
                            </AnalyticsButtonComponent>
                        </div>
                    )
                }
            </div>
            <div className='divider-aliner'>

            {footerText ? <>
                <hr className="subs-flat-divider" />

                <div className="subs-manage-note text-14 line-height-20 text-ls-normal text-ls-22 line-height-ls-28">{footerText}</div>
            </> : null}
        </div>
        </div>
    );
};

const mapStateToProps = ({
    navigation: {
        selector = '',
    } = {},
}) => ({
    selector,
});

ActivePlanTile.propTypes = {
    tileProps: PropTypes.object.isRequired,
    selector: PropTypes.string.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(ActivePlanTile));
